import { FunctionComponent, useState } from 'react';
import styles from './Contact.module.css';
import stylesEn from './ContactEn.module.css';

import React from 'react';
import NaverMapHead from './NaverMapHead.tsx';
import NaverMapLab from './NaverMapLab.tsx';
import { useTranslation } from "react-i18next";


const Contact: FunctionComponent = () => {
	const [selectMapLab, setSelectMapLab] = useState(false);
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	return (
		<div>
			{currentLanguage === 'ko' ? (
				<div className={styles.contact}>
					<div className={styles.div}>
						<div className={styles.child} />
						<img className={styles.icon1} alt="" src="icons/mobile/contact/vdigmLogo.png" />
						<b className={styles.businessContact}>Business Contact</b>

						<div className={styles.tel}>Tel.</div>
						<div className={styles.eMail}>E-mail.</div>
						<div className={styles.adress}>Address.</div>
						<div className={styles.rdCenterAdress}>R&D Center Address.</div>
						{/* <div className={styles.rectangleDiv} /> */}
						<b className={styles.b1}>찾아오시는 길</b>
						<div onClick={() => setSelectMapLab(false)}>
							{selectMapLab
								? <div className={styles.b2}>본사</div>
								: <div className={styles.b2Bold}>본사</div>}
						</div>
						<div onClick={() => setSelectMapLab(true)}>
							{selectMapLab
								? <div className={styles.b3Bold}>R&D Center</div>
								: <div className={styles.b3}>R&D Center</div>}
						</div>
						{/* <div>
                    {selectMapLab ? (
                        <div onClick={() => setSelectMapLab(false)} className={styles.b2}>본사</div>
                    ) : (
                        <div onClick={() => setSelectMapLab(true)} className={styles.b2Bold}>본사</div>
                    )}
                </div>
                <div>
                    {selectMapLab ? (
                        <div onClick={() => setSelectMapLab(false)} className={styles.b3Bold}>R&D 센터</div>
                    ) : (
                        <div onClick={() => setSelectMapLab(true)} className={styles.b3}>R&D 센터</div>
                    )}
                </div> */}

						<div className={styles.div4}> +82-32-277-1700</div>
						<div className={styles.bizvdigmcom}>biz@vdigm.com</div>
						<div className={styles.div5}>인천광역시 부평구 부평대로 293, 1012호</div>
						<div className={styles.aiContainer}>
							<p className={styles.ai}>서울특별시 서초구 매헌로 8길 47, AI 양재허브 희경빌딩</p>
							<p className={styles.c3041}>C동 304호</p>
						</div>
						<div className={styles.vdigmInc}>(주)브이다임 / Vdigm, Inc.</div>
						
						<div className={styles.div2}>
							{selectMapLab ? <NaverMapLab /> : <NaverMapHead />}
						</div>
					</div>
				</div>
            ) : (
				<div className={stylesEn.contact}>
      			<div className={stylesEn.rectangleParent}>
        				<div className={stylesEn.groupChild} />
        				{/* <div className={stylesEn.rdCenter}>{`R&D Center`}</div>
        				<div className={stylesEn.headOffice}>Head Office</div> */}

						<div onClick={() => setSelectMapLab(false)}>
							{selectMapLab
								? <div className={stylesEn.headOffice}>Head Office</div>
								: <div className={stylesEn.headOfficeBold}>Head Office</div>}
						</div>
						<div onClick={() => setSelectMapLab(true)}>
							{selectMapLab
								? <div className={stylesEn.rdCenterBold}>R&D Center</div>
								: <div className={stylesEn.rdCenter}>R&D Center</div>}
						</div>

        				<b className={stylesEn.businessContact}>Business Contact</b>
        				<img className={stylesEn.icon2} alt="" src="icons/mobile/contact/vdigmLogo.png" />
        				{/* <div className={stylesEn.groupItem} /> */}
        				<div className={stylesEn.tel}>Tel.</div>
        				<div className={stylesEn.eMail}>E-mail.</div>
        				<div className={stylesEn.headOfficeAdress}>Head Office Address</div>
        				<div className={stylesEn.rdCenterAdress}>R&D Center Address</div>
        				<div className={stylesEn.location}>Location</div>
        				<div className={stylesEn.div}>+82-32-277-1700</div>
        				<div className={stylesEn.f1012293Container}>
          					<p className={stylesEn.c304SeoulAi}>10F 1012, 293, Bupyeong-daero, Bupyeong-gu, Incheon,</p>
          					<p className={stylesEn.republicOfKorea}>Republic of Korea</p>
        				</div>
        				<div className={stylesEn.c304SeoulAiContainer}>
          					<p className={stylesEn.c304SeoulAi}>C-304, Seoul AI Hub, 47, Maeheon-ro 8-gil, Seocho-gu,</p>
          					<p className={stylesEn.republicOfKorea}>Seoul, Republic of Korea</p>
        				</div>
        				<div className={stylesEn.bizvdigmcom}>biz@vdigm.com</div>
        				<div className={stylesEn.vdigmInc}>Vdigm, Inc. / (주)브이다임</div>
        				
        				<div className={stylesEn.div2}>
							{selectMapLab ? <NaverMapLab /> : <NaverMapHead />}
						</div>
      			</div>
    		</div>
            )}
		</div>
	);
};

export default Contact;
