import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './DVirtualStage.module.css';
import stylesEn from './DVirtualStageEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    solutions: {
        aiAvatar: string;
        deepReal: string;
        dVirtualStage: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const DVirtualStage: FunctionComponent<Props> = ({ solutions, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'aiAvatar':
                    setSelectedCategory(solutions.aiAvatar);
                    break;
                case 'deepReal':
                    setSelectedCategory(solutions.deepReal);
                    break;
                case 'dVirtualStage':
                    setSelectedCategory(solutions.dVirtualStage);
                    break;
            }
        }
    }, [tab, solutions, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(solutions[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.dVirtualStage}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.dContainer}>
                            <p className={styles.d}>3D 라이브 스트리밍을 이끌어 갈</p>
                            <p className={styles.d}>브이다임만의 기술</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                                {solutions.aiAvatar}
                            </div>
                            <div className={styles.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                                {solutions.deepReal}
                            </div>
                            <div className={styles.dVirtualStage1} onClick={() => handleCategoryClick('dVirtualStage')}>
                                {solutions.dVirtualStage}
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>

                        {/* <div className={styles.deepReal}>Deep Real</div>
        				<b className={styles.dVirtualStage1}>3D Virtual Stage</b> */}
                        {/* <div className={styles.aiAvatar}>AI Avatar</div> */}
                        <img className={styles.maskGroupIcon} alt="" src="assets/pc/solution/3dVirtualStage.png" />
                        <div className={styles.virtualStageAiSolutionParent}>
                            <b className={styles.b}>Virtual Stage AI Solution</b>
                            <b className={styles.ai3dContainer}>
                                <p className={styles.ai3d}>AI 아바타가 3D 가상 공간을 추천, 생성, 구성하며, 가상의</p>
                                <p className={styles.ai3d}>공간에 3D 객체 자동배치 기능을 제공하여 유저가 보다</p>
                                <p className={styles.d}>편리하게 공간을 꾸밀 수 있음</p>
                            </b>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={stylesEn.virtualStageSolution}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.vdigmsTechnologySpearheadinContainer}>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>Vdigm’s Technology Spearheading</p>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>3D Live Streaming</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                                AI Avatar
                            </div>
                            <div className={stylesEn.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                                Deep Real
                            </div>
                            <div className={stylesEn.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                3D Virtual Stage
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <div className={styles.deepReal}>Deep Real</div>
                        <b className={styles.dVirtualStage}>3D Virtual Stage</b> */}
                        {/* <div className={styles.aiAvatar}>AI Avatar</div> */}
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/solution/3dVirtualStage.png" />
                        <div className={stylesEn.virtualStageAiSolutionParent}>
                            <b className={stylesEn.virtualStageAi}>Virtual Stage AI Solution</b>
                            <div className={stylesEn.aiAvatarsRevolutionizeContainer}>
                                <p className={stylesEn.aiAvatarsRevolutionize}>AI Avatars Revolutionize 3D Virtual Space Design:</p>
                                <p className={stylesEn.aiAvatarsRevolutionize}>Expert Recommendations, Effortless Creation, and</p>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>Automated Object Placement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DVirtualStage;
