import { FunctionComponent } from 'react';
import Menu from './Menu.tsx';
import styles from './Header.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import i18n from "../locales/i18n.ts";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';


const Header: FunctionComponent = () => {
  console.log('Header');
  const { t, i18n } = useTranslation("main");
  const languageRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);

  // 외부 클릭 시 닫기
  // const handleUserClose = useCallback((e: any) => {
  //     if (isLanguageMenuOpen && languageRef.current !== null && !languageRef.current.contains(e.target)) setLanguageMenuOpen(false);
  // }, [isUserMenuOpen, isLanguageMenuOpen]);

  // useEffect(() => {
  //     document.addEventListener("click", handleUserClose);
  //     return () => document.removeEventListener("click", handleUserClose)
  // }, [handleUserClose]);

  // 언어 변경하기
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguageMenuOpen(false);
  };

  // changeLanguage()를 통해 언어 변환
  // 글로벌하게 적용되는 방식이라 따로 관리 X
  const toggleLocales = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );

  const [isOn, setMenu] = useState(false);  // 메뉴의 초기값 false
  const toggleMenu = () => {
    setMenu(!isOn); // On/Off

    // 스크롤 바 On/Off
    !isOn
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset")
  }

  const closeMenu = () => {
    if (isOn) {
      setMenu(false);
      document.body.style.overflow = "unset";
    }
  }

  // const Language = [
  //   { name: '한국어', code: 'ko', id: 0 },
  //   { name: 'English', code: 'en', id: 1 },
  // ]

  // let initialLanguageCode = i18n.language;

  // const [selectedLag, setSelectedLag] = useState(() => {
  //   return Language.find(lang => lang.code === initialLanguageCode) || Language[0];
  // })

  // const handleLanguageOptionClick = (option) => {
  //   setSelectedLag(option);
  //   switch (option.id) {
  //     case 0:
  //       i18n.changeLanguage('ko');
  //       break;

  //     case 1:
  //       i18n.changeLanguage('en');
  //       break;

  //     default:
  //       break;
  //   }

  //   window.location.reload()
  // };

  return (
    // <div className={styles.bbb}>
    <div className={styles.header}>
      {/* <button onClick={() => toggleLocales("en-US")} title="영어로 바꾸기" />
      <button onClick={() => toggleLocales("ko-KR")} title="한글로 바꾸기" /> */}

      {/* <div className={styles.rectangleParent}> */}
      <Link to="/">
        <img className={styles.icon3} alt="" src="icons/vdigmlogowidth.png"
          onClick={closeMenu} />
      </Link>

      <div onClick={toggleMenu}>
        {/* <button onClick={() => navigate("/user/signin")}>
                      {t(`header.login`)}
                  </button>
              <button onClick={() => navigate("/user/register")}>
                  {t(`header.register`)}
              </button> */}

        {isOn ? (
          <img className={styles.icon5} alt="" src="icons/menuBar.png" />
        ) : (
          <img className={styles.icon5} alt="" src="icons/menuBar.png" />
        )
        }

        <div className={isOn ? `` : `${styles.div}`}>
          <Menu />
        </div>
      </div>


      {/* 스크롤 바 On/Off */}
      {/* <>
                    {isOn
                    ? (document.body.style.overflow = "hidden")
                    : (document.body.style.overflow = "unset")}
                </> */}
      {/* </div> */}
      {/* <Link to="/">
                <img className={styles.icon} alt="" src="icons/vdigmlogowidth.png" />
            </Link> */}
    </div>
  )
}

export default Header