import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './Organization.module.css';
import stylesEn from './OrganizationEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
	introduces: {
		about: string,
		history: string,
		organization: string,
		vision: string,
	};
	setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
	tab: string | null;
	setSearchParams: SetURLSearchParams;
	selectedCategory: any;
}

const Organization: FunctionComponent<Props> = (props) => {
	const { introduces, setSelectedCategory, tab, setSearchParams } = props;
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	useEffect(() => {
		if (tab) {
			switch (tab) {
				case 'about':
					setSelectedCategory(introduces.about);
					break;
				case 'history':
					setSelectedCategory(introduces.history);
					break;
				case 'organization':
					setSelectedCategory(introduces.organization);
					break;
				case 'vision':
					setSelectedCategory(introduces.vision);
					break;
			}
		}
	}, [tab, introduces, setSelectedCategory]);

	const handleCategoryClick = (category: string) => {
		setSearchParams({ tab: category });
		setSelectedCategory(introduces[category]);
	};

	return (
		<div>
			{currentLanguage === 'ko' ? (
				<div className={styles.div}>
					<div className={styles.rectangleParent1}>
						<div className={styles.aiContainer}>
							<p className={styles.ai}>생성형 AI 아바타 서비스의</p>
							<p className={styles.ai}>새로운 패러다임을 만들어 나갑니다</p>
						</div>
					</div>
					<div className={styles.rectangleParent}>
						<div className={styles.groupChild} />

						<div className={styles.category}>
							<div className={styles.b5} onClick={() => handleCategoryClick('about')}>
								{t(`company:category:companyAbout`)}
							</div>
							<div className={styles.div1} onClick={() => handleCategoryClick('organization')}>
								{t(`company:category:organization`)}
							</div>
							<div className={styles.b} onClick={() => handleCategoryClick('history')}>
								{t(`company:category:history`)}
							</div>
							<div className={styles.b1} onClick={() => handleCategoryClick('vision')}>
								{t(`company:category:vision`)}
							</div>
							<div className={styles.ellipseDiv} />
						</div>

						{/* <b className={styles.b}>연혁</b>
        				<div className={styles.div1}>조직도</div>
        				<b className={styles.b1}>비전</b> */}


						{/* <b className={styles.b5}>회사소개</b> */}
						<img className={styles.lineIcon} alt="" src="icons/organizationGroupHeight.png" />
						<img className={styles.groupChild1} alt="" src="icons/pc/company/purpleDotLineWidth.png" />
						<img className={styles.groupChild2} alt="" src="icons/pc/company/pinkDotLineWidth.png" />
						<img className={styles.groupChild3} alt="" src="icons/pc/company/pinkDotLineWidth.png" />
						<div className={styles.groupChild4} />
						<div className={styles.groupChild5} />
						<div className={styles.groupChild6} />
						<div className={styles.ceo}>CEO</div>
						<div className={styles.cto}>CTO</div>
						<b className={styles.ai1}>기업부설 AI 연구소</b>
						<b className={styles.b6}>기획&마케팅</b>
						<b className={styles.tih1}>TIH 개발</b>
						<b className={styles.d}>3D 디자인</b>
						<b className={styles.b7}>서비스 운영</b>
						<img className={styles.groupChild7} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={styles.groupChild8} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={styles.groupChild9} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={styles.groupChild10} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={styles.groupChild11} alt="" src="icons/pc/company/height.png" />
						<img className={styles.groupChild12} alt="" src="icons/pc/company/height.png" />
						<img className={styles.groupChild13} alt="" src="icons/pc/company/height.png" />
						<img className={styles.groupChild14} alt="" src="icons/pc/company/height.png" />
						<div className={styles.groupChild15} />
						<div className={styles.groupChild16} />
						<div className={styles.groupChild17} />
						<div className={styles.groupChild18} />
						<img className={styles.groupChild19} alt="" src="icons/organizationGroupWidth.png" />

					</div>
				</div>
			) : (
				<div className={stylesEn.organizationChart}>
					<div className={stylesEn.rectangleParent1}>
						<div className={stylesEn.shapingTheFutureContainer}>
							<p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
							<p className={stylesEn.shapingTheFuture}>Experiences with Generative AI</p>
						</div>
					</div>
					<div className={stylesEn.rectangleParent}>
						<div className={stylesEn.groupChild} />

						<div className={stylesEn.category}>
							<div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
								{t(`company:category:companyAbout`)}
							</div>
							<div className={stylesEn.organizationChart1} onClick={() => handleCategoryClick('organization')}>
								{t(`company:category:organization`)}
							</div>
							<div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
								{t(`company:category:history`)}
							</div>
							<div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
								{t(`company:category:vision`)}
							</div>
							<div className={stylesEn.ellipseDiv} />
						</div>

						{/* <div className={stylesEn.chart}> */}
						<img className={stylesEn.lineIcon} alt="" src="icons/organizationGroupHeight.png" />
						<img className={stylesEn.groupChild1} alt="" src="icons/pc/company/purpleDotLineWidth.png" />
						<img className={stylesEn.groupChild2} alt="" src="icons/pc/company/pinkDotLineWidth.png" />
						<img className={stylesEn.groupChild3} alt="" src="icons/pc/company/pinkDotLineWidth.png" />
						<div className={stylesEn.groupChild4} />
						<div className={stylesEn.groupChild5} />
						<div className={stylesEn.groupChild6} />
						<div className={stylesEn.ceo}>CEO</div>
						<div className={stylesEn.cto}>CTO</div>
						<b className={stylesEn.ai1}>Corporate AI Research Institute</b>
						<b className={stylesEn.b6}>Planning & marketing</b>
						<b className={stylesEn.tih1}>TIH Development</b>
						<b className={stylesEn.d}>3D Desgin</b>
						<b className={stylesEn.b7}>Service Operations</b>
						<img className={stylesEn.groupChild7} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={stylesEn.groupChild8} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={stylesEn.groupChild9} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={stylesEn.groupChild10} alt="" src="icons/pc/company/purpleDotLineHeight.png" />
						<img className={stylesEn.groupChild11} alt="" src="icons/pc/company/height.png" />
						<img className={stylesEn.groupChild12} alt="" src="icons/pc/company/height.png" />
						<img className={stylesEn.groupChild13} alt="" src="icons/pc/company/height.png" />
						<img className={stylesEn.groupChild14} alt="" src="icons/pc/company/height.png" />
						<div className={stylesEn.groupChild15} />
						<div className={stylesEn.groupChild16} />
						<div className={stylesEn.groupChild17} />
						<div className={stylesEn.groupChild18} />
						<img className={stylesEn.groupChild19} alt="" src="icons/organizationGroupWidth.png" />
					</div>
					{/* </div> */}
				</div>
			)}
		</div>
	);
};

export default Organization;
