import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Home.module.css';
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Header from "./Header.tsx";
import Main from "./Main.tsx";
import News from "./VdigmNews.tsx";
import Youtubes from './VdigmYoutubes.tsx';
import App from '../App.tsx';
import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';
import i18n from "../locales/i18n";
import axios from 'axios';
import VdigmNews from './VdigmNews.tsx';
import VdigmYoutubes from './VdigmYoutubes.tsx';


interface VdigmNews {
    title: string;
    more: string;
    url: string;
}



interface Element1 {
    pngSrc: string;
    title: string;
    description: string;
}

interface Element2 {
    title: string,
    pngSrc: string[];
    word: string[];
    description: string;
}

let ElementOrAttitude: string[][] | (Element1 | Element2)[][] = [
    [
        {
            pngSrc: "AI Avatar",
            title: "ㅈㄷㄱㄷ",
            description: "EWFewf",
        },
        {
            title: "브이다임",
            pngSrc: ["", "", ""],
            word: ["", "", ""],
            description: "",
        },
    ],
    [
        {
            pngSrc: "",
            title: "ㅈㄷㄱㄷ",
            description: "EWFewf",
        },
        
    ],
    [
        {
            title: "채용절차",
            pngSrc: ["", "", ""],
            word: ["", "", "", ""],
            description: "",
        },
    ],
]

let catergoryOrElementArr: string[][] | Element1[][] = [
    [
        {
            pngSrc: "AI Avatar",
            title: "ㅈㄷㄱㄷ",
            description: "EWFewf",
        },
        {
            pngSrc: "AI Avatar",
            title: "ㅈㄷㄱㄷ",
            description: "EWFewf",
        },
    ]
]

catergoryOrElementArr = [
    [
        "AI Avatar", "Deep Real", "3D Virtual Stage",
    ],
    [
        "3D 라이브 스트리밍을 이끌어갈",
        "3D 라이브 스트리밍을 이끌어갈",
        "3D 라이브 스트리밍을 이끌어갈",
    ]
]



const Home: FunctionComponent = () => {
    const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('*');
//         setData(response.data);
//         console.log('Data Successed!');
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);
    console.log('Home');
    const { t, i18n } = useTranslation();

    // 외부 클릭 시 닫기
    //   const handleUserClose = useCallback((e: any) => {
    //     if (isLanguageMenuOpen && languageRef.current !== null && !languageRef.current.contains(e.target)) setLanguageMenuOpen(false);
    //   }, [isUserMenuOpen, isLanguageMenuOpen]);
    //   useEffect(() => {
    //     document.addEventListener("click", handleUserClose);
    //     return () => document.removeEventListener("click", handleUserClose)
    //   }, [handleUserClose]);

    // const [positionx, setPositionx] = useState<number>(0);
    // const [imgCount, setImgCount] = useState<number>(1);

    // const onSwipeMove = (position: { x: number }) => {
    //     if (vdigmYoutubes[0].pngSrc.length === 1) {
    //         return;
    //     }
    //     if (imgCount === 1 && position.x < 0) {
    //         setPositionx(() => position.x);
    //         return;
    //     }
    //     if (imgCount > 1 && imgCount < vdigmYoutubes[0].pngSrc.length) {
    //         setPositionx(() => position.x);
    //         return;
    //     }
    //     if (imgCount === vdigmYoutubes[0].pngSrc.length && position.x > 0) {
    //         setPositionx(() => position.x);
    //         return;
    //     }
    // };

    // const onSwipeEnd = () => {
    //     if (positionx < -20) {
    //         setImgCount((imgCount) => imgCount + 1);
    //     }
    //     if (positionx > 20) {
    //         setImgCount((imgCount) => imgCount - 1);
    //     }
    //     setPositionx(() => 0);
    // };

    // 언어 변경하기
    // const changeLanguage = (lang: string) => {
    //     i18n.changeLanguage(lang);
    //     setLanguageMenuOpen(false);
    // };

    // return (
    //   <div className="App">
    //     <button onClick={() => toggleLocales("en-US")} title="영어로 바꾸기">
    //       en
    //     </button>
    //     <button onClick={() => toggleLocales("ko-KR")} title="한글로 바꾸기">
    //       ko
    //     </button>
    //     {t("test")}

    //     <hr />
    //     <About />
    //   </div>
    // );

    const ref = useRef<HTMLDivElement>(null);
    const div = ref.current;
    const refId = useRef<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [previousX, setPreviousX] = useState(0);
    const tickEvent = useRef<{ start: Date; tickCnt: number }>({ start: new Date(), tickCnt: 0 });

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setPreviousX(e.clientX);
        tickEvent.current = { start: new Date(), tickCnt: 0 };
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);
        console.log(`${(+new Date() - +tickEvent.current.start) / 1000}초`, tickEvent.current.tickCnt);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !div || refId.current) {
            return;
        }

        refId.current = requestAnimationFrame(() => {
            if (div) {
                const delta = e.clientX - previousX;
                div.scrollLeft += delta;
                setPreviousX(e.clientX);
            }
            refId.current = null;
            tickEvent.current.tickCnt += 1;
        });
    };

    const scrollbarRef = useRef<HTMLDivElement>(null);
    return (
        // <div className={styles.aa}>

        // <div className={styles.aaaa}>
        // <div className={styles.container}>
            <div className={styles.home}>
                <div className={styles.rectangleParent}>
                    {/* <div className={styles.groupChild} /> */}

                    {/* <div className={styles.div1}>브이다임 유튜브</div>
                    <a href={"https://www.youtube.com/@vdigm8432"} target="_blank">
                        <b className={styles.b}>바로가기</b>
                    </a> */}
                    {/* <a href={vdigmNews[3].url} target="_blank">
                        <b className={styles.b1}>{vdigmNews[3].more}</b>
                    </a>
                    <a href={vdigmNews[2].url} target="_blank">
                        <b className={styles.b2}>{vdigmNews[2].more}</b>
                    </a>
                    <a href={vdigmNews[1].url} target="_blank">
                        <b className={styles.b3}>{vdigmNews[1].more}</b>
                    </a>
                    <a href={vdigmNews[0].url} target="_blank">
                        <b className={styles.b4}>{vdigmNews[0].more}</b>
                    </a> */}

                    {/* 스와이프 동작 컴포넌트 */}
                    <Main />
                    <VdigmNews />
                    <VdigmYoutubes />

                    {/* <img className={styles.icon4} alt="" src={mainImages[0].mainSeqSrc} /> */}

                    {/* <b className={styles.it}>{vdigmNews[3].title}</b>
                    <b className={styles.aiAi}>{vdigmNews[1].title}</b>
                    <b className={styles.ai1}>{vdigmNews[0].title}</b>
                    <b className={styles.b6}>{vdigmNews[2].title}</b> */}
                    {/* <img className={styles.icon7} alt="" src="스크롤바.svg" />
                    <img className={styles.icon8} alt="" src="스크롤.svg" /> */}
                </div>

            {/* </div> */}
            {/* <Header /> */}

        </div>
        // // </div>

    );
};

export default Home;

