import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './AIAvatar.module.css';
import stylesEn from './AIAvatarEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    solutions: {
        aiAvatar: string;
        deepReal: string;
        dVirtualStage: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const AIAvatar: FunctionComponent<Props> = ({ solutions, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(solutions.aiAvatar);
                    break;
                case 'history':
                    setSelectedCategory(solutions.deepReal);
                    break;
                case 'organization':
                    setSelectedCategory(solutions.dVirtualStage);
                    break;
            }
        }
    }, [tab, solutions, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(solutions[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.aiAvatar}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.aiAvatar1} onClick={() => handleCategoryClick('aiAvatar')}>
                            {solutions.aiAvatar}
                        </div>
                        <div className={styles.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                            {solutions.deepReal}
                        </div>
                        <div className={styles.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                            {solutions.dVirtualStage}
                        </div>

                        <div className={styles.div}>
                            <p className={styles.p}>3D 라이브 스트리밍을 이끌어 갈</p>
                            <p className={styles.pcMobile}>브이다임만의 기술</p>
                        </div>
                        <div className={styles.generativeAiAvatar}>Generative AI Avatar</div>
                        <div className={styles.responsiveAiAvatar}>Responsive AI Avatar</div>
                        <div className={styles.aiAvatarNpc}>AI Avatar NPC</div>
                        <div className={styles.dContainer}>
                            <p className={styles.d}>사용자의 스마트폰, 웹 캠 등을 이용해 1장의 사진으로 3D</p>
                            <p className={styles.d}>아바타를 생성할 수 있으며, AI 모델과 학습용 데이터를 지속</p>
                            <p className={styles.pcMobile}>적으로 업데이트 중에 있음</p>
                        </div>
                        <div className={styles.aiPcContainer}>
                            <p className={styles.d}>유저와 대화가 가능하고, 따라 움직이고, 자연스럽게 반응하는</p>
                            <p className={styles.d}>브이다임만의 모션 트래킹 AI 아바타로 PC / Mobile 에서 </p>
                            <p className={styles.pcMobile}>실시간 트래킹이 가능</p>
                        </div>
                        <div className={styles.npcContainer}>
                            <p className={styles.d}>버추얼 스트리머를 위해 개발한 브이다임의 아바타 NPC로</p>
                            <p className={styles.d}>스트리머가 오프라인 상태라도 팬들과 소통이 가능하며,</p>
                            <p className={styles.pcMobile}>Persona를 적용해 다양한 성격을 체험할 수 있음</p>
                        </div>

                        <img className={styles.responsiveAiAvatarImage} alt="" src="assets/responsiveAIAvatar.png" />
                        <img className={styles.generativeAiAvatarImage} alt="" src="assets/generativeAIAvatar.png" />
                        <img className={styles.aiAvatarNpcImage} alt="" src="assets/aiAvatarNPCImage.png" />
                    </div>
                </div>
            ) : (

                <div className={stylesEn.aiAvatar}>
                    {/* <div className={styles.rectangleParent}> */}
                        <div className={stylesEn.responsiveAiAvatarImageParent}>
                            {/* <img className={styles.responsiveAiAvatarImage} alt="" src="Responsive Ai Avatar_Image.png" /> */}
                            <div className={stylesEn.groupChild} />

                            <div className={stylesEn.aiAvatar1} onClick={() => handleCategoryClick('aiAvatar')}>
                                AI Avatar
                            </div>
                            <div className={stylesEn.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                                Deep Real
                            </div>
                            <div className={stylesEn.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                3D Virtual Stage
                            </div>


                            <b className={stylesEn.vdigmsTechnologySpearheadinContainer}>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>{`Vdigm's Technology Spearheading `}</p>
                                <p className={stylesEn.dLiveStreaming}>3D Live Streaming</p>
                            </b>


                            <div className={stylesEn.groupItem} />
                            <div className={stylesEn.generativeAiAvatar}>Generative AI Avatar</div>
                            <div className={stylesEn.aiAvatarNpc}>AI Avatar NPC</div>
                            <div className={stylesEn.responsiveAiAvatar}>Responsive AI Avatar</div>
                            <div className={stylesEn.createYour3dContainer}>
                                <p className={stylesEn.createYour3d}>{`Create Your 3D Avatar with a Single Photo Using Your `}</p>
                                <p className={stylesEn.createYour3d}>{`Smartphone, Webcam, or Other Device. Our AI Model `}</p>
                                <p className={stylesEn.dLiveStreaming}>and Training Data are Continuously Updated.</p>
                            </div>
                            <div className={stylesEn.vdigmsAvatarNpcContainer}>
                                <p className={stylesEn.createYour3d}>{`Vdigm’s Avatar NPC: Enabling Virtual Streamers to `}</p>
                                <p className={stylesEn.createYour3d}>{`Interact with Fans Even When Offline, with Customizable `}</p>
                                <p className={stylesEn.dLiveStreaming}>Personalities for a Diverse Streaming Experience</p>
                            </div>
                            <div className={stylesEn.experienceTheFutureContainer}>
                                <p className={stylesEn.createYour3d}>Experience the Future of Human-AI Interaction: Vdigm's</p>
                                <p className={stylesEn.createYour3d}>{`Motion Tracking AI Avatar Enables Real-Time `}</p>
                                <p className={stylesEn.createYour3d}>{`Conversations, Mirrored Movements, and Natural `}</p>
                                <p className={stylesEn.dLiveStreaming}>Responses on PC and Mobile</p>
                            </div>
                            <img className={stylesEn.responsiveAiAvatarImage} alt="" src="assets/responsiveAIAvatar.png" />
                            <img className={stylesEn.generativeAiAvatarImage} alt="" src="assets/generativeAIAvatar.png" />
                            <img className={stylesEn.aiAvatarNpcImage} alt="" src="assets/aiAvatarNPCImage.png" />

                        </div>
                    </div>
                // </div>
            )}
        </div>
    );
};

export default AIAvatar;