import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './Vision.module.css';
import stylesEn from './VisionEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
	introduces: {
		about: string,
		history: string,
		organization: string,
		vision: string,
	};
	setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
	tab: string | null;
	setSearchParams: SetURLSearchParams;
	selectedCategory: any;
}

const Vision: FunctionComponent<Props> = (props) => {
	//const ref = useRef(0);
	//const {introduces, selectedCategory} = props;
	const { introduces, setSelectedCategory, tab, setSearchParams } = props;
	// const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (tab) {
			switch (tab) {
				case 'about':
					setSelectedCategory(introduces.about);
					break;
				case 'history':
					setSelectedCategory(introduces.history);
					break;
				case 'organization':
					setSelectedCategory(introduces.organization);
					break;
				case 'vision':
					setSelectedCategory(introduces.vision);
					break;
				default:
					setSelectedCategory(introduces.about);
			}
		}
	}, [tab, introduces, setSelectedCategory]);

	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	const handleCategoryClick = (category: string) => {
		setSearchParams({ tab: category });
		setSelectedCategory(introduces[category]);
	};

	return (
		<div>
			{currentLanguage === 'ko' ? (
				<div className={styles.div}>
					<div className={styles.rectangleParent}>
						<div className={styles.groupChild} />

						<div className={styles.div1} onClick={() => handleCategoryClick('about')}>
							{t(`company:category:companyAbout`)}
						</div>
						<div className={styles.div2} onClick={() => handleCategoryClick('organization')}>
							{t(`company:category:organization`)}
						</div>
						<div className={styles.div3} onClick={() => handleCategoryClick('history')}>
							{t(`company:category:history`)}
						</div>
						<div className={styles.div4} onClick={() => handleCategoryClick('vision')}>
							{t(`company:category:vision`)}
						</div>

						<div className={styles.aiContainer}>
							<p className={styles.ai}>생성형 AI 아바타 서비스의</p>
							<p className={styles.p}>새로운 패러다임을 만들어 나갑니다.</p>
						</div>

						<div className={styles.div8}>
							<div className={styles.ellipseParent}>
								<div className={styles.groupItem} />
								<div className={styles.inner} />
								<div className={styles.groupInner} />
								<b className={styles.b1}>신기술 개발</b>
							</div>
							{/* <div className={styles.ellipseParent}>
								<div className={styles.groupItem} />
								<div className={styles.groupInner} />
								<b className={styles.b1}>신기술 개발</b>
							</div> */}
						</div>
						<div className={styles.div9}>
							<div className={styles.ellipseParent}>
								<div className={styles.groupChild2} />
								<div className={styles.groupInner} />
								<b className={styles.b3}>
									<p className={styles.p1}>지속 가능한</p>
									<p className={styles.p}>서비스</p>
								</b>
							</div>
						</div>
						<div className={styles.div10}>
							<div className={styles.inner} />
							<div className={styles.groupInner} />
							<b className={styles.b4}>
								<p className={styles.p1}>글로벌</p>
								<p className={styles.p}>경쟁력 확보</p>
							</b>
						</div>
						<div className={styles.div11}>
							<img className={styles.lineIcon} alt="" src="icons/visionLineL.png" />
							<img className={styles.child2} alt="" src="icons/visionLineR.png" />
							<img className={styles.child3} alt="" src="icons/visionLineM.png" />
							<div className={styles.child4} />
							<div className={styles.child5} />
							<div className={styles.vision}>Vision</div>
						</div>
						<div className={styles.div12}>아바타로 만드는 새로운 세상</div>
						<img className={styles.icon4} alt="" src="스크롤바.svg" />
						<img className={styles.icon5} alt="" src="스크롤.svg" />


					</div>

				</div>
			) : (

				<div className={stylesEn.vision}>
					<div className={stylesEn.rectangleParent}>
						<div className={stylesEn.groupChild} />

						<div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
							{t(`company:category:companyAbout`)}
						</div>
						<div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
							{t(`company:category:organization`)}
						</div>
						<div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
							{t(`company:category:history`)}
						</div>
						<div className={stylesEn.vision1} onClick={() => handleCategoryClick('vision')}>
							{t(`company:category:vision`)}
						</div>

						<div className={stylesEn.aNewWorld}>A New World of Avatar Creation</div>
						<b className={stylesEn.shapingTheFutureContainer}>
							<p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
							<p className={stylesEn.experiencesWithGenerative}>Experiences with Generative AI</p>
						</b>

						<div className={stylesEn.div}>
							<img className={stylesEn.child} alt="" src="icons/visionLineL.png" />
							<img className={stylesEn.item} alt="" src="icons/visionLineR.png" />
							<img className={stylesEn.inner} alt="" src="icons/visionLineM.png" />
							<div className={stylesEn.child1} />
							<div className={stylesEn.child2} />
							<div className={stylesEn.vision2}>Vision</div>
						</div>
						<div className={stylesEn.groupParent}>
								{/* <img className={stylesEn.purpleEllipse} /> */}
								{/* <img className={stylesEn.groupIcon1} alt="" src="icons/visionPurpleEllipseEn.png"/> */}

								{/* <img className={stylesEn.pinkEllipse} /> */}

							{/* <img className={stylesEn.groupChild31} alt="" src="icons/visionPurpleEllipse.png" /> */}
							<img className={stylesEn.groupIcon} alt="" src="icons/visionPurpleEllipseEn.png" />
							<img className={stylesEn.groupChild2} alt="" src="icons/visionPinkEllipseEn.png" />
							<img className={stylesEn.groupChild3} alt="" src="icons/visionPurpleEllipseEn.png" />
							<div className={stylesEn.newTechnologyDevelopmentContainer}>
								<p className={stylesEn.experiencesWithGenerative}>New</p>
								<p className={stylesEn.experiencesWithGenerative}>Technology</p>
								<p className={stylesEn.experiencesWithGenerative}>Development</p>
							</div>
							<div className={stylesEn.sustainableServices}>
								<p className={stylesEn.experiencesWithGenerative}>Sustainable</p>
								<p className={stylesEn.experiencesWithGenerative}>Services</p>
							</div>
							<div className={stylesEn.securingGlobalCompetitiveContainer}>
								<p className={stylesEn.experiencesWithGenerative}>Securing</p>
								<p className={stylesEn.experiencesWithGenerative}>Global</p>
								<p className={stylesEn.experiencesWithGenerative}>Competitive</p>
								<p className={stylesEn.experiencesWithGenerative}>-ness</p>
								{/* <img className={stylesEn.purpleEllipse} /> */}
							</div>
						</div>

					</div>
				</div>


			)}
		</div>
	);
};

export default Vision;