import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
// import {useParams} from 'react-router-dom';
import styles from './Menu.module.css';
import { useTranslation } from "react-i18next";
import { Languages, languages } from "../locales/i18n.ts";

import React from 'react';

// const data = {
//     introduce: {
//         name: 
//     }
// }


const Menu: FunctionComponent = () => {
    console.log('Menu');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleChangeLanguage = (lang: Languages) => {
        localStorage.setItem("language", lang); // 로컬 스토리지에 저장할 경우
        i18n.changeLanguage(lang);
    };

    const [menuNames, setMenuNames] =
        useState([
            t(`home:menu:companyAbout`),
            t(`home:menu:vdigmSolution`),
            t(`home:menu:tih`),
            t(`home:menu:recruit`),
            t(`home:menu:contact`),
        ]);

    // const [menuAddress, setMenuAddress] = 
    // useState([tab, introduce, solution, recruit, tih, contact]);

    const [searchParams, setSearchParams] = useSearchParams();
    // const tab = searchParams.get('tab');
    // const introduce = searchParams.get('introduce');
    // const history = searchParams.get('history');

    const setTabParams = () => {
        searchParams.set('tab', 'company');
        setSearchParams(searchParams);
    };

    const solutionTabParams = () => {
        searchParams.set('tab', 'solution');
        setSearchParams(searchParams);
    };

    const tihTabParams = () => {
        searchParams.set('tab', 'tih');
        setSearchParams(searchParams);
    };

    const recruitTabParams = () => {
        searchParams.set('tab', 'recruit');
        setSearchParams(searchParams);
    };


    const contactTabParams = () => {
        searchParams.set('tab', 'contact');
        setSearchParams(searchParams);
    };

    const [isMouseDown1, setIsMouseDown1] = useState(false);
    const [isMouseDown2, setIsMouseDown2] = useState(false);
    const [isMouseDown3, setIsMouseDown3] = useState(false);
    const [isMouseDown4, setIsMouseDown4] = useState(false);
    const [isMouseDown5, setIsMouseDown5] = useState(false);

    // useEffect(() => {
    //     const handleScroll = (event: Event) => {
    //       event.preventDefault();
    //     };

    //     document.addEventListener('scroll', handleScroll, { passive: false });

    //     return () => {
    //       document.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);

    return (

        <div className={styles.div}>
            <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                {/* 언어 바꾸기용 버튼 */}
                {/* <img src="icons/language.png" /> */}
                {/* {languages.map(lang => (

                    <button className={styles.multiLang} key={lang} onClick={() => handleChangeLanguage(lang)}>
                        {<img className={styles.language} src="icons/language.png" />} 
                        {t(`${lang}`)}
                    </button>

                ))} */}
                {/* <section>
                    <Link to="/company?tab=about">
                        <div
                            className={`${isMouseDown1 ? styles.div1Bold : styles.div1}`}
                            onMouseDown={() => setIsMouseDown1(true)}
                            onMouseUp={() => setIsMouseDown1(false)}
                        // onClick={setTabParams}
                        >
                            {menuNames[0]}</div>
                    </Link>
                    <Link to="/solution?tab=aiAvatar">
                        <div
                            // className={`${styles.b} ${isMouseDown ? styles.bBold : styles.b}`}
                            // onMouseDown={handleMouseDown}
                            // onMouseUp={handleMouseUp}
                            // onClick={solutionTabParams}

                            className={`${isMouseDown2 ? styles.bBold : styles.b}`}
                            onMouseDown={() => setIsMouseDown2(true)}
                            onMouseUp={() => setIsMouseDown2(false)}
                        // onClick={solutionTabParams}
                        >
                            {menuNames[1]}
                        </div>
                    </Link>
                    <Link to="/tih">
                        <div
                            className={`${isMouseDown3 ? styles.tihTomorrowInBold : styles.tihTomorrowIn}`}
                            onMouseDown={() => setIsMouseDown3(true)}
                            onMouseUp={() => setIsMouseDown3(false)}
                        // onClick={tihTabParams}
                        >
                            {menuNames[2]}
                        </div>
                    </Link>
                    <Link to="/recruit?tab=idealTalent">
                        <div
                            className={`${isMouseDown4 ? styles.b1Bold : styles.b1}`}
                            onMouseDown={() => setIsMouseDown4(true)}
                            onMouseUp={() => setIsMouseDown4(false)}
                        // onClick={recruitTabParams}
                        >
                            {menuNames[3]}
                        </div>
                    </Link>
                    <Link to="/contact">
                        <div
                            className={`${isMouseDown5 ? styles.contactBold : styles.contact}`}
                            onMouseDown={() => setIsMouseDown5(true)}
                            onMouseUp={() => setIsMouseDown5(false)}
                        // onClick={contactTabParams}
                        >
                            {menuNames[4]}
                        </div>
                    </Link>
                </section> */}
                {/* <div> */}
                {currentLanguage === 'ko' ?
                    (
                        <section>
                            <Link to="/company?tab=about">
                                <div
                                    className={`${isMouseDown1 ? styles.div1Bold : styles.div1}`}
                                    onMouseDown={() => setIsMouseDown1(true)}
                                    onMouseUp={() => setIsMouseDown1(false)}
                                // onClick={setTabParams}
                                >
                                    회사소개</div>
                            </Link>
                            <Link to="/solution?tab=aiAvatar">
                                <div
                                    // className={`${styles.b} ${isMouseDown ? styles.bBold : styles.b}`}
                                    // onMouseDown={handleMouseDown}
                                    // onMouseUp={handleMouseUp}
                                    // onClick={solutionTabParams}

                                    className={`${isMouseDown2 ? styles.bBold : styles.b}`}
                                    onMouseDown={() => setIsMouseDown2(true)}
                                    onMouseUp={() => setIsMouseDown2(false)}
                                // onClick={solutionTabParams}
                                >
                                    브이다임 솔루션
                                </div>
                            </Link>
                            <Link to="/tih">
                                <div
                                    className={`${isMouseDown3 ? styles.tihTomorrowInBold : styles.tihTomorrowIn}`}
                                    onMouseDown={() => setIsMouseDown3(true)}
                                    onMouseUp={() => setIsMouseDown3(false)}
                                // onClick={tihTabParams}
                                >
                                    TIH (Tomrrow, In Here)
                                </div>
                            </Link>
                            <Link to="/recruit?tab=idealTalent">
                                <div
                                    className={`${isMouseDown4 ? styles.b1Bold : styles.b1}`}
                                    onMouseDown={() => setIsMouseDown4(true)}
                                    onMouseUp={() => setIsMouseDown4(false)}
                                // onClick={recruitTabParams}
                                >
                                    채용
                                </div>
                            </Link>
                            <Link to="/contact">
                                <div
                                    className={`${isMouseDown5 ? styles.contactBold : styles.contact}`}
                                    onMouseDown={() => setIsMouseDown5(true)}
                                    onMouseUp={() => setIsMouseDown5(false)}
                                // onClick={contactTabParams}
                                >
                                    Contact
                                </div>
                            </Link>
                        </section>
                    ) : (
                        <section>
                            <Link to="/company?tab=about">
                                <div
                                    className={`${isMouseDown1 ? styles.div1BoldEn : styles.div1En}`}
                                    onMouseDown={() => setIsMouseDown1(true)}
                                    onMouseUp={() => setIsMouseDown1(false)}
                                // onClick={setTabParams}
                                >
                                    About Us</div>
                            </Link>
                            <Link to="/solution?tab=aiAvatar">
                                <div
                                    // className={`${styles.b} ${isMouseDown ? styles.bBold : styles.b}`}
                                    // onMouseDown={handleMouseDown}
                                    // onMouseUp={handleMouseUp}
                                    // onClick={solutionTabParams}

                                    className={`${isMouseDown2 ? styles.bBoldEn : styles.bEn}`}
                                    onMouseDown={() => setIsMouseDown2(true)}
                                    onMouseUp={() => setIsMouseDown2(false)}
                                // onClick={solutionTabParams}
                                >
                                    Vdigm Solution
                                </div>
                            </Link>
                            <Link to="/tih">
                                <div
                                    className={`${isMouseDown3 ? styles.tihTomorrowInBold : styles.tihTomorrowIn}`}
                                    onMouseDown={() => setIsMouseDown3(true)}
                                    onMouseUp={() => setIsMouseDown3(false)}
                                // onClick={tihTabParams}
                                >
                                    TIH (Tomrrow, In Here)
                                </div>
                            </Link>
                            <Link to="/recruit?tab=idealTalent">
                                <div
                                    className={`${isMouseDown4 ? styles.b1BoldEn : styles.b1En}`}
                                    onMouseDown={() => setIsMouseDown4(true)}
                                    onMouseUp={() => setIsMouseDown4(false)}
                                // onClick={recruitTabParams}
                                >
                                    Career
                                </div>
                            </Link>
                            <Link to="/contact">
                                <div
                                    className={`${isMouseDown5 ? styles.contactBold : styles.contact}`}
                                    onMouseDown={() => setIsMouseDown5(true)}
                                    onMouseUp={() => setIsMouseDown5(false)}
                                // onClick={contactTabParams}
                                >
                                    Contact
                                </div>
                            </Link>
                            {/* <img className={styles.icon5} alt="" src="icons/menuBar.png" /> */}
                        </section>
                    )}
                {/* </div> */}


                {/* <img className={styles.icon2} alt="" src="한영.svg" />
                <div className={styles.kor}>KOR</div>
                <div className={styles.eng}>ENG</div> */}

                <div className={styles.multiLanguage}>
                    <img className={styles.iconLang} alt="" src="icons/language.png" />
                    {languages.map(lang => (
                        <div>
                            <div key={lang} onClick={() => handleChangeLanguage("ko")}>

                                {currentLanguage === "ko"
                                    ? <div className={styles.korOn}>KOR</div>
                                    : <div className={styles.korOff}>KOR</div>}
                            </div>
                            <div key={lang} onClick={() => handleChangeLanguage("en")}>
                                {currentLanguage === "en"
                                    ? <div className={styles.engOn}>ENG</div>
                                    : <div className={styles.engOff}>ENG</div>}

                            </div>
                        </div>
                    ))}
                </div>
                {/* <ul>
                    <li>
                    <Link to="/introduce/">
                        <div className={styles.div1} onClick={setSortParams}>{ menuNames[0] }</div>
                    </Link>
                </li>
                <Link to="/history">
                        <div className={styles.div2} onClick={appendSortParams}>{ menuNames[1] }</div>
                    </Link>
                    <div className={styles.div3}>{menuNames[2]}</div>
                    <div className={styles.tihTomorrowIn}>{menuNames[3]}</div>
                    <div className={styles.contact}>{menuNames[4]}</div>
                    
                </ul> */}
            </div>
        </div>);
};

export default Menu;
