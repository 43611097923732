import { FunctionComponent, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from 'react-router-dom';
import { Languages, languages } from "../../locales/i18n.ts";

import styles from './Header.module.css';
import stylesEn from './HeaderEn.module.css';

import Menu from './Menu.tsx'

import React from 'react';

interface Menus {
    name: string;
    link: string;
    isMouseHover: boolean;
    hoveredMenuIndex: number;
}

const Header: FunctionComponent = () => {
    console.log('PC HEADER');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangeLanguage = (lang: Languages) => {
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
    };

    // const [menuNames, setMenuNames] = useState<string[]>([
    //     t(`home:menu:companyAbout`),
    //     t(`home:menu:vdigmSolution`),
    //     t(`home:menu:tih`),
    //     t(`home:menu:recruit`),
    //     t(`home:menu:contact`),
    // ]);

    // const [isMouseDown1, setIsMouseDown1] = useState(false);
    // const [isMouseDown2, setIsMouseDown2] = useState(false);
    // const [isMouseDown3, setIsMouseDown3] = useState(false);
    // const [isMouseDown4, setIsMouseDown4] = useState(false);
    // const [isMouseDown5, setIsMouseDown5] = useState(false);

    // const [isMouseHoverArr, setIsMouseHoverArr] = useState<boolean[]>([
    //     false, false, false, false, false
    // ]);

    // const [hoveredMenuIndex, hoveredMenuIndex] = useState<number>(0);


    const [menusObjArr, setmenusObjArr] = useState<Menus[]>([
        {
            name: t(`home:menu:companyAbout`),
            link: "/company?tab=about",
            isMouseHover: false,
            hoveredMenuIndex: 0,
        },
        {
            name: t(`home:menu:vdigmSolutionPc`),
            link: "/solution?tab=aiAvatar",
            isMouseHover: false,
            hoveredMenuIndex: 1,

        },
        {
            name: t(`home:menu:tihPc`),
            link: "/tih",
            isMouseHover: false,
            hoveredMenuIndex: 2,

        },
        {
            name: t(`home:menu:recruit`),
            link: "/recruit?tab=idealTalent",
            isMouseHover: false,
            hoveredMenuIndex: 3,

        },
        {
            name: t(`home:menu:contact`),
            link: "/contact",
            isMouseHover: false,
            hoveredMenuIndex: 4,

        }
    ])

    // const handleCategoryClick = (index: number) => {
    //     setSelectedCategoryIndex(index);

    //     switch (index) {
    //         case 0:
    //             setSearchParams({ tab: 'aiAvatar' });
    //             break;
    //         case 1:
    //             setSearchParams({ tab: 'deepReal' });
    //             break;
    //         case 2:
    //             setSearchParams({ tab: '3dVirtualStage' });
    //             break;
    //         default:
    //             setSearchParams({});
    //             break;
    //     }
    // };

    return (
        <div className={styles.div}>
            {/* <div className={styles.rectangleParent}>
                <div className={styles.groupChild} /> */}
            <Link to="/">
                <img className={styles.icon} alt="" src="icons/pc/main/vdigmLogo.png" />
            </Link>

            {/* {menuNames.map((element, index) => (
                    <div key={index} className={styles.elementContainer}>
                         
                        {setMenuNames[index]}
                    ))} */}
            {currentLanguage === 'ko' ?
                (
                    <div className={styles.parent1}>
                        <Link to={menusObjArr[0].link}>
                            <div className={styles.b6}>회사소개</div>
                        </Link>
                        <Link to={menusObjArr[1].link}>
                            <div className={styles.b7}>솔루션</div>
                        </Link>
                        <Link to={menusObjArr[2].link}>
                            <div className={styles.tih}>TIH</div>
                        </Link>
                        <Link to={menusObjArr[3].link}>
                            <div className={styles.b8}>채용</div>
                        </Link>
                        <Link to={menusObjArr[4].link}>
                            <div className={styles.contact}>Contact</div>
                        </Link>
                    </div>
                ) : (
                    <div className={stylesEn.parent1}>
                        <Link to={menusObjArr[0].link}>
                            <div className={stylesEn.b6}>About Us</div>
                        </Link>
                        <Link to={menusObjArr[1].link}>
                            <div className={stylesEn.b7}>Solution</div>
                        </Link>
                        <Link to={menusObjArr[2].link}>
                            <div className={stylesEn.tih}>TIH</div>
                        </Link>
                        <Link to={menusObjArr[3].link}>
                            <div className={stylesEn.b8}>Recruit</div>
                        </Link>
                        <Link to={menusObjArr[4].link}>
                            <div className={stylesEn.contact}>Contact</div>
                        </Link>
                    </div>
                )
            }


            {/* <div className={styles.parent1}>
                <Link to={menusObjArr[0].link}>
                    <div className={styles.b6}>{menusObjArr[0].name}</div>
                </Link>
                <Link to={menusObjArr[1].link}>
                    <div className={styles.b7}>{menusObjArr[1].name}</div>
                </Link>
                <Link to={menusObjArr[2].link}>
                    <div className={styles.tih}>{menusObjArr[2].name}</div>
                </Link>
                <Link to={menusObjArr[3].link}>
                    <div className={styles.b8}>{menusObjArr[3].name}</div>
                </Link>
                <Link to={menusObjArr[4].link}>
                    <div className={styles.contact}>{menusObjArr[4].name}</div>
                </Link>
            </div> */}

            {/* <div className={styles.parent}>
                {menusObjArr.map((menu, index) => (
                    <Link to={menu.link} key={index}>
                        <button
                            className={styles.menuButton}
                        >
                            {menu.name}
                        </button>
                    </Link>
                ))}
            </div> */}



            <Menu />

            <div className={styles.line} />

            {/* <div className={styles.container}>
                <img className={styles.icon1} alt="" src="icons/language.png" />
                <div>
                {currentLanguage === "ko"
								? <div className={styles.korOn}>KOR</div>
								: <div className={styles.korOff}>KOR</div>}
                    </div>
                <div>
                {currentLanguage === "en"
								? <div className={styles.enOn}>ENG</div>
								: <div className={styles.enOff}>ENG</div>}
                </div>
            </div> */}

            {/* <img className={styles.groupIcon} alt="" src="Group 1355.svg" />

            <img className={styles.groupChild1} alt="" src="Group 1373.png" />
            <div className={styles.ellipseDiv} />
            <div className={styles.groupChild2} />
            <div className={styles.groupChild3} />
            <div className={styles.groupChild4} />
            <img className={styles.groupChild5} alt="" src="Group 1377.svg" /> */}
            {/* </div> */}
        </div >

    );
};

export default Header;

