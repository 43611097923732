import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './Organization.module.css';
import stylesEn from './OrganizationEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    introduces: {
        about: string,
        history: string,
        organization: string,
        vision: string,
    };
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
}

const Organization: FunctionComponent<Props> = (props) => {
    //const ref = useRef(0);
    //const {introduces, selectedCategory} = props;
    const { introduces, setSelectedCategory, tab, setSearchParams } = props;
    // const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
                default:
                    setSelectedCategory(introduces.about);
            }
        }
    }, [tab, introduces, setSelectedCategory]);
    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };
    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />
                        <div className={styles.div1} onClick={() => handleCategoryClick('about')}>
                            {t(`company:category:companyAbout`)}
                        </div>
                        <div className={styles.div2} onClick={() => handleCategoryClick('organization')}>
                            {t(`company:category:organization`)}
                        </div>
                        <div className={styles.div3} onClick={() => handleCategoryClick('history')}>
                            {t(`company:category:history`)}
                        </div>
                        <div className={styles.div4} onClick={() => handleCategoryClick('vision')}>
                            {t(`company:category:vision`)}
                        </div>
                        <div className={styles.aiContainer}>
                            <p className={styles.ai}>생성형 AI 아바타 서비스의</p>
                            <p className={styles.p}>새로운 패러다임을 만들어 나갑니다.</p>
                        </div>

                        <b className={styles.ceo}>CEO</b>
                        <b className={styles.cto}>CTO</b>
                        <b className={styles.ai1}>기업부설 AI 연구소</b>
                        <b className={styles.b1}>{`기획 & 마케팅`}</b>
                        <b className={styles.tih}>TIH 개발</b>
                        <b className={styles.d}>3D 디자인</b>
                        <b className={styles.b2}>서비스 운영</b>
                        <img className={styles.groupItem} alt="" src="icons/organizationLine.png" />
                        <img className={styles.groupInner} alt="" src="icons/chartPinkLine.png" />
                        <img className={styles.lineIcon} alt="" src="icons/chartPinkLine.png" />
                        <img className={styles.groupChild1} alt="" src="icons/chartPurpleLine.png" />
                        <img className={styles.groupChild2} alt="" src="icons/chartPurpleLine.png" />
                        <img className={styles.groupChild3} alt="" src="icons/chartPurpleLine.png" />
                        <img className={styles.groupChild4} alt="" src="icons/chartPurpleLine.png" />
                        <img className={styles.groupChild5} alt="" src="icons/chartPinkLine.png" />
                        <div className={styles.ellipseDiv} />
                        <div className={styles.groupChild6} />
                        <div className={styles.groupChild7} />
                        <div className={styles.groupChild8} />
                        <div className={styles.groupChild9} />
                        <div className={styles.groupChild10} />
                        <div className={styles.groupChild11} />
                    </div>
                </div>

            ) : (
                <div className={stylesEn.organizationChart}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
                            {t(`company:category:companyAbout`)}
                        </div>
                        <div className={stylesEn.organizationChart1} onClick={() => handleCategoryClick('organization')}>
                            {t(`company:category:organization`)}
                        </div>
                        <div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
                            {t(`company:category:history`)}
                        </div>
                        <div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
                            {t(`company:category:vision`)}
                        </div>

                        <b className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.experiencesWithGenerative}>Experiences with Generative AI</p>
                        </b>

                        <div className={stylesEn.groupItem} />
                        <img className={stylesEn.groupInner} alt="" src="Line 403.svg" />
                        <div className={stylesEn.ellipseDiv} />

                        <b className={stylesEn.ceo}>CEO</b>
                        <b className={stylesEn.cto}>CTO</b>
                        <div className={stylesEn.corporateAiResearchContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>Corporate AI</p>
                            <p className={stylesEn.researchInstitute}>Research Institute</p>
                        </div>
                        <div className={stylesEn.planningMarketingContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`Planning &`}</p>
                            <p className={stylesEn.experiencesWithGenerative}>Marketing</p>
                        </div>
                        <div className={stylesEn.tihDevelopment}>TIH Development</div>
                        <div className={stylesEn.dDesign}>3D Design</div>
                        <div className={stylesEn.serviceOperations}>
                            <p className={stylesEn.headOffice10f1012293Bu}>Service</p>
                            <p className={stylesEn.experiencesWithGenerative}>Operations</p>
                        </div>
                        <img className={stylesEn.lineIcon} alt="" src="icons/organizationLine.png" />
                        <img className={stylesEn.groupChild2} alt="" src="icons/chartPinkLine.png" />
                        <img className={stylesEn.groupChild3} alt="" src="icons/chartPinkLine.png" />
                        <img className={stylesEn.groupChild4} alt="" src="icons/chartPurpleLine.png" />
                        <img className={stylesEn.groupChild5} alt="" src="icons/chartPurpleLine.png" />
                        <img className={stylesEn.groupChild6} alt="" src="icons/chartPurpleLine.png" />
                        <img className={stylesEn.groupChild7} alt="" src="icons/chartPurpleLine.png" />
                        <img className={stylesEn.groupChild8} alt="" src="icons/chartPinkLine.png" />
                        <div className={stylesEn.groupChild9} />
                        <div className={stylesEn.groupChild10} />
                        <div className={stylesEn.groupChild11} />
                        <div className={stylesEn.groupChild12} />
                        <div className={stylesEn.groupChild13} />
                        <div className={stylesEn.groupChild14} />
                        <div className={stylesEn.groupChild15} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Organization;