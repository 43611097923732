import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './RecruitProcess.module.css';
import stylesEn from './RecruitProcessEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcess: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

interface RecruitURL {
    pngSrc: string,
    url: string,
}

const recruitURL: RecruitURL[] = [
    {
        pngSrc: "icons/saraminLogo.png",
        url: "https://www.saramin.co.kr/zf_user/company-info/view?csn=SnIrQTdrREhqUHh5MkdCSVhidzl2UT09&popup_yn=y",
    },
    {
        pngSrc: "icons/jobkoreaLogo.png",
        url: "https://www.jobkorea.co.kr/Recruit/Co_Read/C/31624651",
    },
]

const RecruitProcess: FunctionComponent<Props> = (props) => {
    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcess':
                    setSelectedCategory(recruits.recruitProcess);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.div4}>
                            <p className={styles.p}>브이다임과</p>
                            <p className={styles.p}>함께하는 여정이에요!</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.b6} onClick={() => handleCategoryClick('idealTalent')}>
                                인재상
                            </div>
                            <div className={styles.b2} onClick={() => handleCategoryClick('welfareBenefit')}>
                                복리후생
                            </div>
                            <div className={styles.div2} onClick={() => handleCategoryClick('recruitProcess')}>
                                채용절차
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>

                        <div className={styles.div1}>궁금한게 있다면?</div>
                        <div className={styles.bizvdigmcomContainer}>
                            <p className={styles.bizvdigmcom}>
                                <span className={styles.bizvdigmcom1}>biz@vdigm.com</span>
                                <b> 으로 문의주시면 빠른 시일 내에</b>
                            </p>
                            <p className={styles.p}>
                                <b>답변해드리겠습니다.</b>
                            </p>
                        </div>
                        <b className={styles.b}>채용절차</b>
                        <b className={styles.b1}>서류 접수는 여기서!</b>

                            <div className={styles.groupChild1} />
                            <div className={styles.groupChild2} />
                            <div className={styles.groupChild3} />
                            <div className={styles.groupChild4} />
                            <img className={styles.vectorIcon} alt="" src="icons/pc/recruit/processLine.png" />
                        <div className={styles.ellipseParent}>
                            <div className={styles.div8}>서류 접수</div>
                            <div className={styles.div9}>1차 면접</div>
                            <div className={styles.div10}>최종합격</div>
                            <div className={styles.div11}>2차 면접</div>
                            <div className={styles.div12}>처우협의</div>
                            <div className={styles.div13}>화상 면접</div>
                        </div>
                        {/* <b className={styles.submitAResumeContainer}>
                            <p className={styles.thisIsThe}>Submit</p>
                            <p className={styles.thisIsThe}>a resume</p>
                        </b>
                        <b className={styles.onlineInterview}>
                            <p className={styles.thisIsThe}>Online</p>
                            <p className={styles.thisIsThe}>Interview</p>
                        </b>
                        <b className={styles.finalPass}>Final Pass</b>
                        <div className={styles.interviewParent}>
                            <b className={styles.interview1}>Interview</b>
                            <div className={styles.negotiation}>Negotiation</div>
                        </div> */}
                        <div className={styles.rectangleGroup}>
                            <div className={styles.groupChild5} />
                            {/* <img className={styles.imageIcon} alt="" src="icons/pc/recruit/jobKoreaLogo.png" /> */}
                            <div className={styles.groupChild6} />
                            {/* <img className={styles.typeLogoIcon} alt="" src="icons/pc/recruit/saraminLogo.png" /> */}
                            <a href={recruitURL[1].url} target="_blank">
                                <img className={styles.imageIcon} alt="" src="icons/pc/recruit/jobKoreaLogo.png" />
                            </a>
                            <a href={recruitURL[0].url} target="_blank">
                                <img className={styles.typeLogoIcon} alt="" src="icons/pc/recruit/saraminLogo.png" />
                            </a>
                        </div>
                        <b className={styles.b7}>
                            <p className={styles.bizvdigmcom}>채용 사이트를 통해 지원해 주시면 꼼꼼히 보고 연락드리겠습니다.</p>
                            <p className={styles.p}>지원해주셔서 감사합니다.</p>
                        </b>
                        <img className={styles.maskGroupIcon} alt="" src="assets/pc/recruit/mail.png" />

                    </div>
                </div>
            ) : (
                <div className={stylesEn.recruitmentProcess}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.thisIsTheContainer}>
                            <p className={stylesEn.thisIsThe}>This is the journey</p>
                            <p className={stylesEn.thisIsThe}>with vdigm!</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.people} onClick={() => handleCategoryClick('idealTalent')}>
                                People
                            </div>
                            <div className={stylesEn.benefits} onClick={() => handleCategoryClick('welfareBenefit')}>
                                Benefits
                            </div>
                            <div className={stylesEn.recruitmentProcess1} onClick={() => handleCategoryClick('recruitProcess')}>
                                Recruitment Process
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <div className={stylesEn.benefits}>Benefits</div>
                        <b className={stylesEn.recruitmentProcess1}>Recruitment Process</b> */}
                        {/* <div className={stylesEn.people}>People</div> */}
                        <div className={stylesEn.feelFreeToAskIfYouHaveAParent}>
                            <b className={stylesEn.feelFreeToContainer}>
                                <p className={stylesEn.feelFreeTo}>{`Feel free to ask `}</p>
                                <p className={stylesEn.thisIsThe}>if you have any questions.</p>
                            </b>
                            <div className={stylesEn.forAnyInquiriesContainer}>
                                <p className={stylesEn.forAnyInquiries}>{`For any inquiries, please do not hesitate to contact `}</p>
                                <p className={stylesEn.forAnyInquiries}>
                                    <span className={stylesEn.us}>{`us `}</span>
                                    <b className={stylesEn.bizvdigmcom}>biz@vdigm.com</b>
                                    <span>{`. We will endeavor to respond to `}</span>
                                </p>
                                <p className={stylesEn.thisIsThe}>your questions promptly.</p>
                            </div>
                        </div>
                        <b className={stylesEn.b}>Recruitment Process</b>
                        <b className={stylesEn.submitYourResumes}>Submit your resumes here!</b>
                        <div className={stylesEn.groupChild1} />
                        <div className={stylesEn.groupChild2} />
                        <div className={stylesEn.groupChild3} />
                        <div className={stylesEn.groupChild4} />
                        <img className={stylesEn.vectorIcon} alt="" src="icons/pc/recruit/processLine.png" />
                        <b className={stylesEn.submitAResumeContainer}>
                            <p className={stylesEn.thisIsThe}>Submit</p>
                            <p className={stylesEn.thisIsThe}>a resume</p>
                        </b>
                        <b className={stylesEn.onlineInterview}>
                            <p className={stylesEn.thisIsThe}>Online</p>
                            <p className={stylesEn.thisIsThe}>Interview</p>
                        </b>
                        <b className={stylesEn.finalPass}>Final Pass</b>
                        <div className={stylesEn.interviewParent}>
                            <b className={stylesEn.interview1}>Interview</b>
                            <div className={stylesEn.negotiation}>Negotiation</div>
                        </div>
                        <div className={stylesEn.rectangleGroup}>
                            <div className={stylesEn.groupChild5} />
                            <div className={stylesEn.groupChild6} />
                            {/* <img className={stylesEn.typeLogoIcon} alt="" src="icons/pc/recruit/saraminLogo.png" /> */}

                            <a href={recruitURL[1].url} target="_blank">
                                <img className={stylesEn.imageIcon} alt="" src="icons/pc/recruit/jobKoreaLogo.png" />
                            </a>
                            <a href={recruitURL[0].url} target="_blank">
                                <img className={stylesEn.typeLogoIcon} alt="" src="icons/pc/recruit/saraminLogo.png" />
                            </a>
                        </div>
                        <div className={stylesEn.thankYouForContainer}>
                            <p className={stylesEn.feelFreeTo}>Thank you for your interest in our company.</p>
                            <p className={stylesEn.thisIsThe}>We will review all applications carefully and contact candidates for further assessment</p>
                        </div>
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/recruit/mail.png" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecruitProcess;

