import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './VdigmYoutubes.module.css';
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Header from "./Header.tsx";
import axios from 'axios';

import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';
import i18n from "../locales/i18n.ts";

interface Youtubes {
    titleKo: string;
    titleEn: string;
    image: string;
    url: string;
}

// const vdigmYoutubes: Array<VdigmYoutubes> = [
//     {
//         title: "유튜브 영상 제목",
//         pngSrc: "assets/tih.png",
//         url: "",
//     },
//     {
//         title: "",
//         pngSrc: "assets/solution1.png",
//         url: "",
//     },
//     {
//         title: "",
//         pngSrc: "assets/solution3.png",
//         url: "https://www.youtube.com/watch?v=iDH_TthA0ig",
//     },
// ]

interface VdigmYoutubesT {
    title: string;
    pngSrc: string;
    url: string;
}

// let more: string = "더보기";


// interface Description {
//     description: string;
// }

// interface RecruitCategory {
//     title: string;
//     png: string;
//     description: Description[];
// }

// interface RecruitCategoryObj {
//     [key: string]: 
// }

// const vd: Array<RecruitCategory> = [

// ]

const VdigmYoutubes: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // const [ imagesArr, setImagesArr ] = useState<string[]>([]);
    // const [ image, setImage ] = useState("");
    // const [ urlsArr, setUrlsArr ] = useState<string[]>([]);
    // const [ url, setUrl ] = useState("");

    // const [youtubesObjArr, setYoutubesObjArr] = useState<Youtubes[]>([{
    //     titleKo: "",
    //     titleEn: "",
    //     image: "",
    //     url: "",
    // }]);
    const [youtubesObj, setYoutubesObj] = useState<Youtubes>({
        titleKo: "",
        titleEn: "",
        image: "",
        url: "",
    });

    const [youtubesObjArr, setYoutubesObjArr] = useState<Youtubes[]>([{
        titleKo: "AI 아바타 영어 교육 데모 비디오",
        titleEn: "AI Avatar English Teaching Demo",
        image: "assets/youtube/aiAvatarTutorial.png",
        url: "https://youtu.be/_b6N2_01CMs",
    },
    {
        titleKo: "브이다임 메타버스 패션쇼",
        titleEn: "Vdigm Metaverse Fashionshow",
        image: "assets/youtube/fashionShow.png",
        url: "https://youtu.be/iDH_TthA0ig",
    },
    {
        titleKo: "딥 리얼 데이터 젠",
        titleEn: "Deep Real Data Gen",
        image: "assets/youtube/deepRealDataGen.png",
        url: "https://youtu.be/dSEVJs2QpaA",
    },
    {
        titleKo: "Virtual Stage 및 3D 객체 배치 비디오",
        titleEn: "Virtual Stage & 3D Object Placement",
        image: "assets/youtube/virtualStage3dObjectPlacement.png",
        url: "https://youtu.be/m-Vm_9xLi_M",
    }
    ]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(
            'http://vdigm.com/api/youtubesPost', {
            method: "post",
            body: JSON.stringify({
                titleKo: youtubesObj.titleKo,
                titleEn: youtubesObj.titleEn,
                image: youtubesObj.image,
                url: youtubesObj.url
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        const result = await response.json();
        console.warn(result);
        if (result) {
            alert("Data saved succesfully");
            setYoutubesObjArr(prevImages => [...prevImages, {
                titleKo: result.titleKo,
                titleEn: result.titleEn,
                image: result.image,
                url: result.url,
            }]);
            setYoutubesObj({
                titleKo: "",
                titleEn: "",
                image: "",
                url: "",
            });
        };
    };

    useEffect(() => {
        const fetchMainImages = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/youtubes');
                const youtubeData = response.data;
                setYoutubesObjArr(youtubeData.map((item: Youtubes) => ({
                    titleKo: item.titleKo,
                    titleEn: item.titleEn,
                    image: item.image,
                    url: item.url
                })));

                // setMainImagesArr(imageData);

            } catch (error) {
                console.error('Error fetching youtubes:', error);
            }
        };

        fetchMainImages(); // 이미지 데이터 가져오기 호출
    }, []);

    // let vdigmYoutubesT: Array<VdigmYoutubesT> = [];
    // // let recruitCategoryArr: 
    // let mainImgsArr: Array<string> = [];

    // Object.entries(vdigmYoutubesT).forEach(([key, value])) => {

    // }

    // const ref = useRef<HTMLDivElement>(null);

    return (
        <div>
            {/* // <div className={styles.aa}>

        // <div className={styles.aaaa}>
        // <div className={styles.container}>
        //     <div className={styles.home}>
        //         <div className={styles.rectangleParent}> */}
            {/* <div className={styles.groupChild} /> */}
            {currentLanguage === "ko"
                ? (
                    <div>
                        <div className={styles.div1Ko}>{t(`home:vdigmYoutubes:vdigmYoutube`)}</div>
                        <a href={"https://www.youtube.com/@vdigm"} target="_blank">
                            <b className={styles.bKo}>{t(`home:vdigmYoutubes:visit`)}</b>
                        </a>
                    </div>
                ) : (
                    <div>
                        <div className={styles.div1En}>{t(`home:vdigmYoutubes:vdigmYoutube`)}</div>
                        <a href={"https://www.youtube.com/@vdigm"} target="_blank">
                            <b className={styles.bEn}>{t(`home:vdigmYoutubes:visit`)}</b>
                        </a>
                    </div>
                )}
            {/* <div className={styles.manager}>
                <form onSubmit={handleOnSubmit}>
                    <input
                        type="text"
                        placeholder="titleKo"
                        value={youtubesObj.titleKo}
                        onChange={(e) => setYoutubesObj({
                            ...youtubesObj, titleKo: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="titleEn"
                        value={youtubesObj.titleEn}
                        onChange={(e) => setYoutubesObj({
                            ...youtubesObj, titleEn: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="image"
                        value={youtubesObj.image}
                        onChange={(e) => setYoutubesObj({
                            ...youtubesObj, image: e.target.value
                        })}
                    />
                    <input
                        type="text"
                        placeholder="url"
                        value={youtubesObj.url}
                        onChange={(e) => setYoutubesObj({
                            ...youtubesObj, url: e.target.value
                        })}
                    />
                    <button type="submit">Submit</button>
                </form>
                <button onClick={addMainImage}>Add Image</button>
            </div> */}
            <Swiper
                modules={[Scrollbar, Autoplay]}
                slidesPerGroup={1}
                slidesPerView={1.6}
                centeredSlides={true}
                spaceBetween={20}
                autoplay={{ delay: 5000, disableOnInteraction: false, stopOnLastSlide: false }}
                freeMode={false}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true, dragSize: 50 }}
                loop={true}
                className={styles.div2}
            >
                {youtubesObjArr.map((youtube, index) => (
                    <SwiperSlide key={index}>
                        <a href={youtube.url} className={styles.youtubeLink} target="_blank">
                            <img className={styles.swiperMain} alt="" src={youtube.image} />
                            {/* <b className={styles.title}>{youtube.titleKo}</b> */}
                            {currentLanguage === "ko"
                                ? <b className={styles.titleKo}>{youtube.titleKo}</b>
                                : <b className={styles.titleEn}>{youtube.titleEn}</b>
                            }
                        </a>
                    </SwiperSlide>
                ))}

                {/* <SwiperSlide>
                    <img className={styles.swiper1} src={vdigmYoutubes[0].pngSrc} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className={styles.swiper1} src={vdigmYoutubes[1].pngSrc} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className={styles.swiper1} src={vdigmYoutubes[2].pngSrc} />
                </SwiperSlide> */}
            </Swiper>
            {/* <img className={styles.icon2} alt="" src={mainImages[0].mainSrc} /> */}

            {/* <img className={styles.icon4} alt="" src={mainImages[0].mainSeqSrc} /> */}
        </div>
        //        {/* </div>
        //      </div>
        // </div>
        // </div> */}
    );
};

export default VdigmYoutubes;