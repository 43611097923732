import { FunctionComponent } from 'react';
import styles from './VdigmYoutubes.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import 'swiper/css';
import './SwiperYoutubes.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';

import axios from 'axios';

interface Youtubes {
    titleKo: string;
    titleEn: string;
    image: string;
    url: string;
}

const VdigmYoutubes: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // const [youtubesObjArr, setYoutubesObjArr] = useState<Youtubes[]>([{
    //     titleKo: "",
    //     titleEn: "",
    //     image: "",
    //     url: "",
    // }]);
    const [youtubesObj, setYoutubesObj] = useState<Youtubes>({
        titleKo: "",
        titleEn: "",
        image: "",
        url: "",
    });

    const [youtubesObjArr, setYoutubesObjArr] = useState<Youtubes[]>([{
        titleKo: "AI 아바타 영어 교육 데모 비디오",
        titleEn: "AI Avatar Tutor English Teaching Demo Video",
        image: "assets/youtube/aiAvatarTutorial.png",
        url: "https://youtu.be/_b6N2_01CMs",
    },
    {
        titleKo: "브이다임 메타버스 패션쇼",
        titleEn: "Vdigm Metaverse Fashionshow",
        image: "assets/youtube/fashionShow.png",
        url: "https://youtu.be/iDH_TthA0ig",
    },
    {
        titleKo: "딥 리얼 데이터 젠",
        titleEn: "Deep Real Data Gen",
        image: "assets/youtube/deepRealDataGen.png",
        url: "https://youtu.be/dSEVJs2QpaA",
    },
    {
        titleKo: "Virtual Stage 및 3D 객체 배치 비디오",
        titleEn: "Virtual Stage and 3D Object Placement Video",
        image: "assets/youtube/virtualStage3dObjectPlacement.png",
        url: "https://youtu.be/m-Vm_9xLi_M",
    }
    ]);

    useEffect(() => {
        const fetchMainImages = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/youtubes');
                const youtubeData = response.data;
                setYoutubesObjArr(youtubeData.map((item: Youtubes) => ({
                    titleKo: item.titleKo,
                    titleEn: item.titleEn,
                    image: item.image,
                    url: item.url
                })));

                // setMainImagesArr(imageData);

            } catch (error) {
                console.error('Error fetching youtubes:', error);
            }
        };

        fetchMainImages(); // 이미지 데이터 가져오기 호출
    }, []);

    return (
        <div className={styles.youtubes}>
            {currentLanguage === "ko" ? (
                <div className={styles.title1111}>{t(`home:vdigmYoutubes:vdigmYoutube`)}</div>
            ) : (
                <div className={styles.title1111En}>{t(`home:vdigmYoutubes:vdigmYoutube`)}</div>
            )}
            <a href={"https://www.youtube.com/@vdigm"} target="_blank">
                {/* <b className={styles.visitKo}>{t(`home:vdigmYoutubes:visitPc`)}</b> */}
                {currentLanguage === "ko"
                    ? <b className={styles.visitKo}>{t(`home:vdigmYoutubes:visitPc`)}</b>
                    : <b className={styles.visitEn}>{t(`home:vdigmYoutubes:visitPc`)}</b>
                }
            </a>
            <Swiper
                modules={[Pagination, Autoplay]}
                loop={true}
                slidesPerGroup={1}
                slidesPerView={3}
                // loopedSlides={3}
                // loopAdditionalSlides={4}
                // centeredSlides={true}
                spaceBetween={20}
                autoplay={{ delay: 5000, disableOnInteraction: false, stopOnLastSlide: false }}
                freeMode={false}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true, dragSize: 50 }}
                // loopAdditionalSlides={1}
                className={styles.div2}
            >
                {youtubesObjArr.map((youtube, index) => (
                    <SwiperSlide key={index}>
                        <a href={youtube.url} className={styles.youtubeLink} target="_blank">
                            <img className={styles.swiperMain} alt="" src={youtube.image} />
                            {/* <b className={styles.title}>{youtube.titleKo}</b> */}
                            {currentLanguage === "ko"
                                ? <b className={styles.title}>{youtube.titleKo}</b>
                                : <b className={styles.title}>{youtube.titleEn}</b>
                            }
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* <div>
                <div className={styles.title1111}>브이다임 유튜브</div>
                <b className={styles.visit1111}>유튜브 방문하기</b>
                <div className={styles.groupItem1111} />
                <div className={styles.groupInner1111} />
                <div className={styles.rectangleDiv1111} />
                <img className={styles.groupIcon1111} alt="" src="Group 1355.svg" />

                <div className={styles.ellipseDiv1111} />
                <div className={styles.groupChild21111} />
                <div className={styles.groupChild31111} />

                <div className={styles.groupChild41111} />
                <img className={styles.groupChild51111} alt="" src="Group 1377.svg" />
            </div> */}
        </div>
    );
};

export default VdigmYoutubes;

