import { useState, useEffect, FunctionComponent } from 'react';
import styles from './ToTop.module.css';
import React from 'react';


const ToTop: FunctionComponent = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const handleScroll = () => {
            setShowButton(true);    // 스크롤 이벤트 발생 시 버튼 보이게 함

            // 스크롤 이벤트 발생 2초 후 버튼 숨김
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setShowButton(false);

            }, 2000);
        };

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
            // window.removeEventListener('wheel', handleWheel);
            clearTimeout(timeoutId); // 컴포넌트가 언마운트될 때 타임아웃을 클리어
        };
    }, []);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        });
    };
    return (
        // <img className={styles.groupChild7} alt="" src="Group 1379.svg" />
        <>
            {showButton && (
                <div>
                    <img className={styles.groupChild5} alt="" src="icons/toTop.png"
                        onClick={scrollToTop} />

                </div>
            )}
        </>
    );
};

export default ToTop;

