import { FunctionComponent } from 'react';
import styles from './Footer.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Main from './Main.tsx';
import VdigmNews from './VdigmNews.tsx';
import VdigmYoutubes from './VdigmYoutubes.tsx';

interface Logo {
    pngSrc: string,
    url: string,
}

const Footer: FunctionComponent = () => {
    console.log('Footer');
    const { t } = useTranslation();

    const logo: Logo[] = [
        {
            pngSrc: "icons/youtubeLogo.png",
            url: "https://www.youtube.com/@vdigm",
        },
        {
            pngSrc: "icons/insiteLogo.png",
            url: "https://www.linkedin.com/company/vdigm/",
        },
        {
            pngSrc: "icons/tihLogo.png",
            url: "https://tih.ai",
        },
    ]

    return (
        // <div className={styles.div}>
        //     <div className={styles.rectangleParent}>
        //         <div className={styles.groupChild} />

        //         <Main />

        //     </div>
        // </div>
        <div className={styles.footer}>
            <div className={styles.groupChild4} />

            {/* <div className={styles.div}> */}
            <div className={styles.rectangleParent}>
                {/* <div className={styles.groupChild} /> */}


                {/* <img className={styles.groupChild5} alt="" src="Group 1377.svg" /> */}

                <div className={styles.group}>
                    <div className={styles.div3}>{t(`home:footer:companyName`)}</div>
                    <b className={styles.b9}>{t(`home:footer:companyHead`)}{t(`home:footer:companyHeadAddress0`)}
                        {t(`home:footer:companyHeadAddress1`)}
                    </b>
                    <b className={styles.rdCenter}>{t(`home:footer:companyR&D`)}{t(`home:footer:companyR&DAddress0`)}
                        {t(`home:footer:companyR&DAddress1`)}
                    </b>
                    <b className={styles.tel82322771700}>{t(`home:footer:companyTel`)}</b>
                    <b className={styles.mailBizvdigmcom}>{t(`home:footer:companyMail`)}</b>
                </div>
                    <div className={styles.div4}>
                        <a href={logo[0].url} target="_blank">
                            <div className={styles.child} />
                            <img className={styles.instagram328841319204Icon} alt="" src={logo[0].pngSrc} />
                        </a>
                    </div>
                    <div className={styles.div5}>
                        <a href={logo[1].url} target="_blank">
                            <div className={styles.item} />
                            <img className={styles.instagram328841319203Icon} alt="" src={logo[1].pngSrc} />
                        </a>
                    </div>
                    <div className={styles.tihLogo}>
                        <a href={logo[2].url} target="_blank">
                            <div className={styles.tihLogoBackground} />
                            <img className={styles.tihLogoMask} alt="" src={logo[2].pngSrc} />
                        </a>
                    </div>
            </div>


        </div>
        // </div>

    );
};

export default Footer;