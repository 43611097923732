import { FunctionComponent, useEffect } from 'react';
import { Link, useSearchParams, Route, Routes, SetURLSearchParams } from 'react-router-dom';

import styles from './History.module.css';
import React from 'react';

import stylesEn from './HistoryEn.module.css';

import { useTranslation } from "react-i18next";

interface Props {
    introduces: {
        about: string,
        history: string,
        organization: string,
        vision: string,
    };
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
}

const History: FunctionComponent<Props> = ({ introduces, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
            }
        }
    }, [tab, introduces, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.aiContainer}>
                            <p className={styles.ai}>생성형 AI 아바타 서비스의</p>
                            <p className={styles.ai}>새로운 패러다임을 만들어 나갑니다</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.b5} onClick={() => handleCategoryClick('about')}>
                                {introduces.about}
                            </div>
                            <div className={styles.b} onClick={() => handleCategoryClick('organization')}>
                                {introduces.organization}
                            </div>
                            <div className={styles.div1} onClick={() => handleCategoryClick('history')}>
                                {introduces.history}
                            </div>
                            <div className={styles.b1} onClick={() => handleCategoryClick('vision')}>
                                {introduces.vision}
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>


                        <div className={styles.div6}>2024</div>
                        <div className={styles.div7}>2023</div>
                        <div className={styles.div8}>2022</div>
                        <div className={styles.div9}>2020</div>
                        <div className={styles.div10}>2021</div>
                        <b className={styles.tih1}>TIH 버추얼 스트리머 방송 데뷔 및 서비스 출시 예정</b>
                        <b className={styles.b6}>기업부설 연구소 설립</b>
                        <b className={styles.tips}>중소벤처기업부 기업창업지원 ‘TIPS(팁스)’ 선정</b>
                        <b className={styles.worldOf20148}>모바일 게임 ‘World of 20148 Tycoon’ 런칭</b>
                        <b className={styles.sba}>SBA 패션산업 융·복합 기술 사업화 지원사업 선정 : 메타버스 패션쇼 플랫폼 개발</b>
                        <b className={styles.nipaAi}>NIPA(정보통신산업진흥원) AI 바우처 지원사업 선정</b>
                        <b className={styles.koc}>(주)벤처스퀘어 & KOC파트너스 투자 유치</b>
                        <b className={styles.lgCnsAi}>LG CNS ‘AI 튜터링 서비스(Unity 3D)’ 개발 공급</b>
                        <b className={styles.nipa}>NIPA(정보통신산업진흥원) 인공지능 학습용 데이터 구축사업 선정</b>
                        <b className={styles.nipaAi1}>NIPA(정보통신산업진흥원) AI 바우처 지원사업 선정</b>
                        <b className={styles.aiAvatarTutor}>AI Avatar Tutor 솔루션 개발 완려</b>
                        <b className={styles.b7}>법인 설립</b>
                        <div className={styles.div11}>12</div>
                        <div className={styles.div12}>07</div>
                        <div className={styles.div13}>06</div>
                        <div className={styles.div14}>12</div>
                        <div className={styles.div15}>12</div>
                        <div className={styles.div16}>02</div>
                        <div className={styles.div17}>04</div>
                        <div className={styles.div18}>11</div>
                        <div className={styles.div19}>07</div>
                        <div className={styles.div20}>02</div>
                        <div className={styles.div21}>07</div>
                        <div className={styles.div22}>05</div>
                        <img className={styles.lineIcon} alt="" src="icons/historyLinePc.png" />
                        <img className={styles.groupChild1} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={styles.groupChild2} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={styles.groupChild3} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={styles.groupChild4} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={styles.groupChild5} alt="" src="icons/pc/company/historyDotLine.png" />
                        <div className={styles.groupChild6} />
                        <div className={styles.groupChild7} />
                        <div className={styles.groupChild8} />
                        <div className={styles.groupChild9} />
                        <div className={styles.groupChild10} />

                    </div>
                </div>
            ) : (

                <div className={stylesEn.history}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.shapingTheFuture}>Experiences with Generative AI</p>
                        </div>
                    </div>

                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
                                {t(`companyPc:category:companyAbout`)}
                            </div>
                            <div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
                                {t(`companyPc:category:organization`)}
                            </div>
                            <div className={stylesEn.history1} onClick={() => handleCategoryClick('history')}>
                                {t(`companyPc:category:history`)}
                            </div>
                            <div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
                                {t(`companyPc:category:vision`)}
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <div className={stylesEn.aboutUs}>About Us</div> */}
                        <div className={stylesEn.div2}>2024</div>
                        <div className={stylesEn.div3}>2023</div>
                        <div className={stylesEn.div4}>2022</div>
                        <div className={stylesEn.div5}>2020</div>
                        <div className={stylesEn.div6}>2021</div>
                        <div className={stylesEn.tihVirtualStreamers}>TIH Virtual Streamer’s Broadcast Debut and Service Launch Scheduled</div>
                        <div className={stylesEn.nipaAiVoucher}>NIPA AI Voucher Program Project Finalization - AI Avatar Solution Provision</div>
                        <div className={stylesEn.establishmentOfA}>Establishment of a Corporate Research Institute</div>
                        <div className={stylesEn.selectionForThe}>Selection for the ‘TIPS’ program by the Ministry of SMEs and Startups</div>
                        <div className={stylesEn.mobileGameWorld}>Mobile Game 'World of 2048 Tycoon' Launches</div>
                        <div className={stylesEn.selectionForSbaContainer}>
                            <p className={stylesEn.selectionForSba}>Selection for SBA Fashion Industry's Convergence Technology Commercialization Project:</p>
                            <p className={stylesEn.shapingTheFuture}>Metaverse Fashion Show Platform Development</p>
                        </div>
                        <div className={stylesEn.nipaAiVoucher1}>NIPA AI Voucher Program Project Finalization (*NIPA _ National IT Industry Promotion Agency)</div>
                        <div className={stylesEn.venturesquareIncAnd}>Venturesquare, Inc and KOC Partners secure investment</div>
                        <div className={stylesEn.lgCnsDevelops}>LG CNS Develops and Delivers AI Tutoring Service (Unity 3D)</div>
                        <div className={stylesEn.selectionOfData}>Selection of Data Construction Projects for AI Learning by NIPA</div>
                        <div className={stylesEn.nipaAiVoucher2}>NIPA AI Voucher Program Project Finalization (*NIPA _ National IT Industry Promotion Agency)</div>
                        <div className={stylesEn.completionOfAi}>Completion of AI Avatar Tutor Solution Development</div>
                        <div className={stylesEn.establishACompany}>Establish a company</div>
                        <div className={stylesEn.div7}>12</div>
                        <div className={stylesEn.div8}>05</div>
                        <div className={stylesEn.div9}>07</div>
                        <div className={stylesEn.div10}>06</div>
                        <div className={stylesEn.div11}>12</div>
                        <div className={stylesEn.div12}>12</div>
                        <div className={stylesEn.div13}>02</div>
                        <div className={stylesEn.div14}>04</div>
                        <div className={stylesEn.div15}>11</div>
                        <div className={stylesEn.div16}>07</div>
                        <div className={stylesEn.div17}>02</div>
                        <div className={stylesEn.div18}>07</div>
                        <div className={stylesEn.div19}>05</div>
                        <img className={stylesEn.lineIcon} alt="" src="icons/historyLinePc.png" />
                        <img className={stylesEn.groupChild1} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={stylesEn.groupChild2} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={stylesEn.groupChild3} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={stylesEn.groupChild4} alt="" src="icons/pc/company/historyDotLine.png" />
                        <img className={stylesEn.groupChild5} alt="" src="icons/pc/company/historyDotLine.png" />
                        <div className={stylesEn.groupChild6} />
                        <div className={stylesEn.groupChild7} />
                        <div className={stylesEn.groupChild8} />
                        <div className={stylesEn.groupChild9} />
                        <div className={stylesEn.groupChild10} />


                    </div>
                </div>
            )
            }
        </div >
    );
};

export default History;
