import { FunctionComponent, useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styles from './Recruit.module.css';
import IdealTalent from './IdealTalent.tsx';
import WelfareBenefit from './WelfareBenefit.tsx';
import { useTranslation } from "react-i18next";
import RecruitProcess from './RecruitProcess.tsx';

import React from 'react';


const Recruit:FunctionComponent = () => {
    const [recruits, setRecruits] = useState({
        idealTalent: "인재상",
        welfareBenefit: "복리후생",
        recruitProcess: "채용절차",
    });

    type RecruitsType = (typeof recruits)[keyof typeof recruits];

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [selectedCategory, setSelectedCategory] = useState<RecruitsType>(recruits.idealTalent);

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcess':
                    setSelectedCategory(recruits.recruitProcess);
                    break;
            }
        }
    }, [tab, recruits]);

  	return (
        <div>
        
        {selectedCategory === recruits.idealTalent && (
                <IdealTalent recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )} 
            {selectedCategory === recruits.welfareBenefit && (
                <WelfareBenefit recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === recruits.recruitProcess && (
                <RecruitProcess recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            
    </div>);
};

export default Recruit;
