import { FunctionComponent } from 'react';
import styles from './Login.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Axios from 'axios';
import { useDispatch } from 'react-redux';

const Login: FunctionComponent = () => {
    const [id, setId] = useState<string>("");
    const [pw, setPw] = useState<string>("");

    const onIdHandler = (event) => {
        setId(event.currentTarget.value)
    }
    const onPwHandler = (event) => {
        setPw(event.currentTarget.value)
    }
    const onSubmitHandler = (event) => {
        event.prventDefault();

        console.log('id', id);
        console.log('pw', pw);

        let body ={
            id: id,
            pw: pw
        }

        Axios.post('/api/users/login', body)
        .then(response => {

        })
    }

    

    return (
        <div className={styles.login1}>
            <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <div className={styles.div} />
                <div className={styles.div1} />

                {/* <div className={styles.adminId}>Admin ID</div> */}
                <input type="email" value={id} onChange={onIdHandler} className={styles.vdigmBoss} />

                <div className={styles.div2} />

                <div className={styles.adminPassword}>Admin Password</div>
                <input type="password" value={pw} onChange={onPwHandler} className={styles.adminPassword} />

                <div className={styles.login}>Login</div>
                <b className={styles.adminLogin}>Admin Login</b>
                <img className={styles.icon} alt="" src="icons/admin/vdigmLogoWidth.png" />
                <div className={styles.div3}>※ 로그인 정보를 잊으셨나요?</div>
                <div className={styles.div4}>관리자 혹은 직원의 경우, 회사 내 시스템 관리자에게 문의해주세요.</div>
                <b className={styles.b}>관리자 로그인 페이지입니다.</b>
            </div>
        </div>);
};

export default Login;

