import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './RecruitProcedure.module.css';
import stylesEn from './RecruitProcedureEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcedure: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

interface RecruitURL {
    pngSrc: string,
    url: string,
}

const recruitURL: RecruitURL[] = [
    {
        pngSrc: "icons/saraminLogo.png",
        url: "https://www.saramin.co.kr/zf_user/company-info/view?csn=SnIrQTdrREhqUHh5MkdCSVhidzl2UT09&popup_yn=y",
    },
    {
        pngSrc: "icons/jobkoreaLogo.png",
        url: "https://www.jobkorea.co.kr/Recruit/Co_Read/C/31624651",
    },
]

const RecuritProcedure: FunctionComponent<Props> = (props) => {

    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcedure':
                    setSelectedCategory(recruits.recruitProcedure);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    const copyToClipboard = async (text) => {
        try { 
            await navigator.clipboard.writeText(text);
            alert('클립보드에 복사되었습니다: ' + text);
        } catch (err) {
            console.error('클립보드 복사 실패:', err);
        }
    };

    const handleClick = () => {
        const mailToCopy = 'biz@vdigm.com';
        copyToClipboard(mailToCopy);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.div2} onClick={() => handleCategoryClick('idealTalent')}>
                            인재상
                        </div>
                        <div className={styles.div3} onClick={() => handleCategoryClick('welfareBenefit')}>
                            복리후생
                        </div>
                        <div className={styles.div4} onClick={() => handleCategoryClick('recruitProcedure')}>
                            채용절차
                        </div>

                        <div className={styles.div1}>
                            <p className={styles.p}>브이다임과</p>
                            <p className={styles.p1}>함께하는 여정이에요!</p>
                        </div>

                        <div className={styles.div5}>서류 접수는 여기서!</div>
                        <div className={styles.div6}>궁금한게 있다면?</div>
                        <div className={styles.div7}>
                            <p className={styles.bizvdigmcom}>채용 사이트를 통해 지원해 주시면 꼼꼼히 보고 연락드리겠습</p>
                            <p className={styles.p1}>니다. 지원해주셔서 감사합니다.</p>
                        </div>
                        <div className={styles.bizvdigmcomContainer}>
                            <p className={styles.bizvdigmcom}>

                                <span className={styles.bizvdigmcom1} onClick={handleClick}>biz@vdigm.com</span>
  
                                <span> 으로 문의 주시면 빠른 시일 내에 답변해</span>
                            </p>
                            <p className={styles.p1}>드리겠습니다.</p>
                        </div>
                        <div className={styles.div8}>채용절차</div>
                        <div className={styles.div9}>
                            <div className={styles.child} />
                            <div className={styles.item} />
                            <div className={styles.inner} />
                            <div className={styles.ellipseDiv} />
                            <img className={styles.vectorIcon} alt="" src="icons/recruitProcedure.png" />
                            <div className={styles.div10}>서류 접수</div>
                            <div className={styles.div11}>1차 면접</div>
                            <div className={styles.div12}>최종합격</div>
                            <div className={styles.div13}>2차 면접</div>
                            <div className={styles.div14}>처우협의</div>
                            <div className={styles.div15}>화상 면접</div>
                        </div>
                        <div className={styles.div16}>
                            <div className={styles.rectangleDiv} />
                            <a href={recruitURL[1].url} target="_blank">
                                <img className={styles.imageIcon} alt="" src={recruitURL[1].pngSrc} />
                            </a>
                        </div>
                        <div className={styles.div17}>
                            <div className={styles.rectangleDiv} />
                            <a href={recruitURL[0].url} target="_blank">
                                <img className={styles.typeLogoIcon} alt="" src={recruitURL[0].pngSrc} />
                            </a>
                        </div>
                        <img className={styles.icon2} alt="" src="assets/recruitProcedureBottom.png" />

                    </div>
                </div>
            ) : (

                <div className={stylesEn.recruitmentProcess}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.people} onClick={() => handleCategoryClick('idealTalent')}>
                            People
                        </div>
                        <div className={stylesEn.benefits} onClick={() => handleCategoryClick('welfareBenefit')}>
                            Benefits
                        </div>
                        <div className={stylesEn.recruitmentProcess1} onClick={() => handleCategoryClick('recruitProcedure')}>
                            Recruitment Process
                        </div>

                        <b className={stylesEn.thisIsTheContainer}>
                            <p className={stylesEn.thisIsThe}>{`This is the journey `}</p>
                            <p className={stylesEn.withVdigm}>with vdigm!</p>
                        </b>
                        <div className={stylesEn.thankYouForContainer}>
                            <p className={stylesEn.thankYouFor}>{`Thank you for your interest in our company. `}</p>
                            <p className={stylesEn.thankYouFor}>{`We will review all applications carefully and contact `}</p>
                            <p className={stylesEn.withVdigm}>candidates for further assessment.</p>
                        </div>
                        <div className={stylesEn.forAnyInquiriesContainer}>
                            <p className={stylesEn.thankYouFor}>{`For any inquiries, please do not hesitate to contact us `}</p>
                            <p className={stylesEn.thankYouFor}>
                                <b className={stylesEn.bizvdigmcom}>biz@vdigm.com</b>
                                <span>{`. We will endeavor to respond to your `}</span>
                            </p>
                            <p className={stylesEn.withVdigm}>questions promptly.</p>
                        </div>
                        <div className={stylesEn.recruitmentProcess2}>Recruitment Process</div>
                        <div className={stylesEn.submitYourResumes}>Submit your resumes here!</div>
                        <div className={stylesEn.feelFreeTo}>Feel free to ask if you have any questions.</div>
                        <div className={stylesEn.ellipseParent}>
                            <div className={stylesEn.groupItem} />
                            <div className={stylesEn.groupInner} />
                            <div className={stylesEn.ellipseDiv} />
                            <div className={stylesEn.groupChild1} />
                            {/* <img className={stylesEn.vectorIcon} alt="" src="Vector.svg" /> */}
                            <img className={stylesEn.vectorIcon} alt="" src="icons/recruitmentProcess.png" />

                            {/* <div className={stylesEn.submitAResume}>
                                <p className={stylesEn.withVdigm}>Submit </p>
                                <p className={stylesEn.withVdigm}>a resume</p>
                            </div>
                            <div className={stylesEn.onlineInterview}>
                                <p className={stylesEn.withVdigm}>Online</p>
                                <p className={stylesEn.withVdigm}>Interview</p>
                            </div>
                            <div className={stylesEn.finalPass}>Final Pass</div>
                            <div className={stylesEn.interview1}>Interview</div>
                            <div className={stylesEn.negotiation}>Negotiation</div> */}
                        </div>
                        {/* <div className={stylesEn.div}>
                            <div className={stylesEn.child} />
                            <img className={stylesEn.imageIcon} alt="" src="image.png" />
                        </div>
                        <div className={stylesEn.div1}>
                            <div className={stylesEn.child} />
                            <img className={stylesEn.typeLogoIcon} alt="" src="영문_type_logo.png" />
                        </div> */}

                        <div className={stylesEn.div}>
                            <div className={stylesEn.child} />
                            <a href={recruitURL[1].url} target="_blank">
                                <img className={stylesEn.imageIcon} alt="" src={recruitURL[1].pngSrc} />
                            </a>
                        </div>
                        <div className={stylesEn.div1}>
                            <div className={stylesEn.child} />
                            <a href={recruitURL[0].url} target="_blank">
                                <img className={stylesEn.typeLogoIcon} alt="" src={recruitURL[0].pngSrc} />
                            </a>
                        </div>
                        <img className={stylesEn.icon2} alt="" src="assets/recruitProcedureBottom.png" />

                    </div>
                </div>
            )}
        </div>
    );
};

export default RecuritProcedure;