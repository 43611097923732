import { FunctionComponent, useState } from 'react';
import styles from './Contact.module.css';
import stylesEn from './ContactEn.module.css';

import React from 'react';
import NaverMapHead from './NaverMapHead.tsx';
import NaverMapLab from './NaverMapLab.tsx';
import { useTranslation } from "react-i18next";


const Contact: FunctionComponent = () => {
    const [selectMapLab, setSelectMapLab] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.contact}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.businessContact}>Business Contact</div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />
                        <div className={styles.vdigmInc}>(주)브이다임 / Vdigm, Inc</div>
                        <b className={styles.tel}>Tel.</b>
                        <b className={styles.eMail}>E-mail</b>
                        <div className={styles.adress}>Address</div>
                        <b className={styles.rdCenter}>{`R&D Center`}</b>
                        <div className={styles.div}>찾아오시는 길</div>

                        <div onClick={() => setSelectMapLab(false)}>
                            {selectMapLab
                                ? <div className={styles.head}>본사</div>
                                : <div className={styles.headBold}>본사</div>}
                        </div>
                        <div onClick={() => setSelectMapLab(true)}>
                            {selectMapLab
                                ? <div className={styles.rdBold}>R&D Center</div>
                                : <div className={styles.rd}>R&D Center</div>}
                        </div>

                        <b className={styles.headOffice}>Head Office</b>
                        <div className={styles.div1}>+82-32-277-1700</div>
                        <div className={styles.bizvdigmcom}>biz@vdigm.com</div>
                        <b className={styles.b}>인천광역시 부평구 부평대로 293, 1012호</b>
                        <b className={styles.ai}>서울특별시 서초구 매헌로 8길 47, AI 양재허브 희경빌딩 C동  304호</b>
                        {/* <div className={styles.headOffice1}>Head Office</div>
        				<div className={styles.rdCenter1}>{`R&D Center`}</div> */}
                        <img className={styles.icon1} alt="" src="icons/pc/contact/vdigmLogoWidthBig.png" />
                        {/* <div className={styles.groupInner} /> */}

                        <div className={styles.map}>
                            {selectMapLab ? <NaverMapLab /> : <NaverMapHead />}
                        </div>

                    </div>
                </div>
            ) : (
                <div className={stylesEn.contactHeadOffice}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.businessContact}>Business Contact</div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        <div className={stylesEn.vdigmInc}>Vdigm, Inc. / (주)브이다임</div>
                        {/* <div className={stylesEn.vdigmInc2}>(주)브이다임</div> */}
                        <b className={stylesEn.tel}>Tel.</b>
                        <b className={stylesEn.eMail}>E-mail</b>
                        <div className={stylesEn.adress}>Address</div>
                        <b className={stylesEn.rdCenter}>{`R&D Center`}</b>
                        <div className={stylesEn.location}>Location</div>
                        <div onClick={() => setSelectMapLab(false)}>
                            {selectMapLab
                                ? <div className={stylesEn.head}>Head Office</div>
                                : <div className={stylesEn.headBold}>Head Office</div>}
                        </div>
                        <div onClick={() => setSelectMapLab(true)}>
                            {selectMapLab
                                ? <div className={stylesEn.rdBold}>R&D Center</div>
                                : <div className={stylesEn.rd}>R&D Center</div>}
                        </div>
                        <b className={stylesEn.headOffice}>Head Office</b>
                        <div className={stylesEn.div}>+82-32-277-1700</div>
                        <div className={stylesEn.bizvdigmcom}>biz@vdigm.com</div>
                        <div className={stylesEn.f1012293}>10F 1012, 293, Bupyeong-daero, Bupyeong-gu, Incheon, Republic of Korea</div>
                        <div className={stylesEn.c304SeoulAiContainer}>
                            <p className={stylesEn.c304SeoulAi}>{`C-304, Seoul AI Hub, 47, Maeheon-ro 8-gil, Seocho-gu, Seoul, `}</p>
                            <p className={stylesEn.republicOfKorea}>Republic of Korea</p>
                        </div>
                        {/* <div className={stylesEn.headOffice1}>Head Office</div>
                        <div className={stylesEn.rdCenter1}>{`R&D Center`}</div> */}
                        <img className={stylesEn.icon1} alt="" src="icons/pc/contact/vdigmLogoWidthBig.png" />
                        {/* <div className={stylesEn.groupInner} /> */}
                        <div className={stylesEn.map}>
                            {selectMapLab ? <NaverMapLab /> : <NaverMapHead />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contact;
