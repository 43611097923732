import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './Vision.module.css';
import stylesEn from './VisionEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    introduces: {
        about: string,
        history: string,
        organization: string,
        vision: string,
    };
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
}

const Vision: FunctionComponent<Props> = (props) => {
    const { introduces, setSelectedCategory, tab, setSearchParams } = props;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
            }
        }
    }, [tab, introduces, setSelectedCategory]);

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.aiContainer}>
                            <p className={styles.ai}>생성형 AI 아바타 서비스의</p>
                            <p className={styles.ai}>새로운 패러다임을 만들어 나갑니다</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.b5} onClick={() => handleCategoryClick('about')}>
                                {t(`company:category:companyAbout`)}
                            </div>
                            <div className={styles.b1} onClick={() => handleCategoryClick('organization')}>
                                {t(`company:category:organization`)}
                            </div>
                            <div className={styles.b} onClick={() => handleCategoryClick('history')}>
                                {t(`company:category:history`)}
                            </div>
                            <div className={styles.div1} onClick={() => handleCategoryClick('vision')}>
                                {t(`company:category:vision`)}
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>


                        {/* <b className={styles.b5}>회사소개</b> */}
                        <div className={styles.groupChild1} />
                        <div className={styles.groupChild2} />
                        <b className={styles.b6}>신기술 개발</b>
                        <div className={styles.groupChild3} />
                        <div className={styles.groupChild4} />
                        <b className={styles.b7}>
                            <p className={styles.p1}>지속 가능한</p>
                            <p className={styles.ai}>서비스</p>
                        </b>
                        <div className={styles.groupChild5} />
                        <div className={styles.groupChild6} />
                        <b className={styles.b8}>
                            <p className={styles.p1}>글로벌</p>
                            <p className={styles.ai}>경쟁력 확보</p>
                        </b>
                        <img className={styles.lineIcon} alt="" src="icons/visionArrowPcR.png" />
                        <img className={styles.groupChild7} alt="" src="icons/visionArrowPcL.png" />
                        <img className={styles.groupChild8} alt="" src="icons/visionArrowPcM.png" />
                        <div className={styles.groupChild9} />
                        <div className={styles.groupChild10} />
                        <div className={styles.vision}>Vision</div>

                    </div>
                </div>
            ) : (
                <div className={stylesEn.vision}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.shapingTheFuture} style={{ color: '#222' }}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.shapingTheFuture} style={{ color: '#222' }}>Experiences with Generative AI</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        <div className={stylesEn.category}>
                            <div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
                                {t(`company:category:companyAbout`)}
                            </div>
                            <div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
                                {t(`company:category:organization`)}
                            </div>
                            <div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
                                {t(`company:category:history`)}
                            </div>
                            <div className={stylesEn.vision1} onClick={() => handleCategoryClick('vision')}>
                                {t(`company:category:vision`)}
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <div className={styles.history}>History</div>
                        <div className={styles.organizationChart}>Organization Chart</div>
                        <b className={styles.vision1}>Vision</b> */}
                        <div className={stylesEn.groupChild1} />
                        <div className={stylesEn.groupChild2} />
                        <div className={stylesEn.newTechnologyDevelopmentContainer}>
                            <p className={stylesEn.new}>New</p>
                            <p className={stylesEn.new}>Technology</p>
                            <p className={stylesEn.shapingTheFuture}>Development</p>
                        </div>
                        <div className={stylesEn.groupChild3} />
                        <div className={stylesEn.groupChild4} />
                        <div className={stylesEn.groupChild5} />
                        <div className={stylesEn.groupChild6} />
                        <img className={stylesEn.lineIcon} alt="" src="icons/visionArrowPcR.png" />
                        <img className={stylesEn.groupChild7} alt="" src="icons/visionArrowPcL.png" />
                        <img className={stylesEn.groupChild8} alt="" src="icons/visionArrowPcM.png" />
                        <div className={stylesEn.groupChild9} />
                        <div className={stylesEn.groupChild10} />
                        <div className={stylesEn.vision2}>Vision</div>
                        <div className={stylesEn.sustainableService}>
                            <p className={stylesEn.new}>Sustainable</p>
                            <p className={stylesEn.shapingTheFuture}>Service</p>
                        </div>
                        <div className={stylesEn.securingGlobalCompetitvenessContainer}>
                            <p className={stylesEn.securing}>Securing</p>
                            <p className={stylesEn.securing}>Global</p>
                            <p className={stylesEn.shapingTheFuture}>Competitveness</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Vision;
