import { FunctionComponent, useState, useRef, useEffect } from 'react';
import { Link, useSearchParams, Route, Routes, SetURLSearchParams } from 'react-router-dom';
import styles from './History.module.css';
import * as Company from './Company.tsx';
import About from './About.tsx'
import Organization from './Organization.tsx'
import Vision from './Vision.tsx'
import React from 'react';

import stylesEn from './HistoryEn.module.css';

import { useTranslation } from "react-i18next";

interface Props {
    introduces: {
        about: string,
        history: string,
        organization: string,
        vision: string,
    };
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
}

const History: FunctionComponent<Props> = ({ introduces, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
                default:
                    setSelectedCategory(introduces.about);
            }
        }
    }, [tab, introduces, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };




    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        {/* <Link to="/company?tab=about"> */}
                        <div className={styles.div19} onClick={() => handleCategoryClick('about')}>
                            {introduces.about}
                        </div>
                        <div className={styles.div20} onClick={() => handleCategoryClick('organization')}>
                            {introduces.organization}
                        </div>
                        <div className={styles.div21} onClick={() => handleCategoryClick('history')}>
                            {introduces.history}
                        </div>
                        <div className={styles.div22} onClick={() => handleCategoryClick('vision')}>
                            {introduces.vision}
                        </div>

                        <div className={styles.div1}>2024</div>
                        <div className={styles.div2}>2023</div>
                        <div className={styles.div3}>2021</div>
                        <div className={styles.div4}>2020</div>
                        <div className={styles.div5}>2022</div>
                        <div className={styles.div6}>07</div>
                        <div className={styles.div7}>02</div>
                        <div className={styles.div8}>12</div>
                        <div className={styles.div9}>05</div>
                        <div className={styles.div10}>06</div>
                        <div className={styles.div11}>12</div>
                        <div className={styles.div12}>12</div>
                        <div className={styles.div13}>11</div>
                        <div className={styles.div14}>07</div>
                        <div className={styles.div15}>05</div>
                        <div className={styles.div16}>07</div>
                        <div className={styles.div17}>04</div>
                        <div className={styles.div18}>02</div>
                        <b className={styles.b}>기업부설 연구소 설립</b>
                        <b className={styles.nipaAi}>NIPA(정보통신산업진흥원) AI 바우처 지원사업 선정</b>
                        <b className={styles.sbaContainer}>
                            <p className={styles.sba}>SBA 패션산업 융·복합 기술 사업화 지원사업 선정</p>
                            <p className={styles.p}>메타버스 패션쇼 플랫폼 개발</p>
                        </b>
                        <b className={styles.worldOf2048}>모바일 게임 ‘World of 2048 Tycoon’ 런칭</b>
                        <b className={styles.lgCnsAi}>LG CNS ‘AI 튜터링 서비스(Unity 3D)’ 개발 공급</b>
                        <b className={styles.aiAvatarTutor}>AI Avatar Tutor 솔루션 개발 완료</b>
                        <b className={styles.b1}>법인 설립</b>
                        <b className={styles.nipaContainer}>
                            <p className={styles.sba}>NIPA(정보통신산업진흥원) 인공지능 학습용 데이터</p>
                            <p className={styles.p}>구축사업 선정</p>
                        </b>
                        <b className={styles.tih}>TIH 버추얼 스트리머 방송 데뷔 및 서비스 출시 예정</b>
                        <b className={styles.nipaAiContainer}>
                            <p className={styles.sba}>NIPA(정보통신산업진흥원) AI 바우처 지원사업 선정</p>
                            <p className={styles.p}>-AI Avatar 솔루션 공급</p>
                        </b>
                        <b className={styles.tips}>중소벤처기업부 기술창업지원 ‘TIPS(팁스)’ 선정</b>
                        <b className={styles.koc}>{`(주)벤처스퀘어 & KOC파트너스 투자 유치`}</b>
                        <b className={styles.nipaAi2}>NIPA(정보통신산업진흥원) AI 바우처 지원사업 선정</b>
                        <img className={styles.groupItem} alt="" src="icons/historyLine.png" />

                        <div className={styles.aiContainer}>
                            <p className={styles.sba}>생성형 AI 아바타 서비스의</p>
                            <p className={styles.p}>새로운 패러다임을 만들어 나갑니다.</p>
                        </div>
                        <div className={styles.div23} />
                        <div className={styles.div24} />
                        <div className={styles.div25} />
                        <div className={styles.div26} />
                        <div className={styles.div27} />
                    </div>
                </div>
            ) : (

                <div className={stylesEn.history}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        <div className={stylesEn.article}>
                        <div className={stylesEn.aboutUs} onClick={() => handleCategoryClick('about')}>
                            {t(`company:category:companyAbout`)}
                        </div>
                        <div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
                            {t(`company:category:organization`)}
                        </div>
                        <div className={stylesEn.history1} onClick={() => handleCategoryClick('history')}>
                            {t(`company:category:history`)}
                        </div>
                        <div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
                            {t(`company:category:vision`)}
                        </div>
                        <b className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.experiencesWithGenerative}>Experiences with Generative AI</p>
                        </b>
                        <div className={stylesEn.div}>02</div>
                        <div className={stylesEn.div1}>04</div>
                        <div className={stylesEn.div2}>02</div>
                        <div className={stylesEn.div3}>07</div>
                        <div className={stylesEn.div4}>06</div>
                        <div className={stylesEn.div5}>12</div>
                        <div className={stylesEn.div6}>12</div>
                        <div className={stylesEn.div7}>11</div>
                        <div className={stylesEn.div8}>07</div>
                        <div className={stylesEn.div9}>05</div>
                        <div className={stylesEn.div10}>07</div>
                        <div className={stylesEn.nipaAiVoucherContainer}>
                            <p className={stylesEn.nipaAiVoucher}>NIPA AI Voucher Program Project Finalization</p>
                            <p className={stylesEn.experiencesWithGenerative}>*NIPA(National IT Industry Promotion Agency)</p>
                        </div>
                        <div className={stylesEn.venturesquareIncAndContainer}>
                            <p className={stylesEn.nipaAiVoucher}>Venturesquare, Inc and KOC Partners secure</p>
                            <p className={stylesEn.experiencesWithGenerative}>investment</p>
                        </div>
                        <div className={stylesEn.nipaAiVoucherContainer1}>
                            <p className={stylesEn.nipaAiVoucher}>NIPA AI Voucher Program Project Finalization</p>
                            <p className={stylesEn.experiencesWithGenerative}>*NIPA(National IT Industry Promotion Agency)</p>
                        </div>
                        <div className={stylesEn.establishmentOfA}>Establishment of a Corporate Research Institute</div>
                        <div className={stylesEn.selectionForTheContainer}>
                            <p className={stylesEn.nipaAiVoucher}>{`Selection for the ‘TIPS’ program by the Ministry `}</p>
                            <p className={stylesEn.experiencesWithGenerative}>of SMEs and Startups</p>
                        </div>
                        <div className={stylesEn.selectionForSbaContainer}>
                            <p className={stylesEn.selectionForSba}>Selection for SBA Fashion Industry's Convergence</p>
                            <p className={stylesEn.nipaAiVoucher}>{`Technology Commercialization Project: `}</p>
                            <p className={stylesEn.metaverseFashionShow}>Metaverse Fashion Show Platform Development</p>
                        </div>
                        <div className={stylesEn.mobileGameWorld}>Mobile Game 'World of 2048 Tycoon' Launches</div>
                        <div className={stylesEn.lgCnsDevelopsContainer}>
                            <p className={stylesEn.nipaAiVoucher}>{`LG CNS Develops and Delivers AI Tutoring `}</p>
                            <p className={stylesEn.experiencesWithGenerative}>Service (Unity 3D)</p>
                        </div>
                        <div className={stylesEn.completionOfAiContainer}>
                            <p className={stylesEn.nipaAiVoucher}>{`Completion of AI Avatar Tutor Solution `}</p>
                            <p className={stylesEn.experiencesWithGenerative}>Development</p>
                        </div>
                        <div className={stylesEn.establishACompany}>Establish a company</div>
                        <div className={stylesEn.selectionOfDataContainer}>
                            <p className={stylesEn.nipaAiVoucher}>{`Selection of Data Construction Projects for `}</p>
                            <p className={stylesEn.experiencesWithGenerative}>AI Learning by NIPA</p>
                        </div>




                        <div className={stylesEn.div11}>2024</div>
                        <div className={stylesEn.div12}>2023</div>
                        <div className={stylesEn.div13}>2021</div>
                        <div className={stylesEn.div14}>2020</div>
                        <div className={stylesEn.div15}>2022</div>
                        <div className={stylesEn.div16}>12</div>
                        <div className={stylesEn.div17}>05</div>
                        <div className={stylesEn.tihVirtualStreamersContainer}>
                            <p className={stylesEn.nipaAiVoucher}>{`TIH Virtual Streamer's Broadcast Debut and `}</p>
                            <p className={stylesEn.experiencesWithGenerative}>Service Launch Scheduled</p>
                        </div>
                        <div className={stylesEn.nipaAiVoucherContainer2}>
                            <p className={stylesEn.nipaAiVoucher}>NIPA AI Voucher Program Project Finalization</p>
                            <p className={stylesEn.experiencesWithGenerative}>-AI Avatar Solution Provision</p>
                        </div>
                        </div>
                        <img className={stylesEn.lineIcon} alt="" src="icons/historyLineEn.png" />

                        <div className={stylesEn.div18} />
                        <div className={stylesEn.div19} />
                        <div className={stylesEn.div20} />
                        <div className={stylesEn.div21} />
                        <div className={stylesEn.div22} />
                    </div>
                </div>
            )}
        </div>
    );
};


export default History;