import { FunctionComponent, useState, useEffect } from 'react';
import { Link, useSearchParams, Route, Routes } from 'react-router-dom';
import About from './About.tsx';
import History from './History.tsx';
import Organization from './Organization.tsx';
import Vision from './Vision.tsx';
import { useTranslation } from "react-i18next";

import React from 'react';

// interface Test {
//     title: string;
//     pngSrc: string;
//     description: string;
// }

// interface Test2 {

// }

// interface HistoryList {
//     year: number;
//     month: number;
//     description: string;
// }

// let testArr: string[][] | (Test | Test2 | HistoryList)[][] = [
//     [
//         {
//             title: "브이다임 BI",
//             pngSrc: ".png",
//             description: "~",
//         },
//         {
//             title: "SLOGAN",
//             pngSrc: "",
//             description: "~",
//         }
//     ]
// ]

interface Test<T> {
    title: string[][];
    data: T[];
}



const testArr: Test<(string | number)[]> = {
    title: [
        ["회사소개", "생성형 AI ~"],
        ["연혁", "생성형 AI ~"],
        ["회사소개", "생성형 AI ~"],
        ["연혁", "생성형 AI ~"],
    ],
    data: [
        [
            "브이다임",
            "정육면체는 ~",
            ".png",
            ".png",
        ],
        [
            "SLOGAN",
            ""
        ]
    ]
}



const Company: FunctionComponent = () => {
    console.log('Company');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [introduces, setIntroduces] = useState({
        about: "회사소개",
        history: "연혁",
        organization: "조직도",
        vision: "비전",
    });

    type IntroduceType = (typeof introduces)[keyof typeof introduces];

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [selectedCategory, setSelectedCategory] = useState<IntroduceType>(introduces.about);
    // const currentLanguage = i18n.language;
    // const currentLanguage = i18n.language;
    // const currentLanguage = i18n.language;

    // const handleCategoryChange = (event) => {
    //     setSearchParams({ category: event.target.value });
    // }

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'aiAvatar':
                    setSelectedCategory(introduces.about);
                    break;
                case 'deepReal':
                    setSelectedCategory(introduces.history);
                    break;
                case '3dVirtualStage':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
            }
        }
    }, [tab, introduces]);

    return (
        <div>
            {/* <nav>
                <div onClick={() => setSearchParams({ tab: 'about' })}>{introduces.about}</div>
                <div onClick={() => setSearchParams({ tab: 'history' })}>{introduces.history}</div>
                <div onClick={() => setSearchParams({ tab: 'organization' })}>{introduces.organization}</div>
                <div onClick={() => setSearchParams({ tab: 'vision' })}>{introduces.vision}</div>
            </nav> */}

            {selectedCategory === introduces.about && (
                <About introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )} 
            {selectedCategory === introduces.history && (
                <History introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === introduces.organization && (
                <Organization introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === introduces.vision && (
                <Vision introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
        </div>
    );
};

//     switch (selectedCategory) {
//         case introduces.about:
//             // return <Link to="/company?tab=history">
//             //         {/* <Route path="/company?tab=about" element={<About introduces={introduces} setSelectedCategory={setSelectedCategory}
//             //         tab={tab} setSearchParams={setSearchParams}/>} />
//             //         </Routes> */}
//             //     <About introduces={introduces} setSelectedCategory={setSelectedCategory}
//             //         tab={tab} setSearchParams={setSearchParams} />

//             //         {/* <select value={tab} onChange={setSearchParams({tab: "about"})}>

//             //         </select> */}
//             //             </Link>;
//             return <About introduces={introduces} setSelectedCategory={setSelectedCategory}
//                 tab={tab} setSearchParams={setSearchParams} />
//         // setSearchParams({ tab: 'about' })


//         case introduces.history:
//             // return <Link to="/company?tab=history">
//             //     <History introduces={introduces} setSelectedCategory={setSelectedCategory}
//             //         tab={tab} setSearchParams={setSearchParams} />
//             //         {/* <select value={tab} onChange={setSearchParams({tab: "history"})}>
//             //         </select> */}
//             // </Link>
//             return <History introduces={introduces} setSelectedCategory={setSelectedCategory}
//                 tab={tab} setSearchParams={setSearchParams} />
//         //setSearchParams({ tab: 'history' });
//         //         {/* <select value={tab} onChange={setSearchParams({tab: "history"})}>
//         //         </select> */}

//         case introduces.organization:
//             // return <Link to="/company?tab=organization">
//             //     <Organization introduces={introduces} setSelectedCategory={setSelectedCategory}
//             //         tab={tab} setSearchParams={setSearchParams} />
//             // </Link>
//             return <Organization introduces={introduces} setSelectedCategory={setSelectedCategory}
//                 tab={tab} setSearchParams={setSearchParams} />
//         //  setSearchParams({ tab: 'organization' });


//         case introduces.vision:
//             // return <Link to="/company?tab=vision">
//             //     <Vision introduces={introduces} setSelectedCategory={setSelectedCategory}
//             //         tab={tab} setSearchParams={setSearchParams} />
//             // </Link>
//             return <Vision introduces={introduces} setSelectedCategory={setSelectedCategory}
//                 tab={tab} setSearchParams={setSearchParams} />
//         // setSearchParams({ tab: 'vision' });

//         default:
//             // setSearchParams({});
//             break;

//         // default :
//         //     return <About introduces={introduces} setSelectedCategory={setSelectedCategory}
//         //     tab={tab} setSearchParams={setSearchParams}/>;
//     }

//     // return (
//     //     <div>
//     //         <About selectedCategory={selectedCategory} />

//     //         <Routes>
//     //             <Route path="/company/history" element={<History />} />
//     //         </Routes>


//     //         <div className={styles.div}>
//     //             <div className={styles.rectangleParent}>
//     //                 <div className={styles.groupChild} />
//     //                 <Link to="/">
//     //                     <img className={styles.icon} alt="" src="icons/vdigmlogowidth.png" />
//     //                 </Link>



//     //                 <img className={styles.icon1} alt="" src="메뉴.svg" />
//     //                     <div className={styles.div1} onClick={() => setSearchParams({tab: "about"})}>{introduces.about}</div>
//     //                 <Link to="company?tab=organization">
//     //                     <div className={styles.div2}>{introduces.organization}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=history">
//     //                     <div className={styles.div3}>{introduces.history}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=vision">
//     //                     <div className={styles.div4}>{introduces.vision}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=about" className={selectedCategory === `${introduces.about}` ? styles.active : ''}>
//     //                     <div className={styles.div1}>{introduces.about}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=organization" className={selectedCategory === `${introduces.organization}` ? styles.active : ''}>
//     //                     <div className={styles.div2}>{introduces.organization}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=history" className={selectedCategory === `${introduces.history}` ? styles.active : ''}>
//     //                     <div className={styles.div3}>{introduces.history}</div>
//     //                 </Link>
//     //                 <Link to="company?tab=vision" className={selectedCategory === `${introduces.vision}` ? styles.active : ''}>
//     //                     <div className={styles.div4}>{introduces.vision}</div>
//     //                 </Link>
//     //                 <b className={styles.bi}>브이다임 BI</b>
//     //                 <div className={styles.slogan}>SLOGAN</div>
//     //                 <div className={styles.aiContainer}>
//     //                     <p className={styles.ai}>생성형 AI 아바타 서비스의</p>
//     //                     <p className={styles.p}>새로운 패러다임을 만들어 나갑니다.</p>
//     //                 </div>
//     //                 <div className={styles.visualAiVirtualContainer}>
//     //                     <p className={styles.visualAiVirtual}>정육면체는 서로 다른 두 개의 가상 세계라는 의미를 담고,</p>
//     //                     <p className={styles.visualAiVirtual}>{`가운데 역삼각형은 Visual AI &Virtual World의 V를 의미합`}</p>
//     //                     <p className={styles.visualAiVirtual}>니다. 또한 잘린 단면은 두 가상 세계의 충돌로 일어난 새로운</p>
//     //                     <p className={styles.p}>Paradigm Shift, 새로운 패러다임으로의 전환을 상징합니다.</p>
//     //                 </div>
//     //                 <img className={styles.biIcon} alt="" src="BI 눈금.svg" />
//     //                 <img className={styles.icon2} alt="" src="슬로건 눈금.svg" />
//     //                 <img className={styles.biIcon1} alt="" src="icons/vdigmlogowidth.png" />
//     //                 <img className={styles.biIcon2} alt="" src="icons/vdigmlogoheight.png" />
//     //                 <div className={styles.div5}>(주)브이다임</div>
//     //                 <b className={styles.b}>본사. 인천광역시 부평구 부평대로 293, 1012호</b>
//     //                 <b className={styles.rdCenter}>{`R&D Center. 서울 서초구 매헌로 8길 47, AI 양재허브`}</b>
//     //                 <b className={styles.c304}>희경빌딩 C동 304호</b>
//     //                 <div className={styles.tel82322771700}>Tel. +82-32-277-1700</div>
//     //                 <div className={styles.mailBizvdigmcom}>Mail. biz@vdigm.com</div>
//     //                 <img className={styles.icon3} alt="" src="하단라인.svg" />
//     //                 <img className={styles.icon4} alt="" src="상단으로_아이콘.svg" />
//     //                 <div className={styles.div6}>
//     //                     <div className={styles.child} />
//     //                     <img className={styles.instagram328841319204Icon} alt="" src="instagram-3288413_1920 4.png" />
//     //                 </div>
//     //                 <div className={styles.div7}>
//     //                     <div className={styles.item} />
//     //                     <img className={styles.instagram328841319203Icon} alt="" src="instagram-3288413_1920 3.png" />
//     //                 </div>
//     //                 <div className={styles.div8}>
//     //                     <div className={styles.inner} />
//     //                     <div className={styles.div9}>버추얼 스트리머 에너지 스테이션</div>
//     //                 </div>
//     //                 <img className={styles.icon5} alt="" src="스크롤바.svg" />
//     //                 <img className={styles.icon6} alt="" src="스크롤.svg" />
//     //             </div>
//     //         </div>

//     //     </div>
//     // );
// };

export default Company;

// const About = () => {


//     return (

//     );
// };

// export default About;

