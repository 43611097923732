import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './DeepReal.module.css';
import stylesEn from './DeepRealEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    solutions: {
        aiAvatar: string;
        deepReal: string;
        dVirtualStage: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const DeepReal: FunctionComponent<Props> = ({ solutions, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'aiAvatar':
                    setSelectedCategory(solutions.aiAvatar);
                    break;
                case 'deepReal':
                    setSelectedCategory(solutions.deepReal);
                    break;
                case 'dVirtualStage':
                    setSelectedCategory(solutions.dVirtualStage);
                    break;
            }
        }
    }, [tab, solutions, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(solutions[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.deepReal}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                            {solutions.aiAvatar}
                        </div>
                        <div className={styles.deepReal1} onClick={() => handleCategoryClick('deepReal')}>
                            {solutions.deepReal}
                        </div>
                        <div className={styles.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                            {solutions.dVirtualStage}
                        </div>

                        <div className={styles.div}>
                            <p className={styles.p}>3D 라이브 스트리밍을 이끌어 갈</p>
                            <p className={styles.ai}>브이다임만의 기술</p>
                        </div>
                        <div className={styles.deepRealSolution}>Deep Real Solution</div>
                        <div className={styles.dContainer}>
                            <p className={styles.d}>3D 환경에서 제작한 숏폼/AR 영상 콘텐츠를 더욱 실감나게</p>
                            <p className={styles.d}>표현하기 위한 솔루션으로 이미지에서 조화롭지 않은 부분을</p>
                            <p className={styles.ai}>파악 후 보정하는 브이다임만의 AI 기술</p>
                        </div>

                        <img className={styles.deeprealImageIcon} alt="" src="assets/deepReal.png" />
                    </div>
                </div>
            ) : (

                <div className={stylesEn.deepReal}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                            AI Avatar
                        </div>
                        <div className={stylesEn.deepReal1} onClick={() => handleCategoryClick('deepReal')}>
                            Deep Real
                        </div>
                        <div className={stylesEn.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                            3D Virtual Stage
                        </div>

                        <b className={stylesEn.vdigmsTechnologySpearheadinContainer}>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>{`Vdigm's Technology Spearheading `}</p>
                            <p className={stylesEn.dLiveStreaming}>3D Live Streaming</p>
                        </b>
                        <div className={stylesEn.generativeAiAvatar}>Deep Real Solution</div>
                        <div className={stylesEn.vdigmsAiTechnologyContainer}>
                            <p className={stylesEn.vdigmsAiTechnology}>{`Vdigm's AI Technology: Enhancing the Realism of `}</p>
                            <p className={stylesEn.vdigmsAiTechnology}>{`Short-Form and AR Video Content by Identifying and `}</p>
                            <p className={stylesEn.dLiveStreaming}>Correcting Inconsistencies in 3D Environments edit.</p>
                        </div>
                        <img className={stylesEn.deeprealImageIcon} alt="" src="assets/deepReal.png" />

                    </div>
                </div>
            )}
        </div>
    );
};

export default DeepReal;