import { useState, useEffect, FunctionComponent } from "react";
import styles from './ToTop.module.css';

import React from 'react';

const ToTop: FunctionComponent = () => {
    const [showButton, setShowButton] = useState(false);
    // const [showScrollbar, setShowScrollbar] = useState(false);
    // document.body.style.overflowY = 'auto';
    // window.addEventListener('scroll', function() {
    //     var element = document.body; // 혹은 스크롤바를 보여주고 싶은 다른 요소 선택
    //     if (showButton) {
    //         element.classList.add('body');
    //     } else {
    //         element.classList.remove('body');
    //     }
    // });
    
    
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        // const handleWheel = (event: WheelEvent) => {
        //     const container = document.getElementById('scroll-container');
        //     if (!container) return;
        //     if (event.deltaY !== 0) {
        //         container.style.overflowY = 'scroll';
        //       } else {
        //         container.style.overflowY = 'hidden';
        //       }
        //     };
        
            // 마운트될 때 이벤트 리스너를 추가합니다.
            // window.addEventListener('wheel', handleWheel);
            // const container = document.getElementById('scroll-container');
            // if (!container) return;
        const handleScroll = () => {
            setShowButton(true);    // 스크롤 이벤트 발생 시 버튼 보이게 함
            // const isScrollbarVisible = document.documentElement.scrollHeight > window.innerHeight;
            // setShowScrollbar(isScrollbarVisible);
            // const container = document.getElementById('scroll-container');
            // if (!container) return;
            // container.style.overflowY = 'scroll';
            // container.style.display = 'none';
            // document.body.style.display = 'auto';
            // const scrollBar = document.querySelector('body::-webkit-scrollbar');
            // scrollBar. = styles.body.display = 'block';

            // 스크롤 이벤트 발생 2초 후 버튼 숨김
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                // container.style.overflowY = 'hidden';
                // container.style.display = 'visible';
                setShowButton(false);
                // document.body.style.overflow = 'hidden';
                // document.body.style.display = 'auto';
                // document.body.style.display = 'none';

            }, 2000);
        };

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
            // window.removeEventListener('wheel', handleWheel);
            clearTimeout(timeoutId); // 컴포넌트가 언마운트될 때 타임아웃을 클리어
        };
    }, []);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            {showButton && (
                <div>
                    <img className={styles.icon6} alt="" src="icons/toTop.png"
                        onClick={scrollToTop} />

                </div>
            )}

{/* <div className={showButton ? `${styles['with-scroll']}` : `${styles.body}`} /> */}
{/* <div style={{ overflow: showButton ? 'auto' : 'hidden' }} /> */}
        </>
    );
};

export default ToTop;
