import { FunctionComponent } from 'react';
import styles from './Home.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';

import Main from './Main.tsx';
import VdigmNews from './VdigmNews.tsx';
import VdigmYoutubes from './VdigmYoutubes.tsx';


const Home: FunctionComponent = () => {
    return (
        // <div className={styles.div}>
        //     <div className={styles.rectangleParent}>
        //         <div className={styles.groupChild} />

        //         <Main />

        //     </div>
        // </div>
        <div className={styles.div}>
            <div className={styles.rectangleParent}>
                {/* <div className={styles.groupChild} /> */}
                
                
                {/* <div className={styles.div2}>브이다임 유튜브</div>
                <b className={styles.b5}>유튜브 방문하기</b>
                <div className={styles.groupItem} />
                <div className={styles.groupInner} />
                <div className={styles.rectangleDiv} /> */}
                {/* <img className={styles.groupIcon} alt="" src="Group 1355.svg" />
                <img className={styles.icon} alt="" src="가로형.png" /> */}
                <Main />
                <VdigmNews />
                <VdigmYoutubes />
                {/* <img className={styles.groupChild1} alt="" src="Group 1373.png" /> */}
                {/* <div className={styles.ellipseDiv} />
                <div className={styles.groupChild2} />
                <div className={styles.groupChild3} /> */}
                {/* <div className={styles.aiContainer1}>
                    <p className={styles.ai2}>생성형 AI 아바타 서비스의</p>
                    <p className={styles.p}>새로운 패러다임</p>
                </div> */}
                
            </div>
        </div>

    );
};

export default Home;