import { FunctionComponent } from 'react';
import styles from './Tih.module.css';
import stylesEn from './TihEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react'


const Tih: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.tih}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.div}>
                            <p className={styles.p}>버추얼 스트리머와 팬덤을 위한 서비스</p>
                            <p className={styles.p}>내일, 여기서 만나</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />
                        <b className={styles.tihtomorrowInHere}>TIH(Tomorrow, In Here)</b>


                        <div className={styles.ellipseDiv} />
                        <img className={styles.maskGroupIcon} alt="" src="assets/pc/tih/tih.png" />
                        <b className={styles.b4}>향후 3년간 사업 전략</b>
                        <div className={styles.groupContainer}>

                            <div className={styles.tomorrowInHereParent}>
                                <b className={styles.b}>Tomorrow, In Here</b>
                                <b className={styles.aiContainer}>
                                    <p className={styles.ai}>{`‘내일도 여기에서 아바타로 사람들과 만나고, 방송하며 `}</p>
                                    <p className={styles.ai}>즐긴다.’ 라는 의미의 생성형 AI 기술이 적용 된 AI 아바타</p>
                                    <p className={styles.ai}>서비스.</p>
                                    <p className={styles.ai}>{`버추얼 유튜버 / 스트리머를 위한 방송 미디어 서비스와 `}</p>
                                    <p className={styles.ai}>{`팬덤을 만들고 AI NPC를 통한 대화형 소통 서비스를 `}</p>
                                    <p className={styles.p}>제공함</p>
                                </b>
                            </div>
                            <div className={styles.group}>
                                <b className={styles.b}><a className={styles.deleteLinkStyle} rel="stylesheet" href="https://tih.ai/" target="_blank">바로가기</a></b>
                                {/* <img className={styles.vectorIcon} alt="" src="Vector.svg" /> */}
                            </div>
                        </div>
                        <div className={styles.tih2}>2025 TIH 서비스 런칭</div>
                        <div className={styles.tihAiNpc}>2027 TIH AI NPC 테마 플레이스 런칭</div>
                        <div className={styles.ai1}>2026 AI버추얼 스트리머, 공연장 런칭</div>
                        <b className={styles.kContainer}>
                            <p className={styles.ai}>{`북미, 유럽, 동남아를 대상으로 서비스로 런칭, `}</p>
                            <p className={styles.p}>새로운 K-버추얼 스트리머 문화 전파</p>
                        </b>
                        <b className={styles.tihContainer}>
                            <p className={styles.ai}>TIH 테마 플레이스에 패션, 교육, 헬스 서비스 등 입점 예정,</p>
                            <p className={styles.p}>AI NPC 플랫폼을 이용해 AI NPC를 사용할 수 있도록 제공</p>
                        </b>
                        <b className={styles.personaAiContainer}>
                            <p className={styles.ai}>다양한 Persona를 가진 AI 버추얼 스트리머 그룹,</p>
                            <p className={styles.p}>스트리머/팬덤을 위한 대규모 Virtual Stage 런칭</p>
                        </b>
                        <img className={styles.lineIcon} alt="" src="icons/pc/tih/strategyLine1.png" />
                        <img className={styles.groupChild1} alt="" src="icons/pc/tih/strategyLine2.png" />
                        <img className={styles.groupChild2} alt="" src="icons/pc/tih/strategyLine3.png" />
                        <img className={styles.groupIcon} alt="" src="assets/pc/tih/strategy.png" />

                    </div>
                </div>
            ) : (
                <div className={stylesEn.tih}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.virtualStreamerAndContainer}>
                            <p className={stylesEn.virtualStreamerAnd}>Virtual Streamer and Fandom Service</p>
                            <p className={stylesEn.virtualStreamerAnd}>Tomorrow, In Here</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        <b className={stylesEn.tihtomorrowInHere}>TIH(Tomorrow, In Here)</b>
                        <div className={stylesEn.ellipseDiv} />

                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/tih/tih.png" />
                        <div className={stylesEn.groupContainer}>
                            <div className={stylesEn.tomorrowInHereParent}>
                                <b className={stylesEn.website}>Tomorrow, In Here</b>
                                <div className={stylesEn.joinUsTomorrowContainer}>
                                    <p className={stylesEn.joinUsTomorrow}>Join us tomorrow in this virtual space to connect,</p>
                                    <p className={stylesEn.joinUsTomorrow}>stream, and have fun with your avatars!</p>
                                    <p className={stylesEn.joinUsTomorrow}>This is powered by AI Avatar Service Powered by</p>
                                    <p className={stylesEn.joinUsTomorrow}>Generative AI Technology.</p>
                                    <p className={stylesEn.joinUsTomorrow}>Virtual Streamer Broadcasting Media Service</p>
                                    <p className={stylesEn.joinUsTomorrow}>with Fandom Creation and AI NPC-Powered</p>
                                    <p className={stylesEn.virtualStreamerAnd}>Conversational Interaction Service.</p>
                                </div>
                            </div>
                            <div className={stylesEn.websiteParent}>
                                <div className={stylesEn.website}><a className={styles.deleteLinkStyle} rel="stylesheet" href="https://tih.ai/" target="_blank">Website</a></div>
                                <img className={stylesEn.vectorIcon} alt="" src="Vector.svg" />
                            </div>
                        </div>

                        <b className={stylesEn.businessStrategyFor}>Business Strategy for the Next Three Years</b>
                        <div className={stylesEn.tihServiceTo}>TIH Service to Launch in 2025</div>
                        <div className={stylesEn.tihAiNpcContainer}>
                            <p className={stylesEn.joinUsTomorrow}>TIH AI NPC Thema Place</p>
                            <p className={stylesEn.virtualStreamerAnd}>Launch in 2027</p>
                        </div>
                        <div className={stylesEn.aiVirtualStreamerContainer}>
                            <p className={stylesEn.joinUsTomorrow}>AI Virtual Streamer Stage</p>
                            <p className={stylesEn.virtualStreamerAnd}>to Launch in 2026</p>
                        </div>
                        <div className={stylesEn.expandingToNorthContainer}>
                            <p className={stylesEn.joinUsTomorrow}>{`Expanding to North America, Europe, and Southeast `}</p>
                            <p className={stylesEn.virtualStreamerAnd}>Asia to Promote K-Virtual Streamer Culture</p>
                        </div>
                        <div className={stylesEn.fashionEducationAndContainer}>
                            <p className={stylesEn.joinUsTomorrow}>{`Fashion, Education, and Health Services to Enter TIH `}</p>
                            <p className={stylesEn.joinUsTomorrow}>{`Themed Places, Empowering Businesses with `}</p>
                            <p className={stylesEn.virtualStreamerAnd}>AI NPC Platforms</p>
                        </div>
                        <div className={stylesEn.aGroupOfContainer}>
                            <p className={stylesEn.joinUsTomorrow}>A group of AI virtual streamers with personas</p>
                            <p className={stylesEn.joinUsTomorrow}>launches a large Virtual Stage for streamers</p>
                            <p className={stylesEn.virtualStreamerAnd}>and fandoms</p>
                        </div>
                        <img className={stylesEn.lineIcon} alt="" src="icons/pc/tih/strategyLine1.png" />
                        <img className={stylesEn.groupChild1} alt="" src="icons/pc/tih/strategyLine2.png" />
                        <img className={stylesEn.groupChild2} alt="" src="icons/pc/tih/strategyLine3.png" />
                        <img className={stylesEn.groupIcon} alt="" src="assets/pc/tih/strategy.png" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tih;
