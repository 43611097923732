import { FunctionComponent } from 'react';
import axios from 'axios';

import styles from './Main.module.css';
import stylesEn from './MainEn.module.css';

import 'swiper/css';
import './Swiper.css';
import 'swiper/css/pagination';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';

import React, { useState, useRef, useCallback, useEffect } from 'react';


const Main: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    // const [mainImagesArr, setMainImagesArr] = useState<string[]>([]);
    const [mainImage, setMainImage] = useState("");

    const [mainImagesArr, setMainImagesArr] = useState<string[]>([
        "assets/pc/main/main1.png",
        "assets/pc/main/main2.png",
        "assets/pc/main/main3.png"
    ]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(
            'http://localhost:8080/api/submit', {
            method: "post",
            body: JSON.stringify({ src: mainImage }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        const result = await response.json();
        console.warn(result);
        if (result) {
            alert("Data saved succesfully");
            setMainImagesArr(prevImages => [...prevImages, result.src]);
            setMainImage("");
        };
    };

    useEffect(() => {
        const fetchMainImages = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/mainImages');
                const imageData = response.data;
                setMainImagesArr(imageData.map((item: any) => item.src));
                // setMainImagesArr(imageData); 

            } catch (error) {
                console.error('Error fetching mainImages:', error);
            }
        };

        fetchMainImages(); // 이미지 데이터 가져오기 호출
    }, []);

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div>
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={0}
                        autoplay={{ delay: 3000, disableOnInteraction: false, stopOnLastSlide: false }}
                        freeMode={false}
                        pagination={{ clickable: true }}
                        loop={true}
                        className={styles.icon2}
                    >
                        {mainImagesArr.map((src, index) => (
                            <SwiperSlide key={index}>
                                <img className={styles.swiperMain} alt={`Slide ${index}`} src={src} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <b className={styles.aiContainer}>
                        < p className={styles.ai2}>생성형 AI 아바타 서비스의</p>
                        <p className={styles.p}>새로운 패러다임</p>
                    </b>
                </div>
            ) : (
                <div>
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={0}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        freeMode={false}
                        pagination={{ clickable: true }}
                        loop={true}
                        className={stylesEn.icon2}
                    >
                        {mainImagesArr.map((src, index) => (
                            <SwiperSlide key={index}>
                                <img className={stylesEn.swiperMain} alt={`Slide ${index}`} src={src} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <b className={stylesEn.aiContainer}>
                        <p className={stylesEn.ai2}>Generative AI Avatar Service</p>
                        <p className={stylesEn.p}>A new Paradigm</p>
                    </b>
                </div>
            )
            }
        </div>
    );
};

export default Main;

