import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
// 작성된 마크업을 불러오기. import 를 간소화하기 위해 *를 사용
import homeEN from "./en/home.json";
import homeKO from "./ko/home.json";
import companyEN from "./en/company.json";
import companyKO from "./ko/company.json";
import companyPcKO from "./ko/companyPc.json";
import companyPcEN from "./en/companyPc.json";


import solutionEN from "./en/solution.json";
import solutionKO from "./ko/solution.json";
import tihEN from "./en/tih.json";
import tihKO from "./ko/tih.json";
import recruitEN from "./en/recruit.json";
import recruitKO from "./ko/recruit.json";
import contactEN from "./en/contact.json";
import contactKO from "./ko/contact.json";
// import * as en from "./en";
// import * as ko from "./ko";
// import main from "../locales/ko/main.json"

// const resources: Resource = {
//   "en": {
//     main: mainEN // 비구조화 할당을 통해 간소화
//   },
//   "ko": {
//     main: mainKO
//   }
// } as const;

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "ko", // 초기 설정 언어
//   fallbackLng: {
//     "en": ["en"], // 한국어 불러오는 것이 실패했을 경우, 영문 사용
//     default: ["ko"]
//   },
//   debug: true,
//   keySeparator: false,
//   interpolation: {
//     escapeValue: false
//   },
//   react: {
//     useSuspense: false
//   }
// });

const resources = {
  "en": {
    home: homeEN,
    company: companyEN,
    solution: solutionEN,
    tih: tihEN,
    recruit: recruitEN,
    contact: contactEN,

    companyPc: companyPcEN,

  },
  "ko": {
    home: homeKO,
    company: companyKO,
    solution: solutionKO,
    tih: tihKO,
    recruit: recruitKO,
    contact: contactKO,

    companyPc: companyPcKO
  }

  // en: { translation: langEn },
  // ko: { translation: langKo },
}

const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  // lng: localStorage.getItem('language') || userLanguage || 'en',
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n;

export const languages = ['en', 'ko'] as const;
export type Languages = typeof languages[number];