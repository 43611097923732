import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './DeepReal.module.css';
import stylesEn from './DeepRealEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    solutions: {
        aiAvatar: string;
        deepReal: string;
        dVirtualStage: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const DeepReal: FunctionComponent<Props> = ({ solutions, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'aiAvatar':
                    setSelectedCategory(solutions.aiAvatar);
                    break;
                case 'deepReal':
                    setSelectedCategory(solutions.deepReal);
                    break;
                case 'dVirtualStage':
                    setSelectedCategory(solutions.dVirtualStage);
                    break;
            }
        }
    }, [tab, solutions, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(solutions[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.deepReal}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.dContainer}>
                            <p className={styles.d}>3D 라이브 스트리밍을 이끌어 갈</p>
                            <p className={styles.d}>브이다임만의 기술</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                                {solutions.aiAvatar}
                            </div>
                            <div className={styles.deepReal1} onClick={() => handleCategoryClick('deepReal')}>
                                {solutions.deepReal}
                            </div>
                            <div className={styles.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                {solutions.dVirtualStage}
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>

                        {/* <b className={styles.deepReal1}>Deep Real</b>
        				<div className={styles.dVirtualStage}>3D Virtual Stage</div> */}
                        {/* <div className={styles.aiAvatar}>AI Avatar</div> */}
                        <div className={styles.deepRealSolutionParent}>
                            <b className={styles.b}>Deep Real Solution</b>
                            <b className={styles.dContainer1}>
                                <p className={styles.d1}>3D 환경에서 제작한 숏폼/AR 영상 콘텐츠를 더욱</p>
                                <p className={styles.d1}>{`실감나게 표현하기 위한 솔루션으로 조화롭지 않은 `}</p>
                                <p className={styles.d}>부분을 파악 후 보정하는 브이다임만의 AI 기술</p>
                            </b>
                        </div>
                        <img className={styles.maskGroupIcon} alt="" src="assets/pc/solution/deepReal.png" />
                    </div>
                </div>
            ) : (
                <div className={stylesEn.deepReal}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.vdigmsTechnologySpearheadinContainer}>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>Vdigm’s Technology Spearheading</p>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>3D Live Streaming</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.aiAvatar} onClick={() => handleCategoryClick('aiAvatar')}>
                                AI Avatar
                            </div>
                            <div className={stylesEn.deepReal1} onClick={() => handleCategoryClick('deepReal')}>
                                Deep Real
                            </div>
                            <div className={stylesEn.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                3D Virtual Stage
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <b className={styles.deepReal1}>Deep Real</b>
                        <div className={styles.dVirtualStage}>3D Virtual Stage</div> */}
                        {/* <div className={styles.aiAvatar}>AI Avatar</div> */}
                        <div className={stylesEn.deepRealSolutionParent}>
                            <b className={stylesEn.deepRealSolution}>Deep Real Solution</b>
                            <div className={stylesEn.vdigmsAiTechnologyContainer}>
                                <p className={stylesEn.vdigmsAiTechnology}>Vdigm's AI Technology: Enhancing the Realism of</p>
                                <p className={stylesEn.vdigmsAiTechnology}>Short-Form and AR Video Content by Identifying and</p>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>Correcting Inconsistencies in 3D Environments edit.</p>
                            </div>
                        </div>
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/solution/deepReal.png" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeepReal;
