import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';

import styles from './About.module.css';
import stylesEn from './AboutEn.module.css';

import React from 'react';

import { useTranslation } from "react-i18next";

interface Props {
    introduces: {
        about: string;
        history: string;
        organization: string;
        vision: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const About: FunctionComponent<Props> = ({ introduces, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
            }
        }
    }, [tab, introduces, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.aiContainer}>
                            <p className={styles.paradigmShift}>{t(`company:about:Introduce0`)}</p>
                            <p className={styles.paradigmShift}>{t(`company:about:Introduce1`)}</p>
                        </div>
                    </div>

                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.ellipseDiv} />
                            <div className={styles.div5} onClick={() => handleCategoryClick('about')}>
                                {t(`company:category:companyAbout`)}
                            </div>
                            <div className={styles.b1} onClick={() => handleCategoryClick('organization')}>
                                {t(`company:category:organization`)}
                            </div>
                            <div className={styles.b} onClick={() => handleCategoryClick('history')}>
                                {t(`company:category:history`)}
                            </div>
                            <div className={styles.b2} onClick={() => handleCategoryClick('vision')}>
                                {t(`company:category:vision`)}
                            </div>
                        </div>
                        <div className={styles.logoAbout}>
                            <b className={styles.bi}>{t(`company:about:logo:vdigmBI`)}</b>
                            <div className={styles.visualAiContainer}>
                                <p className={styles.visualAi}>정육면체는 서로 다른 두 개의 가상 세계라는 의미를 담고, 가운데 역삼각형은 Visual AI & Virtual World의 V를 의미합니다.</p>
                                <p className={styles.paradigmShift}>또한 잘린 단면은 두 가상 세계의 충돌로 일어난 새로운 Paradigm Shift, 새로운 패러다임으로의 전환을 상징합니다.</p>
                            </div>
                            <img className={styles.groupChild1} alt="" src="assets/pc/company/aboutLogo.png" />
                            {/* <img className={styles.groupIcon} alt="" src="assets/pc/company/vdigmLogoMark.png" />
                        <img className={styles.icon1} alt="" src="assets/pc/company/vdigmLogoWidth.png" />
                        <img className={styles.icon2} alt="" src="assets/pc/company/vdigmLogoHeight.png" /> */}
                        </div>

                        {/* <img className={styles.groupIcon} alt="" src="Group 1399.svg" /> */}
                        {/* <img className={styles.groupChild1} alt="" src="assets/pc/company/vdigmLogoMark.png" /> */}

                        {/* <img className={styles.groupChild0} alt="" src="assets/pc/company/aboutLogo.png" /> */}

                        <div className={styles.sloganGroup}>
                            <div className={styles.slogan}>{t(`company:about:slogan:slogan`)}</div>
                            <div className={styles.rectangleGroup}>
                                <div className={styles.groupChild2} />
                                <div className={styles.groupChild3} />
                                <div className={styles.div1}>{t(`company:about:slogan:description0`)}</div>
                                <div className={styles.ai1}>{t(`company:about:slogan:description1`)}</div>
                            </div>
                            <img className={styles.icon2} alt="" src="assets/pc/company/aboutSloganMark.png" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={stylesEn.aboutUs}>
                    {/* <div className={stylesEn.rectangleParent1}> */}

                    {/* </div> */}
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.additionallyTheTruncated}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.additionallyTheTruncated}>Experiences with Generative AI</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.category}>
                            <div className={stylesEn.aboutUs1} onClick={() => handleCategoryClick('about')}>
                                {t(`companyPc:category:companyAbout`)}
                            </div>
                            <div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
                                {t(`companyPc:category:organization`)}
                            </div>
                            <div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
                                {t(`companyPc:category:history`)}
                            </div>
                            <div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
                                {t(`companyPc:category:vision`)}
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>
                        <div className={stylesEn.groupChild} />


                        <div className={stylesEn.logoAbout}>
                            <b className={stylesEn.vdigmBi}>Vdigm BI</b>
                            <div className={stylesEn.theHexagonRepresentsContainer}>
                                <p className={stylesEn.theHexagonRepresents}>The Hexagon represents two distinct virtual worlds, while the central inverted triangle symbolizes the “V” in Visula AI & Virtual World.</p>
                                <p className={stylesEn.additionallyTheTruncated}>Additionally, the truncated surface signifies a paradigm shift, a radical transformation resulting from the collision of these two virtual worlds.</p>
                            </div>
                            <img className={stylesEn.groupChild1} alt="" src="assets/pc/company/aboutLogo.png" />
                        </div>

                        <div className={stylesEn.sloganGroup}>
                            <b className={stylesEn.slogan}>SLOGAN</b>
                            <img className={stylesEn.groupIcon} alt="" src="assets/pc/company/aboutSloganMark.png" />
                            {/* <div className={stylesEn.history}>History</div>
        				<div className={stylesEn.organizationChart}>Organization Chart</div>
        				<div className={stylesEn.vision}>Vision</div> */}
                            {/* <img className={stylesEn.groupIcon} alt="" src="assets/pc/company/aboutSlogan.png" /> */}

                            {/* <b className={stylesEn.aboutUs1}>About Us</b> */}
                            <div className={stylesEn.rectangleGroup}>
                                <div className={stylesEn.groupChild2} />
                                <div className={stylesEn.groupChild3} />
                                <b className={stylesEn.stepIntoThe}>Step into the world of AI Avatars,</b>
                                <b className={stylesEn.whereYourImagination}>where your imagination becomes reality.</b>
                            </div>

                        </div>
                    </div>
                </div>

            )}

        </div>





        // 	<div className={styles.div}>
        // 		<div className={styles.rectangleParent}>
        // 				<div className={styles.groupChild} />
        // 				<div className={styles.bi}>브이다임 BI</div>
        // 				<div className={styles.slogan}>SLOGAN</div>
        // 				<b className={styles.b}>연혁</b>
        // 				<b className={styles.b1}>조직도</b>
        // 				<b className={styles.b2}>비전</b>
        // 				<b className={styles.visualAiContainer}>
        //   					<p className={styles.visualAi}>{`정육면체는 서로 다른 두 개의 가상 세계라는 의미를 담고, 가운데 역삼각형은 Visual AI & Virtual World의 V를 의미합니다.`}</p>
        //   					<p className={styles.paradigmShift}>또한 잘린 단면은 두 가상 세계의 충돌로 일어난 새로운 Paradigm Shift, 새로운 패러다임으로의 전환을 상징합니다.</p>
        // 				</b>
        // 				<div className={styles.parent}>
        //   					<div className={styles.div1}>회사소개</div>
        //   					<b className={styles.b3}>솔루션</b>
        //   					<div className={styles.tih}>TIH</div>
        //   					<b className={styles.b4}>채용</b>
        //   					<div className={styles.contact}>Contact</div>
        // 				</div>
        // 				<img className={styles.icon} alt="" src="가로형.png" />
        // 				<div className={styles.aiContainer}>
        //   					<p className={styles.paradigmShift}>생성형 AI 아바타 서비스의</p>
        //   					<p className={styles.paradigmShift}>새로운 패러다임을 만들어 나갑니다</p>
        // 				</div>
        // 				<div className={styles.groupItem} />

        // 				<img className={styles.groupIcon} alt="" src="Group 1399.svg" />
        // 				<img className={styles.groupChild1} alt="" src="Group 1400.svg" />
        // 				<img className={styles.icon1} alt="" src="가로형.png" />
        // 				<img className={styles.icon2} alt="" src="세로형.png" />
        // 				<div className={styles.div5}>회사소개</div>
        // 				<div className={styles.ellipseDiv} />
        // 				<div className={styles.rectangleGroup}>
        //   					<div className={styles.groupChild2} />
        //   					<div className={styles.groupChild3} />
        //   					<div className={styles.div1}>당신의 상상을 현실로,</div>
        //   					<div className={styles.ai1}>AI 아바타 세계로 초대합니다.</div>
        // 				</div>
        // 				<div className={styles.group}>
        //   					<img className={styles.icon3} alt="" src="한영.svg" />
        //   					<div className={styles.kor}>KOR</div>
        //   					<div className={styles.eng}>ENG</div>
        // 				</div>
        // 		</div>
        // </div>
    );
};

export default About;
