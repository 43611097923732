import { FunctionComponent } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// import { Languages, languages } from "../locales/i18n.ts";
import { Languages, languages } from "../../locales/i18n.ts";


import styles from './Menu.module.css';
import stylesEn from './MenuEn.module.css';

import React, { useState, useRef, useCallback, useEffect } from 'react';

import Main from './Main.tsx'

interface Category {
    name: string;
    link: string;
}

const Menu: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangeLanguage = (lang: Languages) => {
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
    };

    // const [menuNames, setMenuNames] = useState<string[]>([
    //     t(`home:menu:companyAbout`),
    //     t(`home:menu:vdigmSolution`),
    //     t(`home:menu:tih`),
    //     t(`home:menu:recruit`),
    //     t(`home:menu:contact`),
    // ]);

    const [categoriesObjArr, setCategoriesObjArr] = useState<Category[][]>([
        [
            {
                name: t(`companyPc:category:companyAbout`),
                link: "/company?tab=about"
            },
            {
                name: t(`companyPc:category:history`),
                link: "/company?tab=history"
            },
            {
                name: t(`companyPc:category:organization`),
                link: "/company?tab=organization"
            },
            {
                name: t(`companyPc:category:vision`),
                link: "/company?tab=vision"
            }
        ],
        [
            {
                name: t(`solution:category:title0`),
                link: "/solution?tab=aiAvatar"
            },
            {
                name: t(`solution:category:title1`),
                link: "/solution?tab=deepReal"
            },
            {
                name: t(`solution:category:title2`),
                link: "/solution?tab=dVirtualStage"
            },
        ],
        [
            {
                name: t(`tih:category:tihPc`),
                link: "/tih"
            },
        ],
        [
            {
                name: t(`recruit:category:idealTalent`),
                link: "/recruit?tab=idealTalent"
            },
            {
                name: t(`recruit:category:welfareBenefit`),
                link: "/recruit?tab=welfareBenefit"
            },
            {
                name: t(`recruit:category:recruitProcedure`),
                link: "/recruit?tab=recruitProcess"
            },
        ],
        [
            {
                name: t(`contact:category:businessContact`),
                link: "/contact"
            },
        ]
    ]
    )

    const [isMouseDown1, setIsMouseDown1] = useState(false);
    const [isMouseDown2, setIsMouseDown2] = useState(false);
    const [isMouseDown3, setIsMouseDown3] = useState(false);
    const [isMouseDown4, setIsMouseDown4] = useState(false);
    const [isMouseDown5, setIsMouseDown5] = useState(false);

    return (
        <div>

            {/* <img className={styles.icon} alt="" src="icons/vdigmlogowidth.png" /> */}

            <div className={styles.div}>

                {/* {languages.map(lang => (

                    <button className={styles.multiLang} key={lang} onClick={() => handleChangeLanguage(lang)}>
                        {t(`${lang}`)}
                    </button>

                ))} */}


                {/* <div className={styles.element}>

                {categoryNamesArr[selectedCategoryIndex].map((element, index) => (
                    <div key={index} className={styles.elementContainer}>
                        {('image' in element) && <img src={element.image} alt={element.title} />}
                        <h3>{element.title}</h3>
                        <p>{(currentLanguage === 'ko'
                            ? element.descriptionKo
                            : element.descriptionEn
                        ).map((ele, idx) => (
                            <p key={idx}>{ele}</p>
                        ))}
                        </p>
                    </div>
                ))}
            </div> */}

                {/* <div className={styles.category}>
                    {categoryNamesArr.map((category, index) => (
                        <Link to={menu.link} key={index}>
                    ))} */}

                {currentLanguage === "ko" ?

                    (<div className={styles.groupChild4}>
                        <Link to={categoriesObjArr[0][0].link}>
                            <div className={styles.div3}>회사소개</div>
                        </Link>
                        <Link to={categoriesObjArr[0][1].link}>
                            <div className={styles.div5}>연혁</div>
                        </Link>
                        <Link to={categoriesObjArr[0][2].link}>
                            <div className={styles.div7}>조직도</div>
                        </Link>
                        <Link to={categoriesObjArr[0][3].link}>
                            <div className={styles.div9}>비전</div>
                        </Link>
                        <Link to={categoriesObjArr[1][0].link}>
                            <div className={styles.aiAvatar}>AI Avatar</div>
                        </Link>
                        <Link to={categoriesObjArr[1][1].link}>
                            <div className={styles.deepReal}>Deep Real</div>
                        </Link>
                        <Link to={categoriesObjArr[1][2].link}>
                            <div className={styles.dVirtualStage}>3D Virtual Stage</div>
                        </Link>
                        <Link to={categoriesObjArr[2][0].link}>
                            <div className={styles.tih}>TIH</div>
                        </Link>
                        <Link to={categoriesObjArr[3][0].link}>
                            <div className={styles.div4}>인재상</div>
                        </Link>
                        <Link to={categoriesObjArr[3][1].link}>
                            <div className={styles.div6}>복리후생</div>
                        </Link>
                        <Link to={categoriesObjArr[3][2].link}>
                            <div className={styles.div8}>채용절차</div>
                        </Link>
                        <Link to={categoriesObjArr[4][0].link}>
                            <div className={styles.businessContact}>Business Contact</div>
                        </Link>
                    </div>) : (
                        <div className={stylesEn.groupChild4}>
                            <Link to={categoriesObjArr[0][0].link}>
                                <div className={stylesEn.div3}>About Us</div>
                            </Link>
                            <Link to={categoriesObjArr[0][1].link}>
                                <div className={stylesEn.div5}>History</div>
                            </Link>
                            <Link to={categoriesObjArr[0][2].link}>
                                <div className={stylesEn.div7}>Organization Chart</div>
                            </Link>
                            <Link to={categoriesObjArr[0][3].link}>
                                <div className={stylesEn.div9}>Vision</div>
                            </Link>
                            <Link to={categoriesObjArr[1][0].link}>
                                <div className={stylesEn.aiAvatar}>AI Avatar</div>
                            </Link>
                            <Link to={categoriesObjArr[1][1].link}>
                                <div className={stylesEn.deepReal}>Deep Real</div>
                            </Link>
                            <Link to={categoriesObjArr[1][2].link}>
                                <div className={stylesEn.dVirtualStage}>3D Virtual Stage</div>
                            </Link>
                            <Link to={categoriesObjArr[2][0].link}>
                                <div className={stylesEn.tih}>TIH</div>
                            </Link>
                            <Link to={categoriesObjArr[3][0].link}>
                                <div className={stylesEn.div4}>People</div>
                            </Link>
                            <Link to={categoriesObjArr[3][1].link}>
                                <div className={stylesEn.div6}>Benefits</div>
                            </Link>
                            <Link to={categoriesObjArr[3][2].link}>
                                <div className={stylesEn.div8}>Recruitment Process</div>
                            </Link>
                            <Link to={categoriesObjArr[4][0].link}>
                                <div className={stylesEn.businessContact}>Business Contact</div>
                            </Link>
                        </div>)
                }

                {/* {currentLanguage === "ko" ?

                (<div className={styles.groupChild4}>
                    <Link to={categoriesObjArr[0][0].link}>
                        <div className={styles.div3}>{categoriesObjArr[0][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][1].link}>
                        <div className={styles.div5}>{categoriesObjArr[0][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][2].link}>
                        <div className={styles.div7}>{categoriesObjArr[0][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][3].link}>
                        <div className={styles.div9}>{categoriesObjArr[0][3].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][0].link}>
                        <div className={styles.aiAvatar}>{categoriesObjArr[1][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][1].link}>
                        <div className={styles.deepReal}>{categoriesObjArr[1][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][2].link}>
                        <div className={styles.dVirtualStage}>{categoriesObjArr[1][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[2][0].link}>
                        <div className={styles.tih}>{categoriesObjArr[2][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][0].link}>
                        <div className={styles.div4}>{categoriesObjArr[3][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][1].link}>
                        <div className={styles.div6}>{categoriesObjArr[3][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][2].link}>
                        <div className={styles.div8}>{categoriesObjArr[3][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[4][0].link}>
                        <div className={styles.businessContact}>{categoriesObjArr[4][0].name}</div>
                    </Link>
                </div>) : (
                    <div className={stylesEn.groupChild4}>
                    <Link to={categoriesObjArr[0][0].link}>
                        <div className={stylesEn.div3}>{categoriesObjArr[0][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][1].link}>
                        <div className={stylesEn.div5}>{categoriesObjArr[0][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][2].link}>
                        <div className={stylesEn.div7}>{categoriesObjArr[0][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[0][3].link}>
                        <div className={stylesEn.div9}>{categoriesObjArr[0][3].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][0].link}>
                        <div className={stylesEn.aiAvatar}>{categoriesObjArr[1][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][1].link}>
                        <div className={stylesEn.deepReal}>{categoriesObjArr[1][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[1][2].link}>
                        <div className={stylesEn.dVirtualStage}>{categoriesObjArr[1][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[2][0].link}>
                        <div className={stylesEn.tih}>{categoriesObjArr[2][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][0].link}>
                        <div className={stylesEn.div4}>{categoriesObjArr[3][0].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][1].link}>
                        <div className={stylesEn.div6}>{categoriesObjArr[3][1].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[3][2].link}>
                        <div className={stylesEn.div8}>{categoriesObjArr[3][2].name}</div>
                    </Link>
                    <Link to={categoriesObjArr[4][0].link}>
                        <div className={stylesEn.businessContact}>{categoriesObjArr[4][0].name}</div>
                    </Link>
                    </div>)
} */}


                {/* <div className={styles.groupChild5} />
            <div className={styles.groupChild6} /> */}

                <div className={styles.container}>
                    <img className={styles.icon1} alt="" src="icons/language.png" />
                    {languages.map(lang => (
                        <div>
                            <div key={lang} onClick={() => handleChangeLanguage("ko")}>

                                {currentLanguage === "ko"
                                    ? <div className={styles.korOn}>KOR</div>
                                    : <div className={styles.korOff}>KOR</div>}
                            </div>
                            <div key={lang} onClick={() => handleChangeLanguage("en")}>
                                {currentLanguage === "en"
                                    ? <div className={styles.engOn}>ENG</div>
                                    : <div className={styles.engOff}>ENG</div>}

                            </div>
                        </div>
                    ))}
                </div>
                    {/* {languages.map(lang => (

                        <button className={styles.multiLang} key={lang} onClick={() => handleChangeLanguage(lang)}>
                            {t(`${lang}`)}
                        </button>

                    ))} */}
                {/* <div className={styles.container}>
                    <img className={styles.icon1} alt="" src="icons/language.png" />
                    <div className={styles.kor}>KOR</div>
                    <div className={styles.eng}>ENG</div>
                </div> */}
            </div>
        </div>
    );
};

export default Menu;

