import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './IdealTalent.module.css';
import stylesEn from './IdealTalentEn.module.css';
import { useTranslation } from "react-i18next";

import React from 'react';


interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcess: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

const IdealTalent: FunctionComponent<Props> = (props) => {
    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcess':
                    setSelectedCategory(recruits.recruitProcess);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.div2}>
                            <p className={styles.p}>브이다임은</p>
                            <p className={styles.p}>언제나 열려있습니다</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                        <div className={styles.div6} onClick={() => handleCategoryClick('idealTalent')}>
                            인재상
                        </div>
                        <div className={styles.b} onClick={() => handleCategoryClick('welfareBenefit')}>
                            복리후생
                        </div>
                        <div className={styles.b1} onClick={() => handleCategoryClick('recruitProcess')}>
                            채용절차
                        </div>
                            <div className={styles.ellipseDiv} />
                        </div>

                        {/* <b className={styles.b}>복리후생</b>
        				<b className={styles.b1}>채용절차</b> */}


                        {/* <div className={styles.div6}>인재상</div> */}
                        <b className={styles.b5}>브이다임이 추구하는 태도</b>
                        <div className={styles.div7}>늘 열린마음으로 함께 합니다.</div>
                        <b className={styles.b6}>
                            <p className={styles.p2}>브이다임은 지속적으로 성장하고자 하는 기업으로 열정이</p>
                            <p className={styles.p2}>있는 분이라면 함께하고자 합니다.</p>
                            <p className={styles.p}>같이 성장하고자 하는 인재를 언제나 환영합니다.</p>
                        </b>
                        <b className={styles.b7}>
                            <p className={styles.p2}>열정을 갖고 방향성을 함께 공유하고 고민하며, 노력하고 맡은 일에 책임을 다하는 인재,</p>
                            <p className={styles.p}>새로운 것에 자신있게 도전하는 인재를 모십니다.</p>
                        </b>
                        <div className={styles.rectangleGroup}>
                            {/* <div className={styles.groupChild1} /> */}
                            <img className={styles.talk76478631280Icon} alt="" src="assets/pc/recruit/idealTalent.png" />
                        </div>
                        <div className={styles.group}>
                            <div className={styles.div8}>성실</div>
                            <img className={styles.ellipseIcon} alt="" src="icons/pc/recruit/attribute1.png" />
                        </div>
                        <div className={styles.container}>
                            <div className={styles.div9}>소통</div>
                            <img className={styles.ellipseIcon} alt="" src="icons/pc/recruit/attribute2.png" />
                        </div>
                        <div className={styles.groupDiv}>
                            <div className={styles.div9}>도전</div>
                            <img className={styles.ellipseIcon} alt="" src="icons/pc/recruit/attribute3.png" />
                        </div>

                    </div>
                </div>
            ) : (
                <div className={stylesEn.people}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.vdigmIsAlwaysContainer}>
                            <p className={stylesEn.vdigmIs}>Vdigm is</p>
                            <p className={stylesEn.vdigmIs}>always open.</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        <div className={stylesEn.category}>
                            <div className={stylesEn.people1} onClick={() => handleCategoryClick('idealTalent')}>
                                People
                            </div>
                            <div className={stylesEn.benefits} onClick={() => handleCategoryClick('welfareBenefit')}>
                                Benefits
                            </div>
                            <div className={stylesEn.recruitmentProcess} onClick={() => handleCategoryClick('recruitProcess')}>
                                Recruitment Process
                            </div>
                        </div>

                        {/* <div className={stylesEn.benefits}>Benefits</div>
                        <div className={stylesEn.recruitmentProcess}>Recruitment Process</div> */}
                        {/* <b className={stylesEn.people1}>People</b> */}
                        <div className={stylesEn.ellipseDiv} />
                        <div className={stylesEn.weAreAlwaysHereWithAnOpeParent}>
                            <b className={stylesEn.weAreAlways}>We are always here with an open mind</b>
                            <div className={stylesEn.vdigmIsAContainer}>
                                <p className={stylesEn.vdigmIsA}>{`Vdigm is a company that is always striving for `}</p>
                                <p className={stylesEn.vdigmIsA}>growth. We are passionate about what we do, and</p>
                                <p className={stylesEn.vdigmIsA}>{`we are looking for people who share that passion. `}</p>
                                <p className={stylesEn.vdigmIsA}>{`If you are a talented individual who is looking to `}</p>
                                <p className={stylesEn.vdigmIsA}>grow with a company, we encourage you to join us.</p>
                                <p className={stylesEn.vdigmIsA}>{`We are seeking passionate individuals who are `}</p>
                                <p className={stylesEn.vdigmIsA}>{`eager to share ideas, collaborate, work hard, take `}</p>
                                <p className={stylesEn.vdigmIsA}>{`responsibility for their work, and confidently take on `}</p>
                                <p className={stylesEn.vdigmIs}>new challenges. Sincerity Communication Challenge</p>
                            </div>
                        </div>
                        <div className={stylesEn.rectangleGroup}>
                            {/* <div className={stylesEn.groupChild1} /> */}
                            <img className={stylesEn.talk76478631280Icon} alt="" src="assets/pc/recruit/idealTalent.png" />
                        </div>
                        <b className={stylesEn.vdigmsSoughtAfterAttitudes}>Vdigm's sought-after attitudes</b>
                        <div className={stylesEn.weAreSeekingContainer}>
                            <p className={stylesEn.vdigmIsA}>{`We are seeking passionate individuals who are eager to share ideas, collaborate, work hard, take `}</p>
                            <p className={stylesEn.vdigmIs}>responsibility for their work, and confidently take on new challenges</p>
                        </div>
                        <div className={stylesEn.sincerity}>Sincerity</div>
                        <img className={stylesEn.ellipseIcon} alt="" src="icons/pc/recruit/attribute1.png" />
                        <div className={stylesEn.communicaion}>Communicaion</div>
                        <img className={stylesEn.groupChild2} alt="" src="icons/pc/recruit/attribute2.png" />
                        <div className={stylesEn.challenge}>Challenge</div>
                        <img className={stylesEn.groupChild3} alt="" src="icons/pc/recruit/attribute3.png" />

                    </div>
                </div>
            )}
        </div>
    );
};

export default IdealTalent;
