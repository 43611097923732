import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './About.module.css';
import stylesEn from './AboutEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    introduces: {
        about: string;
        history: string;
        organization: string;
        vision: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const About: FunctionComponent<Props> = ({ introduces, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(introduces.about);
                    break;
                case 'history':
                    setSelectedCategory(introduces.history);
                    break;
                case 'organization':
                    setSelectedCategory(introduces.organization);
                    break;
                case 'vision':
                    setSelectedCategory(introduces.vision);
                    break;
                default:
                    setSelectedCategory(introduces.about);
            }
        }
    }, [tab, introduces, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(introduces[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.div1} onClick={() => handleCategoryClick('about')}>
                            {t(`company:category:companyAbout`)}
                        </div>
                        <div className={styles.div2} onClick={() => handleCategoryClick('organization')}>
                            {introduces.organization}
                        </div>
                        <div className={styles.div3} onClick={() => handleCategoryClick('history')}>
                            {introduces.history}
                        </div>
                        <div className={styles.div4} onClick={() => handleCategoryClick('vision')}>
                            {introduces.vision}
                        </div>

                        <b className={styles.bi}>{t(`company:about:logo:vdigmBI`)}</b>
                        <div className={styles.slogan}>{t(`company:about:slogan:slogan`)}</div>
                        <div className={styles.aiContainer}>
                            <p className={styles.ai}>{t(`company:about:Introduce0`)}</p>
                            <p className={styles.p}>{t(`company:about:Introduce1`)}</p>
                        </div>

                        <div className={styles.visualAiVirtualContainer}>
                            <p className={styles.visualAiVirtual}>{t(`company:about:logo:description0`)}</p>
                            <p className={styles.visualAiVirtual}>{t(`company:about:logo:description1`)}</p>
                            <p className={styles.visualAiVirtual}>{t(`company:about:logo:description2`)}</p>
                            <p className={styles.p}>{t(`company:about:logo:description3`)}</p>
                        </div>

                        {/* <img className={styles.biIcon} alt="" src="icons/vdigmLogoMark.png" />
                        <img className={styles.biIcon1} alt="" src="icons/vdigmlogowidth.png" />
                        <img className={styles.biIcon2} alt="" src="icons/vdigmlogoheight.png" /> */}

                        <img className={styles.groupItem} alt="" src="assets/mobile/company/aboutLogo.png" />

                        <img className={styles.icon2} alt="" src="icons/sloganMark.png" />
                        <div className={styles.div8}>
                            <div className={styles.inner1} />
                            <div className={styles.inner2} />
                            <div className={styles.div91}>{t(`company:about:slogan:description0`)}</div>
                            <div className={styles.div92}>{t(`company:about:slogan:description1`)}</div>
                        </div>

                    </div>
                </div>
            ) : (
                <div className={stylesEn.aboutUs}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.aboutUs1} onClick={() => handleCategoryClick('about')}>
                            {t(`company:category:companyAbout`)}
                        </div>
                        <div className={stylesEn.organizationChart} onClick={() => handleCategoryClick('organization')}>
                            {t(`company:category:organization`)}
                        </div>
                        <div className={stylesEn.history} onClick={() => handleCategoryClick('history')}>
                            {t(`company:category:history`)}
                        </div>
                        <div className={stylesEn.vision} onClick={() => handleCategoryClick('vision')}>
                            {t(`company:category:vision`)}
                        </div>
                        <div className={stylesEn.vdigmBi}>Vdigm BI</div>
                        <div className={stylesEn.slogan}>SLOGAN</div>
                        <b className={stylesEn.shapingTheFutureContainer}>
                            <p className={stylesEn.shapingTheFuture}>Shaping the Future of Avatar</p>
                            <p className={stylesEn.experiencesWithGenerative}>Experiences with Generative AI</p>
                        </b>
                        <div className={stylesEn.theHexagonRepresentsContainer}>
                            <p className={stylesEn.theHexagonRepresents}>The hexagon represents two distinct virtual worlds,</p>
                            <p className={stylesEn.theHexagonRepresents}>while the central inverted triangle symbolizes the "V" in</p>
                            <p className={stylesEn.theHexagonRepresents}>Visual AI & Virtual World. Additionally, the truncated</p>
                            <p className={stylesEn.theHexagonRepresents}>surface signifies a paradigm shift, a radical</p>
                            <p className={stylesEn.theHexagonRepresents}>transformation resulting from the collision of these two</p>
                            <p className={stylesEn.experiencesWithGenerative}>virtual worlds.</p>
                        </div>
                        <img className={stylesEn.icon2} alt="" src="icons/sloganMark.png" />
                        <img className={stylesEn.groupItem} alt="" src="assets/mobile/company/aboutLogo.png" />

                        <div className={stylesEn.div2}>
                            <div className={stylesEn.inner} />
                            <div className={stylesEn.rectangleDiv} />
                            <img className={stylesEn.sloganArticle} alt="" src="icons/slogan.png" />
                            {/* <div className={stylesEn.stepIntoThe}>Step into the world of AI Avatars,</div>
          					<div className={stylesEn.whereYourImagination}>where your imagination becomes reality.</div> */}
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default About;
