import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './IdealTalent.module.css';
import stylesEn from './IdealTalentEn.module.css';
import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcedure: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

const IdealTalent: FunctionComponent<Props> = (props) => {
    //const {introduces, selectedCategory} = props;
    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    // const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcedure':
                    setSelectedCategory(recruits.recruitProcedure);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.div3} onClick={() => handleCategoryClick('idealTalent')}>
                            인재상
                        </div>
                        <div className={styles.div4} onClick={() => handleCategoryClick('welfareBenefit')}>
                            복리후생
                        </div>
                        <div className={styles.div5} onClick={() => handleCategoryClick('recruitProcedure')}>
                            채용절차
                        </div>

                        <div className={styles.div1}>
                            <p className={styles.p}>브이다임은</p>
                            <p className={styles.p1}>언제나 열려있습니다.</p>
                        </div>
                        <div className={styles.div2}>
                            <p className={styles.p2}>브이다임은 지속적으로 성장하고자 하는 기업으로 열정이</p>
                            <p className={styles.p2}>{`있는 분이라면 함께하고자 합니다. 같이 성장하고자 하는 `}</p>
                            <p className={styles.p1}>인재를 언제나 환영합니다.</p>
                        </div>
                        <div className={styles.div6}>늘 열린마음으로 함께 합니다.</div>
                        <div className={styles.div7}>브이다임이 추구하는 태도</div>
                        <div className={styles.div8}>
                            <p className={styles.p2}>열정을 갖고 방향성을 함께 공유하고 고민하며, 노력하고</p>
                            <p className={styles.p2}>맡은 일에 책임을 다하는 인재, 새로운 것에 자신있게 도전하는</p>
                            <p className={styles.p1}>인재를 모십니다</p>
                        </div>
                        <div className={styles.div9}>
                            <div className={styles.child} />
                            <img className={styles.talk76478631280Icon} alt="" src="assets/idealTalent.png" />
                        </div>
                        <div className={styles.div10}>
                            <div className={styles.div11}>성실</div>
                            <img className={styles.item} alt="" src="icons/attribute1.png" />
                        </div>
                        <div className={styles.div12}>
                            <div className={styles.div13}>소통</div>
                            <img className={styles.item} alt="" src="icons/attribute2.png" />
                        </div>
                        <div className={styles.div14}>
                            <div className={styles.div15}>도전</div>
                            <img className={styles.item} alt="" src="icons/attribute3.png" />
                        </div>

                    </div>
                </div>
            ) : (

                <div className={stylesEn.people}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.people1} onClick={() => handleCategoryClick('idealTalent')}>
                            People
                        </div>
                        <div className={stylesEn.benefits} onClick={() => handleCategoryClick('welfareBenefit')}>
                            Benefit
                        </div>
                        <div className={stylesEn.recruitmentProcess} onClick={() => handleCategoryClick('recruitProcedure')}>
                            Recruitment Process
                        </div>

                        <div className={stylesEn.vdigmsSoughtAfterAttitudes}>Vdigm's sought-after attitudes</div>
                        <b className={stylesEn.vdigmIsAlwaysContainer}>
                            <p className={stylesEn.vdigmIs}>Vdigm is</p>
                            <p className={stylesEn.alwaysOpen}>always open.</p>
                        </b>

                        <div className={stylesEn.weAreAlways}>We are always here with an open mind</div>
                        <div className={stylesEn.vdigmIsAContainer}>
                            <p className={stylesEn.vdigmIsA}>{`Vdigm is a company that is always striving for growth. `}</p>
                            <p className={stylesEn.vdigmIsA}>We are passionate about what we do, and we are looking</p>
                            <p className={stylesEn.vdigmIsA}>{`for people who share that passion. If you are a talented `}</p>
                            <p className={stylesEn.vdigmIsA}>{`individual who is looking to grow with a company, `}</p>
                            <p className={stylesEn.alwaysOpen}>we encourage you to join us.</p>
                        </div>
                        <div className={stylesEn.weAreSeekingContainer}>
                            <p className={stylesEn.vdigmIsA}>{`We are seeking passionate individuals who are eager to `}</p>
                            <p className={stylesEn.vdigmIsA}>{`share ideas, collaborate, work hard, take responsibility `}</p>
                            <p className={stylesEn.alwaysOpen}>for their work, and confidently take on new challenges.</p>
                        </div>
                        <div className={stylesEn.div}>
                            <div className={stylesEn.child} />
                            <img className={stylesEn.talk76478631280Icon} alt="" src="assets/idealTalent.png" />
                        </div>
                        <img className={stylesEn.ellipseIcon} alt="" src="icons/attribute1.png" />
                        <img className={stylesEn.groupChild2} alt="" src="icons/attribute2.png" />
                        <img className={stylesEn.groupChild3} alt="" src="icons/attribute3.png" />
                        <a className={stylesEn.sincerity} href="https://en.dict.naver.com/#/entry/enko/2bbde0178ec141baa3d287f67e1f409f" target="_blank">Sincerity</a>
                        <div className={stylesEn.communication}>Communication</div>
                        <div className={stylesEn.challenge}>Challenge</div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default IdealTalent;