import { FunctionComponent } from 'react';
import styles from './Tih.module.css';
import stylesEn from './TihEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react'


const Tih: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.tih}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />
                        <img className={styles.icon} alt="" src="icons/globalArrow.png" />
                        <img className={styles.icon1} alt="" src="assets/tih2025.png" />
                        <img className={styles.icon2} alt="" src="assets/tih2026.png" />
                        <img className={styles.icon3} alt="" src="assets/tih2027.png" />

                        <div className={styles.tihtomorrowInHere}>TIH(Tomorrow, In Here)</div>
                        <div className={styles.div}>
                            <p className={styles.p}>버추얼 스트리머와 팬덤을 위한 서비스</p>
                            <p className={styles.k}>내일, 여기서 만나</p>
                        </div>
                        <div className={styles.tomorrowInHere}>Tomorrow, In Here</div>
                        <b className={styles.tih1}>2025 TIH 서비스 런칭</b>
                        <b className={styles.tihAiNpc}>2027 TIH AI NPC 플랫폼 런칭</b>
                        <b className={styles.stage}>2026 버추얼 스트리머, Stage 런칭</b>
                        <div className={styles.tih2}>2027년 글로벌 TIH</div>
                        <div className={styles.d3dContainer}>
                            <p className={styles.d3d}>2D로 3D 실감 아바타가 생성 가능한 브이다임만의 기술.</p>
                            <p className={styles.d3d}>{`AI 모델과 AI 학습용 데이터를 지속적으로 업데이트 하여 `}</p>
                            <p className={styles.k}>개인의 선호하는 스타일을 반영하여 보정</p>
                        </div>
                        <div className={styles.kContainer}>
                            <p className={styles.aiNpc}>북미, 유럽, 동남아를 대상으로 서비스 런칭,</p>
                            <p className={styles.k}>{`새로운 K-버추얼 스트리머 문화 전파 `}</p>
                        </div>
                        <div className={styles.tihContainer}>
                            <p className={styles.aiNpc}>TIH 테마 플레이스에 패션, 교육, 헬스 서비스 등</p>
                            <p className={styles.aiNpc}>입점 예정, AI NPC 플랫폼을 이용해 AI NPC를</p>
                            <p className={styles.k}>사용할 수 있도록 제공</p>
                        </div>
                        <div className={styles.personaContainer}>
                            <p className={styles.aiNpc}>다양한 Persona를 가진 버추얼 스트리머 그룹,</p>
                            <p className={styles.k}>스트리머/팬덤을 위한 Virtual Stage 런칭</p>
                        </div>
                        <b className={styles.b}><a className={styles.deleteLinkStyle} rel="stylesheet" href="https://tih.ai/" target="_blank">바로가기</a></b>

                        <img className={styles.tihImageIcon} alt="" src="assets/tih.png" />
                    </div>
                </div>
            ) : (

                <div className={stylesEn.tih}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />
                        
                        <div className={stylesEn.tihtomorrowInHere}>TIH(Tomorrow In Here)</div>
                        <div className={stylesEn.businessStrategyFor}>Business Strategy for the Next Three Years</div>
                        <b className={stylesEn.virtualStreamerAndContainer}>
                            <p className={stylesEn.virtualStreamerAnd}>Virtual Streamer and Fandom Services</p>
                            <p className={stylesEn.tomorrowInHere}>Tomorrow, In Here</p>
                        </b>
                        
                        <div className={stylesEn.tomorrowInHere1}>Tomorrow, In Here</div>
                        <div className={stylesEn.joinUsTomorrowContainer}>
                            <p className={stylesEn.joinUsTomorrow}>{`Join us tomorrow in this virtual space to connect, `}</p>
                            <p className={stylesEn.joinUsTomorrow}>stream, and have fun with your avatars!</p>
                            <p className={stylesEn.joinUsTomorrow}>{`This is powered by AI Avatar Service Powered by `}</p>
                            <p className={stylesEn.joinUsTomorrow}>Generative AI Technology.</p>
                            <p className={stylesEn.joinUsTomorrow}>{`Virtual YouTuber/Streamer Broadcasting Media Service `}</p>
                            <p className={stylesEn.joinUsTomorrow}>{`with Fandom Creation and AI NPC-Powered `}</p>
                            <p className={stylesEn.tomorrowInHere}>Conversational Interaction Service</p>
                        </div>
                        <img className={stylesEn.tihImageIcon} alt="" src="assets/tih.png" />
                        <div className={stylesEn.website}><a className={styles.deleteLinkStyle} rel="stylesheet" href="https://tih.ai/" target="_blank">Website</a></div>
                        <img className={stylesEn.icon4} alt="" src="icons/globalArrowEn.png" />
                        <img className={stylesEn.icon5} alt="" src="assets/tih2025.png" />
                        <img className={stylesEn.icon6} alt="" src="assets/tih2026.png" />
                        <img className={stylesEn.icon7} alt="" src="assets/tih2027.png" />
                        <div className={stylesEn.tihServiceTo}>TIH Service to Launch in 2025</div>
                        <div className={stylesEn.tihAiNpcContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`TIH AI NPC Thema Place `}</p>
                            <p className={stylesEn.tomorrowInHere}>Launch in 2027</p>
                        </div>
                        <div className={stylesEn.aiVirtualStreamerContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>AI Virtual Streamer Stage</p>
                            <p className={stylesEn.tomorrowInHere}>to Launch in 2026</p>
                        </div>
                        <div className={stylesEn.expandingToNorthContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`Expanding to North America, Europe, `}</p>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`and Southeast Asia to Promote K-Virtual `}</p>
                            <p className={stylesEn.tomorrowInHere}>Streamer Culture</p>
                        </div>
                        <div className={stylesEn.fashionEducationAndContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`Fashion, Education, and Health Services to `}</p>
                            <p className={stylesEn.headOffice10f1012293Bu}>{`Enter TIH Themed Places, Empowering `}</p>
                            <p className={stylesEn.tomorrowInHere}>Businesses with AI NPC Platforms</p>
                        </div>
                        <div className={stylesEn.aGroupOfContainer}>
                            <p className={stylesEn.headOffice10f1012293Bu}>A group of AI virtual streamers with diverse</p>
                            <p className={stylesEn.headOffice10f1012293Bu}>personas launches a large-scale Virtual Stage</p>
                            <p className={stylesEn.tomorrowInHere}> for streamers and fandoms</p>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default Tih;
