import { FunctionComponent, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AiAvatar from './AIAvatar.tsx';
import DeepReal from './DeepReal.tsx';
import DVirtualStage from './DVirtualStage.tsx';

import React from 'react';

const Solution: FunctionComponent = () => {

const [solutions, setSolutions] = useState({
  aiAvatar: "AI Avatar",
  deepReal: "Deep Real",
  dVirtualStage: "3D Virtual Stage",
});

type SolutionType = (typeof solutions)[keyof typeof solutions];

const [searchParams, setSearchParams] = useSearchParams();
const tab = searchParams.get("tab");
const [selectedCategory, setSelectedCategory] = useState<SolutionType>(solutions.aiAvatar);

useEffect(() => {
  if (tab) {
      switch (tab) {
          case 'aiAvatar':
              setSelectedCategory(solutions.aiAvatar);
              break;
          case 'deepReal':
              setSelectedCategory(solutions.deepReal);
              break;
          case 'dVirtualStage':
              setSelectedCategory(solutions.dVirtualStage);
              break;
      }
  }
}, [tab, solutions]);
      return (
        <div>

            {selectedCategory === solutions.aiAvatar && (
                <AiAvatar solutions={solutions} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === solutions.deepReal && (
                <DeepReal solutions={solutions} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === solutions.dVirtualStage && (
                <DVirtualStage solutions={solutions} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}

        </div>
    )
};

export default Solution;