import { FunctionComponent, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import IdealTalent from './IdealTalent.tsx';
import WelfareBenefit from './WelfareBenefit.tsx';
import RecruitProcedure from './RecruitProcedure.tsx';

import React from 'react';

const Recruit: FunctionComponent = () => {

    const [recruits, setRecruits] = useState({
        idealTalent: "인재상",
        welfareBenefit: "복리후생",
        recruitProcedure: "채용절차",
    });

    type RecruitsType = (typeof recruits)[keyof typeof recruits];

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [selectedCategory, setSelectedCategory] = useState<RecruitsType>(recruits.idealTalent);
    
    // const handleCategoryChange = (event) => {
    //     setSearchParams({ category: event.target.value });
    // }

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcedure':
                    setSelectedCategory(recruits.recruitProcedure);
                    break;
            }
        }
    }, [tab, recruits]);

return (
    <div>
        
        {selectedCategory === recruits.idealTalent && (
                <IdealTalent recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )} 
            {selectedCategory === recruits.welfareBenefit && (
                <WelfareBenefit recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            {selectedCategory === recruits.recruitProcedure && (
                <RecruitProcedure recruits={recruits} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
            )}
            
    </div>
)

    // switch (selectedCategory) {
    //     case recruits.idealTalent :
    //         return <IdealTalent recruits={recruits} setSelectedCategory={setSelectedCategory} />;

    //     case recruits.welfareBenefit :
    //         return <WelfareBenefit recruits={recruits} setSelectedCategory={setSelectedCategory} />;

    //     case recruits.recruitProcedure :
    //         return <RecruitProcedure recruits={recruits} setSelectedCategory={setSelectedCategory} />;

    //     // default :
    //     //     return <AIAvatar solutions={solutions} setSelectedCategory={setSelectedCategory}/>;
    // }
};

export default Recruit;