import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './WelfareBenefit.module.css';
import stylesEn from './WelfareBenefitEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcess: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

const WelfareBenefit: FunctionComponent<Props> = (props) => {
    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcess':
                    setSelectedCategory(recruits.recruitProcess);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.div3}>
                            <p className={styles.p}>브이다임은</p>
                            <p className={styles.p}>직원들을 생각합니다</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.category}>
                            <div className={styles.b4} onClick={() => handleCategoryClick('idealTalent')}>
                                인재상
                            </div>
                            <div className={styles.div1} onClick={() => handleCategoryClick('welfareBenefit')}>
                                복리후생
                            </div>
                            <div className={styles.b} onClick={() => handleCategoryClick('recruitProcess')}>
                                채용절차
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>

                        {/* <div className={styles.div1}>복리후생</div>
        				<b className={styles.b}>채용절차</b> */}

                        {/* <b className={styles.b4}>인재상</b> */}
                        <div className={styles.groupChild1} />
                        <div className={styles.groupChild2} />
                        <div className={styles.groupChild3} />
                        <div className={styles.groupChild4} />
                        <div className={styles.groupChild5} />
                        <div className={styles.groupChild6} />
                        <div className={styles.groupChild7} />
                        <div className={styles.groupChild8} />
                        <div className={styles.groupChild9} />
                        <div className={styles.groupChild10} />
                        <div className={styles.div7}>
                            <p className={styles.p2}>당연히</p>
                            <p className={styles.p}>챙겨야죠!</p>
                        </div>
                        <div className={styles.div8}>
                            <p className={styles.p2}>점심식사</p>
                            <p className={styles.p}>걱정마세요!</p>
                        </div>
                        <div className={styles.div9}>
                            <p className={styles.p2}>명절에는</p>
                            <p className={styles.p}>양손을 무겁게!</p>
                        </div>
                        <div className={styles.div10}>요즘은 개성시대!</div>
                        <div className={styles.div11}>
                            <p className={styles.p2}>건강은 회사가</p>
                            <p className={styles.p}>책임질게요!</p>
                        </div>
                        <div className={styles.div12}>눈치보지 마세요!</div>
                        <div className={styles.div13}>
                            <p className={styles.p2}>기쁘고 슬픈일</p>
                            <p className={styles.p}>회사가 함께해요</p>
                        </div>
                        <div className={styles.div14}>
                            <p className={styles.p2}>출출할 땐</p>
                            <p className={styles.p}>간식먹으러!</p>
                        </div>
                        <div className={styles.div15}>
                            <p className={styles.p2}>강요 없는</p>
                            <p className={styles.p}>회식 문화</p>
                        </div>
                        <div className={styles.div16}>
                            <p className={styles.p2}>근로자의 날에는</p>
                            <p className={styles.p}>휴식을!</p>
                        </div>
                        <div className={styles.div17}>4대 보험</div>
                        <div className={styles.div18}>점심식사 제공</div>
                        <div className={styles.div19}>명절선물</div>
                        <div className={styles.div20}>자율복장</div>
                        <div className={styles.div21}>건강검진</div>
                        <div className={styles.div22}>반차, 연차 사용</div>
                        <div className={styles.div23}>경조사 휴가</div>
                        <div className={styles.div24}>커피/음료, 간식 제공</div>
                        <div className={styles.div25}>근무시간 내 회식</div>
                        <div className={styles.div26}>근로자의 날 휴무</div>
                        <img className={styles.maskGroupIcon} alt="" src="assets/pc/recruit/insurance.png" />
                        <img className={styles.maskGroupIcon1} alt="" src="assets/pc/recruit/diningTogether.png" />
                        <img className={styles.maskGroupIcon2} alt="" src="assets/pc/recruit/vacation.png" />
                        <img className={styles.maskGroupIcon3} alt="" src="assets/pc/recruit/checkUp.png" />
                        <img className={styles.maskGroupIcon4} alt="" src="assets/pc/recruit/snack.png" />
                        <img className={styles.maskGroupIcon5} alt="" src="assets/pc/recruit/meals.png" />
                        <img className={styles.maskGroupIcon6} alt="" src="assets/pc/recruit/holiday.png" />
                        <img className={styles.maskGroupIcon7} alt="" src="assets/pc/recruit/familyEvent.png" />
                        <img className={styles.maskGroupIcon8} alt="" src="assets/pc/recruit/selfDressing.png" />
                        <img className={styles.maskGroupIcon9} alt="" src="assets/pc/recruit/laborDay.png" />

                    </div>
                </div>
            ) : (
                <div className={stylesEn.benefits}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.vdigmCaresAboutContainer}>
                            <p className={stylesEn.vdigmCaresAbout}>Vdigm cares about</p>
                            <p className={stylesEn.vdigmCaresAbout}>its employees</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.people} onClick={() => handleCategoryClick('idealTalent')}>
                                People
                            </div>
                            <div className={stylesEn.benefits1} onClick={() => handleCategoryClick('welfareBenefit')}>
                                Benefits
                            </div>
                            <div className={stylesEn.recruitmentProcess} onClick={() => handleCategoryClick('recruitProcess')}>
                                Recruitment Process
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <b className={styles.benefits1}>Benefits</b>
                        <div className={styles.recruitmentProcess}>Recruitment Process</div> */}
                        {/* <div className={styles.people}>People</div> */}

                        <div className={stylesEn.groupChild1} />
                        <div className={stylesEn.groupChild2} />
                        <div className={stylesEn.groupChild3} />
                        <div className={stylesEn.groupChild4} />
                        <div className={stylesEn.groupChild5} />
                        <div className={stylesEn.groupChild6} />
                        <div className={stylesEn.groupChild7} />
                        <div className={stylesEn.groupChild8} />
                        <div className={stylesEn.groupChild9} />
                        <div className={stylesEn.groupChild10} />
                        <div className={stylesEn.ofCourseWeContainer}>
                            <p className={stylesEn.ofCourse}>Of Course</p>
                            <p className={stylesEn.vdigmCaresAbout}>We offer!</p>
                        </div>
                        <div className={stylesEn.lunchIsOn}>Lunch is on us!</div>
                        <div className={stylesEn.holidayCheerByContainer}>
                            <p className={stylesEn.ofCourse}>Holiday cheer by</p>
                            <p className={stylesEn.vdigmCaresAbout}>giving generously</p>
                        </div>
                        <div className={stylesEn.embraceYourIndividualityContainer}>
                            <p className={stylesEn.ofCourse}>Embrace</p>
                            <p className={stylesEn.vdigmCaresAbout}>your individuality!</p>
                        </div>
                        <div className={stylesEn.yourHealthOurContainer}>
                            <p className={stylesEn.ofCourse}>Your health,</p>
                            <p className={stylesEn.vdigmCaresAbout}>Our priority</p>
                        </div>
                        <div className={stylesEn.dontCareAboutContainer}>
                            <p className={stylesEn.ofCourse}>Don’t care</p>
                            <p className={stylesEn.vdigmCaresAbout}>about others</p>
                        </div>
                        <div className={stylesEn.weShareYourContainer}>
                            <p className={stylesEn.ofCourse}>We share your</p>
                            <p className={stylesEn.vdigmCaresAbout}>joys and sorrow</p>
                        </div>
                        <div className={stylesEn.enjoyDrinksAndContainer}>
                            <p className={stylesEn.ofCourse}>Enjoy drinks,</p>
                            <p className={stylesEn.vdigmCaresAbout}>and snacks</p>
                        </div>
                        <div className={stylesEn.celebrateLaborDayContainer}>
                            <p className={stylesEn.ofCourse}>Celebrate</p>
                            <p className={stylesEn.vdigmCaresAbout}>labor day!</p>
                        </div>
                        <div className={stylesEn.majorInsyrances}>4 Major Insyrances</div>
                        <div className={stylesEn.weOfferLunch}>We offer lunch</div>
                        <div className={stylesEn.holidayGift}>Holiday gift</div>
                        <div className={stylesEn.wearWhateverYou}>Wear whatever you want</div>
                        <div className={stylesEn.medicalCheckup}>Medical Checkup</div>
                        <div className={stylesEn.takeAHalf}>Take a (half) day off</div>
                        <div className={stylesEn.bereavementLeave}>Bereavement leave</div>
                        <div className={stylesEn.freeDrinks}>{`Free drinks & snacks`}</div>
                        <div className={stylesEn.duringWorkHours}>During work hours</div>
                        <div className={stylesEn.laborDayOff}>Labor day off</div>
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/recruit/insurance.png" />
                        <img className={stylesEn.maskGroupIcon1} alt="" src="assets/pc/recruit/diningTogether.png" />
                        <img className={stylesEn.maskGroupIcon2} alt="" src="assets/pc/recruit/vacation.png" />
                        <img className={stylesEn.maskGroupIcon3} alt="" src="assets/pc/recruit/checkUp.png" />
                        <img className={stylesEn.maskGroupIcon4} alt="" src="assets/pc/recruit/snack.png" />
                        <img className={stylesEn.maskGroupIcon5} alt="" src="assets/pc/recruit/meals.png" />
                        <img className={stylesEn.maskGroupIcon6} alt="" src="assets/pc/recruit/holiday.png" />
                        <img className={stylesEn.maskGroupIcon7} alt="" src="assets/pc/recruit/familyEvent.png" />
                        <img className={stylesEn.maskGroupIcon8} alt="" src="assets/pc/recruit/selfDressing.png" />
                        <img className={stylesEn.maskGroupIcon9} alt="" src="assets/pc/recruit/laborDay.png" />
                        <div className={stylesEn.anUnforcedDiningContainer}>
                            <p className={stylesEn.ofCourse}>An unforced</p>
                            <p className={stylesEn.vdigmCaresAbout}>dining culture</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WelfareBenefit;
