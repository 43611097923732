import { FunctionComponent, useEffect, useRef } from 'react';
import React from 'react'

const NaverMapLab: FunctionComponent = () => {
  const mapElement = useRef(null);
  const { naver } = window;

  useEffect(() => {
    if (!mapElement.current || !naver) return;

    // 표시할 위치의 위도/경도
    const location = new naver.maps.LatLng(37.465918, 127.035914);
    const mapOptions = {
      center: location,
      zoom: 17,
      zoomControl: true,
    };

    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  
    const size = new naver.maps.Size(400, 400);
  }, []);   

  return (
    <>
      {/* <h1>Naver Map - Default</h1> */}
      <div ref={mapElement} style={{ height: '800px', width: '1180px'}} />
    </>
  );
};

export default NaverMapLab;