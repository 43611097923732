import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Main.module.css';
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Header from "./Header.tsx";
import axios from 'axios';

import { Autoplay, FreeMode, Pagination, Scrollbar } from 'swiper/modules';
import i18n from "../locales/i18n";
import { response } from 'express';
import LeakDetector from 'jest-leak-detector';

// const mainImages: Array<MainImages> = [
//     {
//         mainSrc: "assets/main1.png",
//         mainSeqSrc: "icons/mainSeq1.png",
//     },
//     {
//         mainSrc: "assets/main2.png",
//         mainSeqSrc: "icons/mainSeq2.png",
//     },
//     {
//         mainSrc: "assets/main3.png",
//         mainSeqSrc: "icons/mainSeq3.png",
//     },
// ]

const Main: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    // const [mainImagesArr, setMainImagesArr] = useState<string[]>([]);
    const [mainImage, setMainImage] = useState("");

    const [mainImagesArr, setMainImagesArr] = useState<string[]>([
        "assets/mobile/main/main1.png",
        "assets/mobile/main/main2.png",
        "assets/mobile/main/main3.png"
    ]);



    // const fetchMainImages = async () => {
    //     const response = await fetch('/');
    //     const data = await response.json();

    //     const transformedMainImages = data.results.map((mainImageData: string)=>{
    //         return{
    //             id: mainImageData.episode_id,
    //             src: mainImageData.src
    //         };
    //         });
    //     setMainImagesArr(transformedMainImages);
    // }

    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    //     const { value } = e.target;
    //     setMainImagesArr(prevMainImagesArr => {
    //         const updatedArr = [...prevMainImagesArr];
    //         updatedArr[index] = value;
    //         return updatedArr;
    //     });
    // };

    // const handleOnSubmit = async (e: any) => {
    //     e.preventDefault();
    //     try {
    //         const response = await fetch('http://localhost:8080/api/submit', {
    //             method: "POST",
    //             body: JSON.stringify({ mainImagesArr }),
    //             headers: {
    //                 'Content-Type': 'application/json; charset=utf-8'
    //             }
    //         });
    //         const result = await response.json();
    //         console.warn(result);
    //         if (result) {
    //             alert("Data saved successfully");
    //             setMainImagesArr(prevMainImagesArr => [...prevMainImagesArr, ""]);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         // Handle error here, e.g., show error message to the user
    //     }
    // };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(
            'http://vdigm.com/api/mainMPost', {
            method: "post",
            body: JSON.stringify({ src: mainImage }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
        const result = await response.json();
        console.warn(result);
        if (result) {
            alert("Data saved succesfully");
            setMainImagesArr(prevImages => [...prevImages, result.src]);
            setMainImage("");
        };
    };

    useEffect(() => {
        const fetchMainImages = async () => {
            try {
                const response = await axios.get('https://vdigm.com/api/mainImagesM');
                const imageData = response.data;
                setMainImagesArr(imageData.map((item: any) => item.src));
                // setMainImagesArr(imageData);

            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchMainImages(); // 이미지 데이터 가져오기 호출
    }, []);

    const addMainImage = () => {
        const newMainImage = "assets/main1.png";
        const newMainImage1 = mainImagesArr;


        // let copyMainImagesArr = [...mainImagesArr];
        // copyMainImagesArr[0] = newMainImage;
        // setMainImagesArr(copyMainImagesArr);
        setMainImagesArr((prevImages) => [...prevImages, mainImage]);

        setMainImage(""); // 입력 필드 초기화
    };

    const deleteMainImage = () => {
        setMainImagesArr(mainImagesArr.filter(mainImage => mainImage != ""))
    }

    return (
        <div>
            {/* // <div className={styles.aa}>

        // <div className={styles.aaaa}>
        // <div className={styles.container}>
        //     <div className={styles.home}>
        //         <div className={styles.rectangleParent}> */}
            {/* <div className={styles.groupChild} /> */}
            {/* <div className={styles.manager}>
            <form onSubmit={handleOnSubmit}>
                <input
                    type="text"
                    placeholder="src"
                    value={mainImage}
                    onChange={(e) => setMainImage(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
        </div> */}
            {/* <div className={styles.manager}>
                <form onSubmit={handleOnSubmit}>
                    <input
                        type="text"
                        placeholder="src"
                        value={mainImage}
                        onChange={(e) => setMainImage(e.target.value)}
                    />
                    <button type="submit">Submit</button>
                </form>
                <button onClick={addMainImage}>Add Image</button>
            </div> */}
            <div className={styles.mainSwiper}>
                <Swiper
                    modules={[Pagination, Autoplay]}
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    autoplay={{ delay: 3000, disableOnInteraction: false, stopOnLastSlide: false }}
                    freeMode={false}
                    pagination={{ clickable: true }}
                    loop={true}
                    className={styles.icon2}
                >
                    {mainImagesArr.map((src, index) => (
                        <SwiperSlide key={index}>
                            <img className={styles.swiperMain} alt={`Slide ${index}`} src={src} />
                        </SwiperSlide>
                    ))}
                    {/* <SwiperSlide>
                    <img className={styles.swiperMain} alt="" src={mainImagesArr[0]} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className={styles.swiperMain} alt="" src={mainImagesArr[1]} />
                </SwiperSlide>
                <SwiperSlide>
                    <img className={styles.swiperMain} alt="" src={mainImagesArr[2]} />
                </SwiperSlide> */}
                </Swiper>

                {currentLanguage === 'ko' ? (
                    <b className={styles.aiContainerKo}>
                        <p className={styles.ai}>{t(`home:mainTitle0`)}</p>
                        <p className={styles.ai}>{t(`home:mainTitle1`)}</p>
                        <p className={styles.ai}>{t(`home:mainTitle2`)}</p>
                    </b>
                ) : (
                    <b className={styles.aiContainerEn}>
                        <p className={styles.ai}>{t(`home:mainTitle0`)}</p>
                        <p className={styles.ai}>{t(`home:mainTitle1`)}</p>
                        <p className={styles.ai}>{t(`home:mainTitle2`)}</p>
                    </b>
                )
                }
            </div>
            {/* <img className={styles.icon2} alt="" src={mainImages[0].mainSrc} /> */}


            {/* <img className={styles.icon4} alt="" src={mainImages[0].mainSeqSrc} /> */}
        </div>
        //          {/* </div>
        //      </div>
        // </div>
        // </div> */}
    );
};

export default Main;

