import { FunctionComponent } from 'react';
import { useTranslation } from "react-i18next";

import styles from './Footer.module.css';
import stylesEn from './FooterEn.module.css';

import React from 'react';

interface Logo {
    pngSrc: string,
    url: string,
}

const logo: Array<Logo> = [
    {
        pngSrc: "icons/youtubeLogo.png",
        url: "https://www.youtube.com/@vdigm",
    },
    {
        pngSrc: "icons/insiteLogo.png",
        url: "https://www.linkedin.com/company/vdigm/",
    },
    {
        pngSrc: "icons/tihLogo.png",
        url: "https://tih.ai",
    },
]

const Footer: FunctionComponent = () => {
    console.log('Footer');
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div>
            {currentLanguage === 'ko' ? (
        <div className={styles.footer}>
            {/* <div className={styles.home}> */}
                <img className={styles.groupItem} alt="" src="icons/footerLine.png" />
            <div className={styles.rectangleParent}>
                <div className={styles.div3}>{t(`home:footer:companyName`)}</div>
                <b className={styles.b5}>
                    {t(`home:footer:companyHead`)}{t(`home:footer:companyHeadAddress0`)}
                </b>
                <b className={styles.rdCenter}>
                    {t(`home:footer:companyR&D`)}{t(`home:footer:companyR&DAddress0`)}
                </b>
                <b className={styles.c304}>{t(`home:footer:companyR&DAddress1`)}</b>
                <div className={styles.tel82322771700}>{t(`home:footer:companyTel`)}</div>
                <div className={styles.mailBizvdigmcom}>{t(`home:footer:companyMail`)}</div>
                {/* <img className={styles.icon6} alt="" src="icons/toTop.png" /> */}
                <div className={styles.div4}>
                    <div className={styles.child} />
                    <a href={logo[0].url} target="_blank">
                        <img className={styles.instagram328841319204Icon} alt="" src={logo[0].pngSrc} />
                    </a>
                </div>
                <div className={styles.div5}>
                    <div className={styles.item} />
                    <a href={logo[1].url} target="_blank">
                        <img className={styles.instagram328841319203Icon} alt="" src={logo[1].pngSrc} />
                    </a>
                </div>
                <div className={styles.tihLogo}>
                    <a href={logo[2].url} target="_blank">
                        <div className={styles.tihLogoBackground} />
                        <img className={styles.tihLogoMask} alt="" src={logo[2].pngSrc} />
                    </a>
                </div>
            </div>
            {/* </div> */}
        </div>
        ) : (
<div className={stylesEn.footer}>
            {/* <div className={styles.home}> */}
                <img className={stylesEn.groupItem} alt="" src="icons/footerLine.png" />
            <div className={stylesEn.rectangleParent}>
                <div className={stylesEn.div3}>{t(`home:footer:companyName`)}</div>
                <b className={stylesEn.b5}>
                    {t(`home:footer:companyHead`)}{t(`home:footer:companyHeadAddress0`)}
                </b>
                <b className={stylesEn.rdCenter}>
                    {t(`home:footer:companyR&D`)}{t(`home:footer:companyR&DAddress0`)}
                </b>
                <b className={stylesEn.c304}>{t(`home:footer:companyR&DAddress1`)}</b>
                <div className={stylesEn.tel82322771700}>{t(`home:footer:companyTel`)}</div>
                <div className={stylesEn.mailBizvdigmcom}>{t(`home:footer:companyMail`)}</div>
                {/* <img className={styles.icon6} alt="" src="icons/toTop.png" /> */}
                <div className={stylesEn.div4}>
                    <div className={stylesEn.child} /> 
                    <a href={logo[0].url} target="_blank">
                        <img className={stylesEn.instagram328841319204Icon} alt="" src={logo[0].pngSrc} />
                    </a>
                </div>
                <div className={stylesEn.div5}>
                    <div className={stylesEn.item} />
                    <a href={logo[1].url} target="_blank">
                        <img className={stylesEn.instagram328841319203Icon} alt="" src={logo[1].pngSrc} />
                    </a>
                </div>
                <div className={styles.tihLogo}>
                    <a href={logo[2].url} target="_blank">
                        <div className={styles.tihLogoBackground} />
                        <img className={styles.tihLogoMask} alt="" src={logo[2].pngSrc} />
                    </a>
                </div>
            </div>
            {/* </div> */}
        </div>
        )}
        </div>
    )
}

export default Footer;