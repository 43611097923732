import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './AiAvatar.module.css';
import stylesEn from './AiAvatarEn.module.css';

import { useTranslation } from "react-i18next";
import React from 'react';

interface Props {
    solutions: {
        aiAvatar: string;
        deepReal: string;
        dVirtualStage: string;
    };
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
    setSearchParams: SetURLSearchParams;
    tab: string | null;
}

const AiAvatar: FunctionComponent<Props> = ({ solutions, setSelectedCategory, tab, setSearchParams }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'about':
                    setSelectedCategory(solutions.aiAvatar);
                    break;
                case 'history':
                    setSelectedCategory(solutions.deepReal);
                    break;
                case 'organization':
                    setSelectedCategory(solutions.dVirtualStage);
                    break;
            }
        }
    }, [tab, solutions, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(solutions[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.aiAvatar}>
                    <div className={styles.rectangleParent1}>
                        <div className={styles.dContainer}>
                            <p className={styles.d}>3D 라이브 스트리밍을 이끌어 갈</p>
                            <p className={styles.d}>브이다임만의 기술</p>
                        </div>
                    </div>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />
                        <div className={styles.category}>
                            <div className={styles.aiAvatar1} onClick={() => handleCategoryClick('aiAvatar')}>
                                {solutions.aiAvatar}
                            </div>
                            <div className={styles.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                                {solutions.deepReal}
                            </div>
                            <div className={styles.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                {solutions.dVirtualStage}
                            </div>
                            <div className={styles.ellipseDiv} />
                        </div>
                        {/* <div className={styles.deepReal}>Deep Real</div>
        				<div className={styles.dVirtualStage}>3D Virtual Stage</div> */}

                        {/* <b className={styles.aiAvatar1}>AI Avatar</b> */}
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/solution/responsiveAiAvatar.png" />
                        <img className={stylesEn.maskGroupIcon1} alt="" src="assets/pc/solution/generativeAiAvatar.png" />
                        <img className={stylesEn.maskGroupIcon2} alt="" src="assets/pc/solution/aiAvatar.png" />
                        <div className={styles.generativeAiAvatarParent}>
                            <b className={styles.generativeAiAvatar}>Generative AI Avatar</b>
                            <b className={styles.aiPcContainer}>
                                <p className={styles.ai}>사용자의 스마트폰, 웹 캠 등을 이용해 1장의 사진으로 3D</p>
                                <p className={styles.ai}>{`아바타를 생성할 수 있으며, AI 모델과 학습용 데이터를 `}</p>
                                <p className={styles.d}>지속적으로 업데이트 중에 있음</p>
                            </b>
                        </div>
                        <div className={styles.responsiveAiAvatarParent}>
                            <b className={styles.generativeAiAvatar}>Responsive AI Avatar</b>
                            <b className={styles.aiPcContainer}>
                                <p className={styles.ai}>{`유저와 대화가 가능하고, 따라 움직이고, 자연스럽게 `}</p>
                                <p className={styles.ai}>{`반응하는 브이다임만의 모션 트래킹 AI 아바타로 `}</p>
                                <p className={styles.d}>PC / Mobile 에서 실시간 트래킹이 가능</p>
                            </b>
                        </div>
                        <div className={styles.aiAvatarNpcParent}>
                            <b className={styles.generativeAiAvatar}>AI Avatar NPC</b>
                            <b className={styles.aiPcContainer}>
                                <p className={styles.ai}>버추얼 스트리머를 위해 개발한 브이다임의 아바타 NPC로</p>
                                <p className={styles.ai}>{`스트리머가 오프라인 상태라도 팬들과 소통이 가능하며, `}</p>
                                <p className={styles.d}>Persona를 적용해 다양한 성격을 체험할 수 있음</p>
                            </b>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={stylesEn.aiAvatar}>
                    <div className={stylesEn.rectangleParent1}>
                        <div className={stylesEn.vdigmsTechnologySpearheadinContainer}>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>Vdigm’s Technology Spearheading</p>
                            <p className={stylesEn.vdigmsTechnologySpearheadin}>3D Live Streaming</p>
                        </div>
                    </div>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.category}>
                            <div className={stylesEn.aiAvatar1} onClick={() => handleCategoryClick('aiAvatar')}>
                                AI Avatar
                            </div>
                            <div className={stylesEn.deepReal} onClick={() => handleCategoryClick('deepReal')}>
                                Deep Real
                            </div>
                            <div className={stylesEn.dVirtualStage} onClick={() => handleCategoryClick('dVirtualStage')}>
                                3D Virtual Stage
                            </div>
                            <div className={stylesEn.ellipseDiv} />
                        </div>

                        {/* <div className={styles.deepReal}>Deep Real</div>
                        <div className={styles.dVirtualStage}>3D Virtual Stage</div> */}

                        {/* <b className={styles.aiAvatar1}>AI Avatar</b> */}
                        <img className={stylesEn.maskGroupIcon} alt="" src="assets/pc/solution/responsiveAiAvatar.png" />
                        <img className={stylesEn.maskGroupIcon1} alt="" src="assets/pc/solution/generativeAiAvatar.png" />
                        <img className={stylesEn.maskGroupIcon2} alt="" src="assets/pc/solution/aiAvatar.png" />
                        <div className={stylesEn.generativeAiAvatarParent}>
                            <b className={stylesEn.generativeAiAvatar}>Generative AI Avatar</b>
                            <div className={stylesEn.createYour3dContainer}>
                                <p className={stylesEn.createYour3d}>Create Your 3D Avatar with a Single Photo Using</p>
                                <p className={stylesEn.createYour3d}>{`Your Smartphone, Webcam, or Other Device. `}</p>
                                <p className={stylesEn.createYour3d}>{`Our AI Model and Training Data are Continuously `}</p>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>Updated.</p>
                            </div>
                        </div>
                        <div className={stylesEn.responsiveAiAvatarParent}>
                            <b className={stylesEn.generativeAiAvatar}>Responsive AI Avatar</b>
                            <div className={stylesEn.createYour3dContainer}>
                                <p className={stylesEn.createYour3d}>{`Experience the Future of Human-AI Interaction: `}</p>
                                <p className={stylesEn.createYour3d}>Vdigm's Motion Tracking AI Avatar Enables Real-</p>
                                <p className={stylesEn.createYour3d}>{`Time Conversations, Mirrored Movements, and `}</p>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>Natural Responses on PC and Mobile</p>
                            </div>
                        </div>
                        <div className={stylesEn.aiAvatarNpcParent}>
                            <b className={stylesEn.generativeAiAvatar}>AI Avatar NPC</b>
                            <div className={stylesEn.createYour3dContainer}>
                                <p className={stylesEn.createYour3d}>Vdigm’s Avatar NPC: Enabling Virtual Streamers to</p>
                                <p className={stylesEn.createYour3d}>{`Interact with Fans Even When Offline, with `}</p>
                                <p className={stylesEn.createYour3d}>{`Customizable Personalities for a Diverse Streaming `}</p>
                                <p className={stylesEn.vdigmsTechnologySpearheadin}>Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AiAvatar;
