import { FunctionComponent, useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import styles from './WelfareBenefit.module.css';
import stylesEn from './WelfareBenefitEn.module.css';

import { useTranslation } from "react-i18next";

import React from 'react';

interface Props {
    recruits: {
        idealTalent: string,
        welfareBenefit: string,
        recruitProcedure: string,
    };
    setSearchParams: SetURLSearchParams;
    selectedCategory: any;
    setSelectedCategory: React.Dispatch<React.SetStateAction<any>>
    tab: string | null;

}

const WelfareBenefit: FunctionComponent<Props> = (props) => {

    const { recruits, setSelectedCategory, tab, setSearchParams } = props;
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (tab) {
            switch (tab) {
                case 'idealTalent':
                    setSelectedCategory(recruits.idealTalent);
                    break;
                case 'welfareBenefit':
                    setSelectedCategory(recruits.welfareBenefit);
                    break;
                case 'recruitProcedure':
                    setSelectedCategory(recruits.recruitProcedure);
                    break;
            }
        }
    }, [tab, recruits, setSelectedCategory]);

    const handleCategoryClick = (category: string) => {
        setSearchParams({ tab: category });
        setSelectedCategory(recruits[category]);
    };

    return (
        <div>
            {currentLanguage === 'ko' ? (
                <div className={styles.div}>
                    <div className={styles.rectangleParent}>
                        <div className={styles.groupChild} />

                        <div className={styles.div2} onClick={() => handleCategoryClick('idealTalent')}>
                            인재상
                        </div>
                        <div className={styles.div3} onClick={() => handleCategoryClick('welfareBenefit')}>
                            복리후생
                        </div>
                        <div className={styles.div4} onClick={() => handleCategoryClick('recruitProcedure')}>
                            채용절차
                        </div>

                        <div className={styles.div1}>
                            <p className={styles.p}>브이다임은</p>
                            <p className={styles.p1}>직원들을 생각합니다</p>
                        </div>

                        <div className={styles.div5}>
                            <div className={styles.child} />
                            <div className={styles.div6}>
                                <p className={styles.p2}>명절에는</p>
                                <p className={styles.p1}>양손을 무겁게!</p>
                            </div>
                            <div className={styles.div7}>명절선물</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/holiday.png" />
                        </div>
                        <div className={styles.div8}>
                            <div className={styles.child} />
                            <div className={styles.div9}>
                                <p className={styles.p2}>기쁘고 슬픈일</p>
                                <p className={styles.p1}>회사가 함께해요</p>
                            </div>
                            <div className={styles.div10}>경조사 휴가</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/familyEvent.png" />
                        </div>
                        <div className={styles.div11}>
                            <div className={styles.child} />
                            <div className={styles.div12}>
                                <p className={styles.p2}>출출할 땐</p>
                                <p className={styles.p1}>간식먹으러!</p>
                            </div>
                            <div className={styles.div13}>커피/음료, 간식 제공</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/snack.png" />
                        </div>
                        <div className={styles.div14}>
                            <div className={styles.child} />
                            <div className={styles.div12}>
                                <p className={styles.p2}>점심식사</p>
                                <p className={styles.p1}>걱정마세요!</p>
                            </div>
                            <div className={styles.div16}>점심식사 제공</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/meals.png" />
                        </div>
                        <div className={styles.div17}>
                            <div className={styles.child} />
                            <div className={styles.div18}>
                                <p className={styles.p2}>당연히</p>
                                <p className={styles.p1}>챙겨야죠!</p>
                            </div>
                            <div className={styles.div7}>4대 보험</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/insurance.png" />
                        </div>
                        <div className={styles.div20}>
                            <div className={styles.child} />
                            <div className={styles.div21}>
                                <p className={styles.p2}>강요 없는</p>
                                <p className={styles.p1}>회식 문화</p>
                            </div>
                            <div className={styles.div22}>근무시간 내 회식</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/diningTogether.png" />
                        </div>
                        <div className={styles.div23}>
                            <div className={styles.child} />
                            <div className={styles.div24}>요즘은 개성시대!</div>
                            <div className={styles.div7}>자율복장</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/selfDressing.png" />
                        </div>
                        <div className={styles.div26}>
                            <div className={styles.child} />
                            <div className={styles.div9}>
                                <p className={styles.p2}>근로자의 날에는</p>
                                <p className={styles.p1}>휴식을!</p>
                            </div>
                            <div className={styles.div22}>근로자의 날 휴무</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/laborDay.png" />
                        </div>
                        <div className={styles.div29}>
                            <div className={styles.child} />
                            <div className={styles.div24}>눈치보지 마세요!</div>
                            <div className={styles.div31}>반차, 연차 사용</div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/vacation.png" />
                        </div>
                        <div className={styles.div32}>
                            <div className={styles.child} />
                            <div className={styles.div33}>
                                <p className={styles.p2}>건강은 회사가</p>
                                <p className={styles.p1}>책임질게요!</p>
                            </div>
                            <img className={styles.maskGroupIcon} alt="" src="assets/checkUp.png" />
                            <div className={styles.div7}>건강검진</div>
                        </div>

                    </div>
                </div>

            ) : (

                <div className={stylesEn.benefits}>
                    <div className={stylesEn.rectangleParent}>
                        <div className={stylesEn.groupChild} />

                        <div className={stylesEn.people} onClick={() => handleCategoryClick('idealTalent')}>
                            People
                        </div>
                        <div className={stylesEn.benefits1} onClick={() => handleCategoryClick('welfareBenefit')}>
                            Benefits
                        </div>
                        <div className={stylesEn.recruitmentProcess} onClick={() => handleCategoryClick('recruitProcedure')}>
                            Recruitment Process
                        </div>

                        <b className={stylesEn.vdigmCaresAboutContainer}>
                            <p className={stylesEn.vdigmCaresAbout}>Vdigm cares about</p>
                            <p className={stylesEn.itsEmployees}>its employees</p>
                        </b>
                        <div className={stylesEn.rectangleGroup}>
                            <div className={stylesEn.groupItem} />
                            <div className={stylesEn.holidayGift}>Holiday gift</div>
                            <img className={stylesEn.maskGroupIcon} alt="" src="assets/holiday.png" />
                            <div className={stylesEn.groupInner} />
                            <div className={stylesEn.weShareYourContainer}>
                                <p className={stylesEn.weShareYour}>We share your</p>
                                <p className={stylesEn.itsEmployees}>joys and sorrows.</p>
                            </div>
                            <div className={stylesEn.bereavementLeave}>Bereavement leave</div>
                            <img className={stylesEn.maskGroupIcon1} alt="" src="assets/familyEvent.png" />
                            <div className={stylesEn.rectangleDiv} />
                            <div className={stylesEn.freeDrinks}>{`Free drinks & snacks`}</div>
                            <img className={stylesEn.maskGroupIcon2} alt="" src="assets/snack.png" />
                            <div className={stylesEn.groupChild1} />
                            <div className={stylesEn.lunchIsOn}>Lunch is on us!</div>
                            <div className={stylesEn.weOfferLunch}>We offer lunch</div>
                            <img className={stylesEn.maskGroupIcon3} alt="" src="assets/meals.png" />
                            <div className={stylesEn.groupChild2} />
                            <div className={stylesEn.ofCourseWeContainer}>
                                <p className={stylesEn.weShareYour}>Of Course</p>
                                <p className={stylesEn.itsEmployees}>We offer!</p>
                            </div>
                            <div className={stylesEn.majorInsurances}>4 Major Insurances</div>
                            <img className={stylesEn.maskGroupIcon4} alt="" src="assets/insurance.png" />
                            <div className={stylesEn.groupChild3} />
                            <div className={stylesEn.anUnforcedDiningContainer}>
                                <p className={stylesEn.weShareYour}>An unforced</p>
                                <p className={stylesEn.itsEmployees}>dining culture</p>
                            </div>
                            <img className={stylesEn.maskGroupIcon5} alt="" src="assets/diningTogether.png" />
                            <div className={stylesEn.groupChild4} />
                            <div className={stylesEn.embraceYourIndividualityContainer}>
                                <p className={stylesEn.weShareYour}>Embrace</p>
                                <p className={stylesEn.itsEmployees}>your individuality!</p>
                            </div>
                            <div className={stylesEn.wearWhateverYou}>Wear whatever you want</div>
                            <img className={stylesEn.maskGroupIcon6} alt="" src="assets/selfDressing.png" />
                            <div className={stylesEn.groupChild5} />
                            <div className={stylesEn.celebrateLaborDayContainer}>
                                <p className={stylesEn.weShareYour}>Celebrate</p>
                                <p className={stylesEn.itsEmployees}>labor day!</p>
                            </div>
                            <div className={stylesEn.laborDayOff}>Labor day off</div>
                            <img className={stylesEn.maskGroupIcon7} alt="" src="assets/laborDay.png" />
                            <div className={stylesEn.groupChild6} />
                            <div className={stylesEn.dontCareAboutContainer}>
                                <p className={stylesEn.weShareYour}>{` Don't care `}</p>
                                <p className={stylesEn.itsEmployees}>about others</p>
                            </div>
                            <div className={stylesEn.takeAHalf}>Take a (half) day off</div>
                            <img className={stylesEn.maskGroupIcon8} alt="" src="assets/vacation.png" />
                            <div className={stylesEn.groupChild7} />
                            <div className={stylesEn.yourHealthOurContainer}>
                                <p className={stylesEn.weShareYour}>{`Your health, `}</p>
                                <p className={stylesEn.itsEmployees}>our priority!</p>
                            </div>
                            <img className={stylesEn.maskGroupIcon9} alt="" src="assets/checkUp.png" />
                            <div className={stylesEn.medicalCheckup}>Medical Checkup</div>
                            <div className={stylesEn.enjoyDrinksAndContainer}>
                                <p className={stylesEn.weShareYour}>Enjoy drinks,</p>
                                <p className={stylesEn.itsEmployees}>and snacks</p>
                            </div>
                            <div className={stylesEn.duringWorkHours}>During work hours</div>
                            <div className={stylesEn.holidayCheerByContainer}>
                                <p className={stylesEn.weShareYour}>holiday cheer by</p>
                                <p className={stylesEn.itsEmployees}>giving generously</p>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default WelfareBenefit;