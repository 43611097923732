import { FunctionComponent, useState, useEffect } from 'react';
import { Link, useSearchParams, Route, Routes } from 'react-router-dom';
import About from './About.tsx';
import History from './History.tsx';
import Organization from './Organization.tsx';
import Vision from './Vision.tsx';
import { useTranslation } from "react-i18next";
import styles from './Company.module.css';
import React from 'react';


const Company: FunctionComponent = () => {
	const [introduces, setIntroduces] = useState({
		about: "회사소개",
		history: "연혁",
		organization: "조직도",
		vision: "비전",
	});

	type IntroduceType = (typeof introduces)[keyof typeof introduces];

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get("tab");
	const [selectedCategory, setSelectedCategory] = useState<IntroduceType>(introduces.about);

	useEffect(() => {
		if (tab) {
			switch (tab) {
				case 'aiAvatar':
					setSelectedCategory(introduces.about);
					break;
				case 'deepReal':
					setSelectedCategory(introduces.history);
					break;
				case '3dVirtualStage':
					setSelectedCategory(introduces.organization);
					break;
				case 'vision':
					setSelectedCategory(introduces.vision);
					break;
			}
		}
	}, [tab, introduces]);

	return (
		<div>
			<div>
				{selectedCategory === introduces.about && (
					<About introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
				)}
				{selectedCategory === introduces.history && (
					<History introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
				)}
				{selectedCategory === introduces.organization && (
					<Organization introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
				)}
				{selectedCategory === introduces.vision && (
					<Vision introduces={introduces} setSelectedCategory={setSelectedCategory} tab={tab} setSearchParams={setSearchParams} selectedCategory={selectedCategory} />
				)}
			</div>

			{/* <div className={styles.div}>
				<div className={styles.rectangleParent}>
					<div className={styles.groupChild} />
					<div className={styles.bi}>브이다임 BI</div>
					<div className={styles.slogan}>SLOGAN</div>
					<b className={styles.b}>연혁</b>
					<b className={styles.b1}>조직도</b>
					<b className={styles.b2}>비전</b>
					<b className={styles.visualAiContainer}>
						<p className={styles.visualAi}>{`정육면체는 서로 다른 두 개의 가상 세계라는 의미를 담고, 가운데 역삼각형은 Visual AI & Virtual World의 V를 의미합니다.`}</p>
						<p className={styles.paradigmShift}>또한 잘린 단면은 두 가상 세계의 충돌로 일어난 새로운 Paradigm Shift, 새로운 패러다임으로의 전환을 상징합니다.</p>
					</b>
					<div className={styles.parent}>
						<div className={styles.div1}>회사소개</div>
						<b className={styles.b3}>솔루션</b>
						<div className={styles.tih}>TIH</div>
						<b className={styles.b4}>채용</b>
						<div className={styles.contact}>Contact</div>
					</div>
					<img className={styles.icon} alt="" src="가로형.png" />
					<div className={styles.aiContainer}>
						<p className={styles.paradigmShift}>생성형 AI 아바타 서비스의</p>
						<p className={styles.paradigmShift}>새로운 패러다임을 만들어 나갑니다</p>
					</div>
					<div className={styles.groupItem} />
					
					<img className={styles.groupIcon} alt="" src="Group 1399.svg" />
					<img className={styles.groupChild1} alt="" src="Group 1400.svg" />
					<img className={styles.icon1} alt="" src="가로형.png" />
					<img className={styles.icon2} alt="" src="세로형.png" />
					<div className={styles.div5}>회사소개</div>
					<div className={styles.ellipseDiv} />
					<div className={styles.rectangleGroup}>
						<div className={styles.groupChild2} />
						<div className={styles.groupChild3} />
						<div className={styles.div1}>당신의 상상을 현실로,</div>
						<div className={styles.ai1}>AI 아바타 세계로 초대합니다.</div>
					</div>
					<div className={styles.group}>
						<img className={styles.icon3} alt="" src="한영.svg" />
						<div className={styles.kor}>KOR</div>
						<div className={styles.eng}>ENG</div>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default Company;
